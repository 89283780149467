<ng-template #filterTemplate let-modal="modal">
    <div class="position-absolute top-0 d-flex flex-column flex-grow w-100 h-100 pt-lg-3">
        <div class="px-4 pt-4 position-relative flex-grow h-100">
            <div class="position-relative flex-grow h-100">
                <div class="d-flex justify-content-between">
                    <h5 class="text-blue fw-bolder my-0">Filter</h5>
                    <p class="fw-light">{{ organizations.length }} Organization{{ organizations.length === 0 || organizations.length > 1 ? 's' : '' }}</p>
                </div>

                <div class="filters-container pt-5 position-absolute flex-grow w-100 h-100 top-0">
                    <div class="overflow-auto p-0 m-0 w-100 h-100">
                        <div *ngFor="let field of filterableFields">
                            <h6 class="text-blue fw-light mb-2">{{ fieldsNicknames[field] || field | titleCaseWord }}</h6>
                            <div class="fancy-checkbox" [ngClass]="{ 'mb-4': i === filterableValues[field].length - 1 }" *ngFor="let value of filterableValues[field]; index as i">
                                <input type="checkbox" id="{{ value }}" [checked]="isFilterableFieldActive(field, value)" (change)="toggleFilterableField(field, value)" />
                                <label for="{{ value }}" role="button">{{ value }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons d-flex justify-content-between p-4 z-4">
            <button class="btn btn-white" (click)="clearFilter()">Clear</button>
            <button class="btn btn-green" (click)="applyFilter()">Apply</button>
        </div>
    </div>
</ng-template>

<div class="accounts-wrapper gradient-container py-5">
    <div class="circle-top-left"></div>
    <div class="circle-lower-right"></div>
    <div class="container-xxxl px-0 px-lg-3">
        <div class="d-flex justify-content-between flex-xl-row flex-column mb-32 py-xl-5">
            <h5 class="fw-bolder text-white mb-xl-0 d-none d-xl-block fs-md-3">{{ patientOrgainationTitle }}</h5>
            <div class="options d-flex justify-content-between px-4">
                <button class="btn text-white filter text-start py-2 d-flex justify-content-between align-items-center" (click)="openFilterModal()">
                    <p class="m-0 p-0 text-center text-xl-start w-100 fs-md-18">
                        <i class="icon-gard-filter d-none d-xl-inline"></i> Filter<span class="m-0 p-0 d-none d-xl-inline">:</span>
                    </p>
                    <i class="icon-gard-drop-arrow-down d-none d-xl-inline"></i>
                </button>
                <div class="line"></div>
                <button class="btn text-white download py-2 text-center text-xl-start fs-md-18" (click)="download()">
                    <i class="icon-gard-download d-none d-xl-inline"></i> Download
                </button>
            </div>
        </div>

        <p class="text-white fw-light d-block mb-4 px-4 px-xl-0">{{ organizations.length }} Organizations</p>

        <div class="interactive-table">
            <div class="head mb-4 py-2 d-none d-xl-block">
                <div class="row text-green fw-bolder py-1 px-5">
                    <div class="col-xl-5">
                        <p role="button" (click)="sortBy('name')">
                            Organization Name
                            <i
                                class="text-green"
                                [ngClass]="[sortState.field === nameProperty ? (sortState.asc ? 'icon-gard-sort-up' : 'icon-gard-sort-down') : 'icon-gard-sort']"
                            ></i>
                        </p>
                    </div>
                    <div class="col-xl-2">
                        <p role="button" (click)="sortBy('serveOrder')">
                            Who They Serve
                            <i
                                class="text-green"
                                [ngClass]="[sortState.field === serveOrderProperty ? (sortState.asc ? 'icon-gard-sort-up' : 'icon-gard-sort-down') : 'icon-gard-sort']"
                            ></i>
                        </p>
                    </div>
                    <div class="col-xl-3 service-title">
                        <p role="button" (click)="sortBy('linkOrder')">
                            Helpful Links
                            <i
                                class="text-green"
                                [ngClass]="[sortState.field === linkOrderProperty ? (sortState.asc ? 'icon-gard-sort-up' : 'icon-gard-sort-down') : 'icon-gard-sort']"
                            ></i>
                        </p>
                    </div>
                    <div class="col-xl-2">
                        <p role="button" (click)="sortBy('country')">
                            Country
                            <i
                                class="text-green"
                                [ngClass]="[sortState.field === countryProperty ? (sortState.asc ? 'icon-gard-sort-up' : 'icon-gard-sort-down') : 'icon-gard-sort']"
                            ></i>
                        </p>
                    </div>
                </div>
            </div>
            <div class="body d-flex flex-row flex-xl-column" (scroll)="onScroll()">
                <div class="item mb-0 mb-xl-2 me-4 me-xl-0" *ngFor="let organization of organizations; let f = first" [ngClass]="{ 'ms-4 ms-xl-0': f }">
                    <div class="row py-0 py-xl-1 px-0 px-xl-5">
                        <div class="org-name col-xl-5 py-4 py-xl-0 px-4 px-xl-0 flex-fill pb-3 pb-xl-0">
                            <a link [attr.href]="organization.url" target="_blank" rel="noopener noreferrer">
                                <h5 class="d-inline fw-bolder mb-2 mb-xl-3 w-75 fs-md-22 me-1">{{ organization.name }}</h5>
                            </a>
                        </div>
                        <div class="col-xl-2 py-3 py-xl-0 px-4 px-xl-0 mb-2 mb-xl-0">
                            <h6 class="text-blue fw-bolder mb-2 fs-6 fs-md-18">People With</h6>
                            <p class="fs-md-18">{{ organization.whoTheyServe }}</p>
                        </div>
                        <div class="col-xl-3 py-2 pt-0 py-xl-0 px-4 px-xl-0 mb-2 mb-xl-0 service-title z-10">
                            <h6 class="d-block d-xl-none text-green mb-2 fw-bolder">Helpful Links</h6>
                            <div class="d-flex flex align-items-start text-center fs-6">
                                <div *ngIf="organization.expertDirectoryUrl">
                                    <i class="icon-gard-information icon-circle text-blue"></i>
                                    <div class="icon-text">
                                        <a class="text-link" [href]="organization.expertDirectoryUrl" link>List of Experts</a>
                                    </div>
                                </div>
                                <div *ngIf="organization.patientRegistryUrl">
                                    <i class="icon-gard-research icon-circle text-green"></i>
                                    <div class="icon-text">
                                        <a class="text-link" [href]="organization.patientRegistryUrl" link>Research Registry</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-2 py-2 py-xl-0 px-4 px-xl-0 mb-2 mb-xl-0">
                            <h6 class="fw-bolder mb-2 d-block d-xl-none s-md-18 text-green">Country</h6>
                            <p class="fs-md-18">
                                {{ organization.country }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
