<div *ngIf="isVisible" class="feedback-banner d-block d-md-flex justify-content-center">
    <!-- Desktop -->
    <div class="feedback-banner d-none d-md-flex align-items-center w-100 fs-18">
        <div class="w-100 h-100 bg-yellow text-dark d-flex align-items-center justify-content-center p-4">
            <div class="me-4">
                <img src="/assets/gears.svg" alt="" />
            </div>
            <div>
                Because of a lapse in government funding, the information on this website may not be up to date, transactions submitted via the website may not be processed, and
                the agency may not be able to respond to inquiries until appropriations are enacted.
            </div>
        </div>
        <div class="w-100 h-100 bg-teal text-white p-4 d-flex align-items-center justify-content-center">
            <div>
                <p>
                    The GARD Contact Center is open for patients, caregivers, and family members searching for information on a rare disease. To get in touch please call or visit:
                    <a class="text-white underline" href="https://rarediseases.info.nih.gov/contact">https://rarediseases.info.nih.gov/contact</a>
                </p>
                <p>
                    The NIH Clinical Center (the research hospital of NIH) is open. For more details about its operating status, please visit
                    <a class="text-white underline" href="https://cc.nih.gov">cc.nih.gov</a>.
                </p>
                <p>
                    Updates regarding government operating status and resumption of normal operations can be found at
                    <a class="text-white underline" href="https://opm.gov">OPM.gov</a>.
                </p>
            </div>
        </div>
    </div>

    <!-- Mobile -->
    <div class="d-block d-md-none fs-6">
        <div class="bg-yellow text-dark p-4">
            Because of a lapse in government funding, the information on this website may not be up to date, transactions submitted via the website may not be processed, and the
            agency may not be able to respond to inquiries until appropriations are enacted.
        </div>
        <div class="bg-teal text-white p-4">
            <p>
                The GARD Contact Center is open for patients, caregivers, and family members searching for information on a rare disease. To get in touch please call or visit:
                <a class="text-white underline" href="https://rarediseases.info.nih.gov/contact">https://rarediseases.info.nih.gov/contact</a>
            </p>
            <p>
                The NIH Clinical Center (the research hospital of NIH) is open. For more details about its operating status, please visit
                <a class="text-white underline" href="https://cc.nih.gov">cc.nih.gov</a>.
            </p>
            <p>
                Updates regarding government operating status and resumption of normal operations can be found at
                <a class="text-white underline" href="https://opm.gov">OPM.gov</a>.
            </p>
        </div>
    </div>

    <!-- Close button -->
    <button class="x-button position-absolute btn icon-gard-close-wrapper fs-7 fw-bold mb-3" (click)="close()" aria-label="Close">
        <i class="icon-gard-close"></i>
    </button>
</div>

<!-- Floating Feedback Button -->
<button class="side-button position-fixed bg-teal border-1 border-bottom-0 text-white px-3 py-1 py-md-2 border border-white fs-7 fs-md-6" (click)="gotoForm($event)">
    Feedback
</button>
