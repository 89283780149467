<div class="main">
    <div class="symptom-list-header">
        <div class="medical-term">Medical Term</div>
        <div class="medical-term-header fs-4">{{ symptomSlide.title }}</div>
    </div>

    <div class="symptom-list-background">
        <div class="symptom-list-frequency-header">
            <span class="frequency-header">Frequency</span>
            <p class="frequency-ka-text">{{ frequencyKa }}</p>
        </div>

        <div class="symptom-list-frequency row" #symptomListFrequency>
            <div class="col-12 col-md-6">
                <symptoms-section-frequency-slider
                    [tooltipText]="frequencyKa"
                    [frequencyValue]="symptomSlide.frequencyValue"
                    [frequencyText]="symptomSlide.frequencyText"
                    [showInfo]="false"
                    [showFrequencyText]="false"
                    [forceLabels]="true"
                ></symptoms-section-frequency-slider>
            </div>
            <div class="frequency-text-wrapper col-12 col-md-6" [ngClass]="{ 'grey-cover': symptomSlide.frequencyValue === 0 }">
                <div class="frequency-text">
                    <div class="row mt-1">
                        <div class="col-6">
                            <p class="right" [innerHTML]="frequencyTextsWithBr"></p>
                        </div>
                        <div class="col-6">
                            <p class="left" [innerHTML]="frequencyNumbersWithBr"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="symptom-list-synonym-or-description">
        <div class="symptom-list-synonym-or-description-header">Description</div>
        <div class="mt-2">
            {{ symptomSlide.textFull }}
        </div>
    </div>
    <div class="symptom-list-synonym-or-description">
        <div class="symptom-list-synonym-or-description-header">Synonyms</div>
        <div class="mt-2">
            {{ symptomSlide.synonym ? symptomSlide.synonym : 'None' }}
        </div>
    </div>
</div>
