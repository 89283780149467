import { Component, Input, ViewEncapsulation } from '@angular/core';
import { randomId } from 'shared/utils/random-id';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
    @Input() public text: string;
    @Input() public mobileIconClass = 'icon-regular';
    public rid = randomId();
}
