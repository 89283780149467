/* eslint-disable max-len */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbTooltipModule, NgbModalModule, NgbActiveModal, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { Ab2DescriptionComponent } from './components/about/ab2-description/ab2-description.component';
import { Ab4PoliciesComponent } from './components/about/about-gard-website-faq/about-gard-website-faq.component';
import { AboutComponent } from './components/about/about.component';
import { Co2ContactCenterComponent } from './components/contact-us/co2-contact-center/co2-contact-center.component';
import { ContactResourcesComponent } from './components/contact-us/contact-resources/contact-resources.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { EspanolDiseaseDetailComponent } from './components/disease-es/espanol-disease-detail.component';
import { AgeAtOnsetComponent } from './components/disease/disease-about/age-at-onset/age-at-onset.component';
import { CausesComponent } from './components/disease/disease-about/causes/causes.component';
import { CausesTabsComponent } from './components/disease/disease-about/causes-tabs/causes-tabs.component';
import { DiseaseAboutComponent } from './components/disease/disease-about/disease-about.component';
import { DiseaseAtAGlanceCategoriesPopupComponent } from './components/disease/disease-about/disease-at-a-glance-categories-popup/disease-at-a-glance-categories-popup.component';
import { DiseaseAtAGlanceSummaryComponent } from './components/disease/disease-about/disease-at-a-glance-summary/disease-at-a-glance-summary.component';
import { DiseaseAtAGlanceComponent } from './components/disease/disease-about/disease-at-a-glance/disease-at-a-glance.component';
import { SymptomsSectionReadmorePopupComponent } from './components/disease/disease-about/symptoms-section-readmore-popup/symptoms-section-readmore-popup.component';
import { SymptomsSectionComponent } from './components/disease/disease-about/symptoms-section/symptoms-section.component';
import { YourMedicalTeamComponent } from './components/disease/disease-diagnosis/your-medical-team/your-medical-team.component';
import { AppointmentsAndDiagnosticEvaluationsComponent } from './components/disease/disease-diagnosis/appointments-and-diagnostic-evaluations/appointments-and-diagnostic-evaluations.component';
import { YourMedicalTeamAccordionComponent } from './components/disease/disease-diagnosis/your-medical-team-accordion/your-medical-team-accordion.component';
import { DiseaseDiagnosisComponent } from './components/disease/disease-diagnosis/disease-diagnosis.component';
import { PatientOrganizationsAccountsComponent } from './components/disease/disease-about/patient-organizations-accounts/patient-organizations-accounts.component';
import { GenericPageComponent } from './components/generic/generic.component';
import { H2AboutGardComponent } from './components/homepage/h2-about-gard/h2-about-gard.component';
import { H3UnderstandComponent } from './components/homepage/h3-understand/h3-understand.component';
import { H4NotAloneComponent } from './components/homepage/h4-not-alone/h4-not-alone.component';
import { H5ContactUsComponent } from './components/homepage/h5-contact-us/h5-contact-us.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { BarHeaderComponent } from './components/common/bar-header/bar-header.component';
import { BottomSourcesDateInformationComponent } from './components/common/bottom-sources-date-information/bottom-sources-date-information.component';
import { ClampComponent } from './components/common/clamp/clamp.component';
import { ContentSliderComponent } from './components/common/content-slider/content-slider.component';
import { DiseaseTabsComponent } from './components/common/disease-tabs/disease-tabs.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { MainBannerComponent } from './components/common/main-banner/main-banner.component';
import { MobileSearchItemComponent } from './components/common/mobile-search-item/mobile-search-item.component';
import { ModalBaseComponent } from './components/common/modal-base/modal-base.component';
import { MultitabComponent } from './components/common/multitab/multitab.component';
import { PrevNextButtonComponent } from './components/common/prev-next-button/prev-next-button.component';
import { ScrollToTopComponent } from './components/common/scroll-to-top/scroll-to-top.component';
import { TooltipComponent } from './components/common/tooltip/tooltip.component';
import { TopNavigationComponent } from './components/common/top-navigation/top-navigation.component';
import { ViewSourcesReferencesComponent } from './components/common/view-sources-references/view-sources-references.component';
import { SearchResultPageComponent } from './components/search-result-page/search-result-page.component';
import { EmbeddedHtmlDirective } from './directives/embedded-html/embedded-html.directive';
import { ExternalLinkDirective } from './directives/external-link/external-link.directive';
import { MatchHeightDirective } from './directives/match-height/match-height.directive';
import { ClipTextPipe } from './pipes/clip-text.pipe';
import { NumberOrStringToDecimalPipe } from './pipes/number-or-string-to-decimal.pipe';
import { NumberRoundingSuffixPipe } from './pipes/number-rounding-suffix.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { TitleCaseWordPipe } from './pipes/title-case-word.pipe';
import { ErrorListComponent } from './testing/error-list/error-list.component';
import { ContactUsEsComponent } from './components/contact-us/contact-us-es/contact-us-es.component';
import { FeedbackBannerComponent } from './components/common/feedback-banner/feedback-banner.component';
import { DiseaseCasePipe } from './pipes/disease-case.pipe';
import { OutsideClickDirective } from './directives/outside-click/outside-click.directive';
import { AutoFocusDirective } from './directives/auto-focus/auto-focus.directive';
import { ToggleControllerComponent } from './components/disease/disease-about/toggle-controller/toggle-controller.component';
import { FilterByLetterComponent } from './components/search-result-page/filter-by-letter/filter-by-letter.component';
import { FilterBubblesComponent } from './components/search-result-page/filter-bubbles/filter-bubbles.component';
import { SymptomsSectionFrequencySliderComponent } from './components/disease/disease-about/symptoms-section-frequency-slider/symptoms-section-frequency-slider.component';
import { FilterByCategoryComponent } from './components/search-result-page/filter-by-category/filter-by-category.component';
import { DiseaseFooterComponent } from './components/common/disease-footer/disease-footer.component';
import { DiseaseAtAGlanceSnippetsComponent } from './components/disease/disease-about/disease-at-a-glance-snippets/disease-at-a-glance-snippets.component';
import { AdvocateForYourselfComponent } from './components/disease/disease-diagnosis/advocate-for-yourself/advocate-for-yourself.component';
import { DiagnosticJourneyComponent } from './components/disease/disease-diagnosis/diagnostic-journey/diagnostic-journey.component';
import { CareCoordinationComponent } from './components/disease/disease-diagnosis/care-coordination/care-coordination.component';
import { ParticipatingInClinicalStudiesComponent } from './components/disease/disease-about/participating-in-clinical-studies/participating-in-clinical-studies.component';
import { RareDiseaseExpertComponent } from './components/disease/disease-diagnosis/rare-disease-expert/rare-disease-expert.component';
import { ClinicalStudiesComponent } from './components/disease/disease-about/clinical-studies/clinical-studies.component';
import { ClinicalTrialsContactUsComponent } from './components/disease/disease-about/clinical-trials-contact-us/clinical-trials-contact-us.component';
import { AdvocacyAndSupportGroupsComponent } from './components/disease/disease-about/advocacy-and-support-groups/advocacy-and-support-groups.component';
import { HttpErrorInterceptor } from './http-interceptors/error-interceptor';
import { PhoneLinkDirective } from './directives/phone-link/phone-link.directive';
import { DiseaseSectionContactUsComponent } from './components/disease/disease-generic/disease-section-contact-us/disease-section-contact-us.component';
import { InheritanceComponent } from './components/disease/disease-about/inheritance/inheritance.component';
import { ImprovingAccessComponent } from './components/about/improving-access/improving-access.component';
import { ButtonDetailListComponent } from './components/common/button-detail-list/button-detail-list.component';
import { ButtonDetailListLargerComponent } from './components/common/button-detail-list-larger/button-detail-list-larger.component';
import { GardIdComponent } from './redirects/gard-id/gard-id.component';
import { ResourcesPageComponent } from './components/pages/resources-page/resources-page.component';
import { SingleKaWithPhotoComponent } from './components/layouts/single-ka-with-photo/single-ka-with-photo.component';
import { SingleKaLeftOnlyLightgrayComponent } from './components/layouts/single-ka-left-only-lightgray/single-ka-left-only-lightgray.component';

// Resources page
import { SuggestedResourcesComponent } from './components/pages/resources-page/suggested-resources/suggested-resources.component';
import { CaregiverResourcesComponent } from './components/pages/resources-page/caregiver-resources/caregiver-resources.component';
import { LivingWithInformedDecisionsComponent } from './components/pages/resources-page/living-with-informed-decisions/living-with-informed-decisions.component';
import { ResearchParticipateInResearchCardComponent } from './components/pages/resources-page/research-participate-in-research-card/research-participate-in-research-card.component';

export const imports = [
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbTooltipModule,
    NgbModalModule,
    NgbAccordionModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScullyLibModule.forRoot({
        useTransferState: true,
        alwaysMonitor: true,
    }),
];

export const providers = [
    DecimalPipe,
    NumberOrStringToDecimalPipe,
    { provide: Window, useValue: window },
    TitleCaseWordPipe,
    NgbActiveModal,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true,
    },
];

@NgModule({
    imports,
    providers,
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        SafeHTMLPipe,
        FooterComponent,
        SymptomsSectionComponent,
        DiseaseAboutComponent,
        DiseaseDiagnosisComponent,
        AgeAtOnsetComponent,
        DiseaseAtAGlanceComponent,
        BarHeaderComponent,
        DiseaseAtAGlanceSummaryComponent,
        CausesComponent,
        CausesTabsComponent,
        ScrollToTopComponent,
        DiseaseAtAGlanceCategoriesPopupComponent,
        SymptomsSectionReadmorePopupComponent,
        ClipTextPipe,
        YourMedicalTeamAccordionComponent,
        AppointmentsAndDiagnosticEvaluationsComponent,
        PrevNextButtonComponent,
        PatientOrganizationsAccountsComponent,
        NumberRoundingSuffixPipe,
        NumberOrStringToDecimalPipe,
        YourMedicalTeamComponent,
        MultitabComponent,
        TooltipComponent,
        EspanolDiseaseDetailComponent,
        SearchResultPageComponent,
        NotFoundComponent,
        ExternalLinkDirective,
        EmbeddedHtmlDirective,
        HomepageComponent,
        H3UnderstandComponent,
        H4NotAloneComponent,
        H2AboutGardComponent,
        H5ContactUsComponent,
        ContactUsComponent,
        AboutComponent,
        GenericPageComponent,
        Co2ContactCenterComponent,
        ErrorListComponent,
        Ab2DescriptionComponent,
        Ab4PoliciesComponent,
        ContactResourcesComponent,
        ContentSliderComponent,
        TitleCaseWordPipe,
        TopNavigationComponent,
        MainBannerComponent,
        DiseaseTabsComponent,
        MobileSearchItemComponent,
        ViewSourcesReferencesComponent,
        BottomSourcesDateInformationComponent,
        MatchHeightDirective,
        ClampComponent,
        ModalBaseComponent,
        ContactUsEsComponent,
        FeedbackBannerComponent,
        DiseaseCasePipe,
        OutsideClickDirective,
        AutoFocusDirective,
        ToggleControllerComponent,
        FilterByLetterComponent,
        FilterBubblesComponent,
        SymptomsSectionFrequencySliderComponent,
        FilterByCategoryComponent,
        DiseaseFooterComponent,
        DiseaseAtAGlanceSnippetsComponent,
        AdvocateForYourselfComponent,
        DiagnosticJourneyComponent,
        CareCoordinationComponent,
        ParticipatingInClinicalStudiesComponent,
        RareDiseaseExpertComponent,
        ClinicalStudiesComponent,
        ClinicalTrialsContactUsComponent,
        AdvocacyAndSupportGroupsComponent,
        PhoneLinkDirective,
        DiseaseSectionContactUsComponent,
        InheritanceComponent,
        ImprovingAccessComponent,
        ButtonDetailListComponent,
        ButtonDetailListLargerComponent,
        GardIdComponent,
        ResourcesPageComponent,
        SingleKaWithPhotoComponent,
        SingleKaLeftOnlyLightgrayComponent,
        SuggestedResourcesComponent,
        CaregiverResourcesComponent,
        LivingWithInformedDecisionsComponent,
        ResearchParticipateInResearchCardComponent,
    ],
})
export class AppModule {}
