import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Section, Page, SubSection } from 'shared/location-enums';
import { Article } from 'shared/types';

@Component({
    selector: 'advocacy-and-support-groups',
    templateUrl: './advocacy-and-support-groups.component.html',
    styleUrls: ['./advocacy-and-support-groups.component.scss'],
})
export class AdvocacyAndSupportGroupsComponent {
    public article: Article;
    public imgsrc = '';
    public imgAltText = '';

    constructor(private articleService: ArticleService) {
        this.article = this.articleService.getOne(Page.about, Section.patientOrganizations, SubSection.parent, undefined, undefined, false);

        if (this.article.images.length > 0) {
            this.imgsrc = PATHS.articleImages + this.article.images[0].url + '-800.webp';
            this.imgAltText = this.article.images[0].altText;
        }
    }
}
