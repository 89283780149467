import { Article, Image } from 'shared/types';
import { Section, SubSection } from 'shared/location-enums';

export interface MultitabLink {
    title: string;
    onClick: () => void;
}

export interface MultitabItem extends Article {
    panelTitle?: string;
    items?: MultitabItem[];
    link?: MultitabLink;
    bullets?: string[];
    additionalArticles?: MultitabItem[];
}

export interface TopTab {
    title: string;
    sectionHeader?: string;
    section: Section;
    subSection: SubSection;
    items?: MultitabItem[];
    type: PanelType;
    img?: Image;
    order?: number;
}

// eslint-disable-next-line no-shadow
export enum PanelType {
    normal = 'normal',
    info = 'info',
    accounts = 'accounts',
}
