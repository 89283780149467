<ngb-accordion class="accordion-filter-by-letter" [animation]="true" [closeOthers]="true" activeIds="disease-letter">
    <ngb-panel title="Browse by Letter" id="disease-letter">
        <ng-template ngbPanelContent>
            <div class="letter-box">
                <div class="row letter-row">
                    <div *ngFor="let letter of filterLetters1" class="col-2 letter-bubble" [ngClass]="{ selected: activeLetter === letter }">
                        <div class="letter" tabindex="0" (keyup.enter)="clickFilterLetter(letter)" (click)="clickFilterLetter(letter)">{{ letter }}</div>
                    </div>
                </div>
                <div class="row letter-row">
                    <div *ngFor="let letter of filterLetters2" class="col-2 letter-bubble" [ngClass]="{ selected: activeLetter === letter }">
                        <div class="letter" tabindex="0" (keyup.enter)="clickFilterLetter(letter)" (click)="clickFilterLetter(letter)">{{ letter }}</div>
                    </div>
                </div>
                <div class="row letter-row">
                    <div *ngFor="let letter of filterLetters3" class="col-2 letter-bubble" [ngClass]="{ selected: activeLetter === letter }">
                        <div class="letter" tabindex="0" (keyup.enter)="clickFilterLetter(letter)" (click)="clickFilterLetter(letter)">{{ letter }}</div>
                    </div>
                </div>
                <div class="row letter-row">
                    <div *ngFor="let letter of filterLetters4" class="col-2 letter-bubble" [ngClass]="{ selected: activeLetter === letter }">
                        <div class="letter" tabindex="0" (keyup.enter)="clickFilterLetter(letter)" (click)="clickFilterLetter(letter)">{{ letter }}</div>
                    </div>
                </div>
                <div class="row letter-row">
                    <div *ngFor="let letter of filterLetters5" class="col-2 letter-bubble" [ngClass]="{ selected: activeLetter === letter }">
                        <div class="letter" tabindex="0" (keyup.enter)="clickFilterLetter(letter)" (click)="clickFilterLetter(letter)">{{ letter }}</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
