import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { DiseaseService } from '../../../services/disease.service';
import { SearchFilter } from '../search-filter';
import { ModalService } from 'src/app/services/modal.service';
import { Categories } from 'shared/types';
import { DISEASE_TAGS } from 'shared/constants';

@Component({
    selector: 'filter-by-category',
    templateUrl: './filter-by-category.component.html',
    styleUrls: ['./filter-by-category.component.scss'],
})
export class FilterByCategoryComponent {
    @Input() set searchFilter(searchFilters: SearchFilter[]) {
        this.activeDiseaseCategories = searchFilters
            ?.filter((searchFilter: SearchFilter) => searchFilter.filterType === 'category')
            ?.map((searchFilter: SearchFilter) => searchFilter.filterText);
    }

    @Output() selectedCategories = new EventEmitter<string[]>();
    activeDiseaseCategories: string[] = [];
    allDiseaseCategories: string[] = [];

    public categoriesWithDescriptions: Categories[] = [];

    constructor(public diseaseService: DiseaseService, public modalService: ModalService) {
        this.categoriesWithDescriptions = this.diseaseService.diseaseCategories.sort((a: Categories, b: Categories) => a.nameCurated.localeCompare(b.nameCurated));
        this.categoriesWithDescriptions = this.categoriesWithDescriptions.filter((category: Categories) => category.name !== DISEASE_TAGS.mitochondrial);

        this.allDiseaseCategories = this.categoriesWithDescriptions.map(dc => dc.nameCurated).sort();

        this.categoriesWithDescriptions = this.categoriesWithDescriptions.filter((category: Categories) => category.nameCurated !== 'Inherited Metabolic Diseases');
    }

    clickFilter(category: string) {
        if (this.activeDiseaseCategories.includes(category)) {
            this.activeDiseaseCategories = this.activeDiseaseCategories.filter(c => c !== category);
        } else {
            this.activeDiseaseCategories.push(category);
        }
        this.selectedCategories.emit(this.activeDiseaseCategories);
    }

    openModal(event: MouseEvent, template: TemplateRef<ElementRef>) {
        event.stopPropagation();
        this.modalService.open(template);
    }
}
