import { ChangeDetectorRef, ElementRef, OnDestroy, TemplateRef, OnInit } from '@angular/core';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SymptomSlide } from './symptom-slide';
import { SymptomSearchResult } from './symptom-search-result';
import { SymptomsSectionReadmorePopupComponent } from '../symptoms-section-readmore-popup/symptoms-section-readmore-popup.component';
import { TitleCaseWordPipe } from '../../../../pipes/title-case-word.pipe';
import { ModalService } from 'src/app/services/modal.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { ArticleService } from 'src/app/services/article.service';
import { Section as SEC, Page as TABS, SubSection as SS } from 'shared/location-enums';
import { take } from 'rxjs/operators';
import { Article, Disease } from 'shared/types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FEATURE_TYPES, GRID_BREAKPOINTS, EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';
import { PATHS } from 'shared/paths';

const TOOLTIP_ARTICLE_ORDER = 3;
const ALL_SYSTEMS_FILTER_VALUE = 'All Systems';
const BODY_SYSTEMS_KA_TITLE = 'Body Systems';
const EMPTY_SEARCH_RESULTS = [new SymptomSearchResult('No Results', -1, '', [])];
const REDIRECT_URL = 'https://hpo.jax.org/';

@Component({
    selector: 'symptoms-section',
    templateUrl: './symptoms-section.component.html',
    styleUrls: ['./symptoms-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SymptomsSectionComponent implements OnDestroy, OnInit {
    @ViewChild('templateFilterSort') templateFilterSort: TemplateRef<unknown>;
    @ViewChild('templateSearch') templateSearch: TemplateRef<unknown>;
    @ViewChild('symptomListParent') listParent: ElementRef;
    @ViewChild('systemPopup') systemPopup: TemplateRef<unknown>;
    @ViewChild('listView') listViewRef: ElementRef;

    // @ViewChild('descriptionGhost') descriptionGhost: ElementRef<HTMLParagraphElement>;
    // @ViewChild('synonymGhost') synonymGhost: ElementRef<HTMLParagraphElement>;
    // @ViewChild('descriptionHost') descriptionHost: ElementRef<HTMLParagraphElement>;
    // @ViewChild('synonymHost') synonymHost: ElementRef<HTMLParagraphElement>;
    @ViewChild('title') title: ElementRef<HTMLHeadingElement>;

    searchForm = new UntypedFormGroup({ term: new UntypedFormControl('') });
    searchFormMobile = new UntypedFormGroup({ termMobile: new UntypedFormControl('') });
    jaxorg: string = REDIRECT_URL;
    paths = PATHS;

    // titles of next and previous tabs
    prev1: string;
    prev2: string;
    prev3: string;
    next1: string;
    next2: string;
    next3: string;

    intro: Article;

    slides: SymptomSlide[];
    currentSlide: SymptomSlide;
    searchResults = new Array<SymptomSearchResult>();
    filters: string[] = [];
    filter = ALL_SYSTEMS_FILTER_VALUE;

    currentSystem: Article;
    currentSystemImage: string;
    currentSystemImageAltText: string;

    currentSystem2: Article;
    currentSystem2Image: string;
    currentSystem2ImageAltText: string;

    currentSystem3: Article;
    currentSystem3Image: string;
    currentSystem3ImageAltText: string;

    allSystemsKAs: Article[];

    // TODO: remove this
    defaultSystem: Article;

    tooltipText: string;
    frequencyKa: string;
    frequencyTexts: string[] = [];
    frequencyNumbers: string[] = [];

    arrowRotateHaveWe = false;
    showSymptomsSearch = false;
    showFilters = false;
    showMobileFilters = false;
    isNull = false;
    nullMessage = '';
    noSymptomInfoMessage = '';
    readMoreVisibilityList = [];

    viewMode: 'list' | 'tile' = 'list';
    hideList: boolean;

    medicalSort: 'asc' | 'dsc' | null = 'asc';
    freqSort: 'asc' | 'dsc' | null = null;

    showReadMore = false;
    hideSynonym = false;
    trimmedText: string;
    trimmedSyn: string;

    private currentIndex = -1;
    private allSlides = [] as SymptomSlide[];
    private allSearchResults = [] as SymptomSearchResult[];
    private sub = new Subscription();

    constructor(
        private diseaseService: DiseaseService,
        public cdr: ChangeDetectorRef,
        public titleCaseWord: TitleCaseWordPipe,
        private modalService: ModalService,
        private articleService: ArticleService,
    ) {
        this.noSymptomInfoMessage = this.articleService.getOne(TABS.about, SEC.symptoms, SS.symptomCard, undefined, undefined, true).bodyText;

        if (SymptomsSectionComponent.isNull(this.diseaseService.disease)) {
            this.isNull = true;
            this.nullMessage = '';

            const nullArticles: Article[] = this.articleService.getArticles(TABS.about, SEC.symptoms, undefined, undefined, undefined, true);

            if (nullArticles.length === 1) {
                [this.nullMessage] = nullArticles.map(ka => ka.bodyText);
            }

            if (!this.nullMessage) {
                const nulltext = nullArticles.find(article => !article.subSection).bodyText;
                if (nulltext) {
                    this.nullMessage = nulltext;
                }
            }

            if (!this.nullMessage) {
                this.nullMessage = 'Unfortunately, data is not currently available for this section.';
                this.nullMessage = 'This information is currently in development.';
            }

            return;
        }

        this.frequencyTexts = ['Uncommon', 'Occasional', 'Frequent', 'Very Frequent', 'Always'];
        this.frequencyNumbers = ['<1-4%', '5-29%', '30-79%', '80-99%', '100%'];

        this.frequencyKa = articleService.getArticles(TABS.about, SEC.symptoms).find(a => a.order === TOOLTIP_ARTICLE_ORDER).bodyText;

        this.tooltipText = `
          <div class="row symptoms-tooltip-text">
            <div class="col-12">
                <p class="left">
                  ${this.frequencyKa}
                </p>
            </div>
          </div>
          <div class="row mt-1 symptoms-tooltip-text">
            <div class="col-6">
              <p class="right">
                  ${this.frequencyTexts.join('<br />')}
              </p>
            </div>
            <div class="col-6">
              <p class="left">
                  ${this.frequencyNumbers.join('<br />')}
              </p>
            </div>
          </div>`;

        // Desktop
        this.sub.add(
            this.searchForm.controls.term.valueChanges.subscribe(term => {
                this.doSearch(term);
            }),
        );

        // Mobile
        this.sub.add(
            this.searchFormMobile.controls.termMobile.valueChanges.subscribe(term => {
                this.doSearch(term);
            }),
        );

        this.allSlides = this.diseaseService.disease.symptoms
            .filter(symptom => symptom.HPO_Name__c && symptom.Feature_Type__c === FEATURE_TYPES.symptom)
            .map(symptom => new SymptomSlide(symptom))
            .sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));

        this.filters = [];
        this.allSlides.forEach(slide => {
            slide.system.forEach(systemEach => {
                if (!this.filters.includes(systemEach)) {
                    this.filters.push(systemEach);
                }
            });
        });

        // why do we need to check that s is not false?
        // filter(s => !!s)

        // now sort
        this.filters = this.filters.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

        this.allSearchResults = this.allSlides.map(slide => {
            const synonym = slide.synonym || '';
            const synonyms = synonym
                .split('; ')
                .filter(x => x)
                .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
            return new SymptomSearchResult(slide.title, slide.id, synonym, synonyms);
        });

        this.setNoSearchResults();
        this.resetSort();

        if (window.innerWidth < GRID_BREAKPOINTS.lg) {
            this.hideList = true;
        } else {
            this.hideList = false;
        }

        this.intro = this.articleService.getOne(TABS.about, SEC.symptoms, SS.parent);

        // Get filter values
        this.allSystemsKAs = articleService.getArticles(TABS.about, SEC.symptoms).filter(a => a.tags?.length || a.title === BODY_SYSTEMS_KA_TITLE);
        // Set filter to default
        this.filterBySystem(ALL_SYSTEMS_FILTER_VALUE);
    }

    ngOnInit(): void {
        this.toggleSort();
    }

    static isNull(disease: Disease) {
        return disease.symptoms.filter(symptom => symptom.HPO_Name__c && symptom.Feature_Type__c === FEATURE_TYPES.symptom).length === 0;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    move(steps: number) {
        this.resetSort();

        const i = this.currentIndex;
        const l = this.slides.length;
        const newIndex = (i + steps + l) % l;
        this.switchToSlide(newIndex);
    }

    onWindowResize() {
        this.calculateClamping();
        if (window.innerWidth < GRID_BREAKPOINTS.lg) {
            this.hideList = true;
        } else {
            this.hideList = false;
        }
    }

    onClickSearchResult(result: SymptomSearchResult) {
        this.resetSort();

        this.moveToId(result.slideID);
        this.searchForm.controls.term.setValue(result.title);
        this.searchFormMobile.controls.termMobile.setValue(result.title);
        this.modalService.dismissAll();
        this.showSymptomsSearch = false;

        this.slides = [this.currentSlide, ...this.slides.filter(slide => slide.id !== this.currentSlide.id)];

        if (this.viewMode === 'list' && !this.hideList) {
            this.listViewRef.nativeElement.scrollTop = 0;
        }
    }

    moveToId(id: number) {
        const slideIndex = this.slides.findIndex(slide => slide.id === id);
        if (slideIndex < 0) {
            // if filter by system is set, some results may not be in the list
            this.filterBySystem(ALL_SYSTEMS_FILTER_VALUE);
            this.moveToId(id);
        } else {
            this.switchToSlide(slideIndex);
        }
        this.showFilters = false;
        this.showMobileFilters = false;
    }

    showDefaultSystem() {
        return !this.hideList && this.searchForm.value?.term !== this.slides[0].title && this.filter === ALL_SYSTEMS_FILTER_VALUE && !this.hideList && this.viewMode === 'list';
    }

    // Sort by medical term
    toggleSort() {
        this.freqSort = null;

        if (this.medicalSort === 'asc') {
            this.medicalSort = 'dsc';
            this.allSlides.sort((a, b) => (a.title > b.title ? -1 : 1));
        } else {
            this.medicalSort = 'asc';
            this.allSlides.sort((a, b) => (a.title > b.title ? 1 : -1));
        }

        this.onSlideSetChange();
    }

    // Reset Sort
    resetSort() {
        this.medicalSort = 'dsc';
        this.freqSort = null;

        this.allSlides.sort((a, b) => (a.frequencyValue > b.frequencyValue ? -1 : 1));
        this.onSlideSetChange();
    }

    // Sort by frequency
    sortByFreq() {
        this.medicalSort = null;

        if (this.freqSort === 'dsc') {
            this.freqSort = 'asc';
            this.allSlides.sort((a, b) => (a.frequencyValue > b.frequencyValue ? 1 : -1));
        } else {
            this.freqSort = 'dsc';
            this.allSlides.sort((a, b) => (a.frequencyValue > b.frequencyValue ? -1 : 1));
        }

        this.onSlideSetChange();
    }

    // Clear filters from modal
    onClearFilterAndSort() {
        this.resetSort();
        this.filterBySystem(ALL_SYSTEMS_FILTER_VALUE);

        this.modalService.dismissAll();
    }

    filterBySystem(system: string) {
        this.filter = this.titleCaseWord.transform(system);
        let filter = this.filter;

        // check to show "Body Systems" KA when "All Systems" selected
        if (this.filter === ALL_SYSTEMS_FILTER_VALUE) {
            filter = BODY_SYSTEMS_KA_TITLE;
        }
        this.currentSystem = this.allSystemsKAs.find(s => filter === s.title);

        this.currentSystemImage = this.paths.articleImages + this.currentSystem?.images[0].url + '-150.webp';
        this.currentSystemImageAltText = this.currentSystem?.images[0].altText;

        this.showFilters = false;
        this.showMobileFilters = false;
        this.onSlideSetChange();
    }

    openReadMoreModal(event: Event, symptomSlide: SymptomSlide) {
        event.preventDefault();
        this.modalService.open(SymptomsSectionReadmorePopupComponent, {
            customRightModal: true,
            footerShow: false,
            footerFunctionsApply: this.onClearFilterAndSort.bind(this),
            footerFunctionsClear: this.modalService.dismissAll.bind(this),
            data: {
                symptomSlide,
                frequencyKa: this.frequencyKa,
                frequencyTexts: this.frequencyTexts,
                frequencyNumbers: this.frequencyNumbers,
            },
        });
    }

    openSymptomSystemModal(systems: string[]) {
        this.modalService.open(this.systemPopup, {
            customRightModal: true,
            footerShow: false,
            data: {
                system: systems,
            },
        });
    }

    onFilterClick() {
        this.showSymptomsSearch = false;
        this.setNoSearchResults();
        this.showFilters = !this.showFilters;
    }

    onMobileFilterClick() {
        this.showSymptomsSearch = false;
        this.setNoSearchResults();
        this.showMobileFilters = !this.showMobileFilters;
    }

    onSearchFocus() {
        this.searchForm.controls.term.setValue('');
        this.searchFormMobile.controls.termMobile.setValue('');
    }

    redirectToHPO(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
            const tab = window.open(REDIRECT_URL, '_blank');
            tab.opener = null;
        }
    }

    openSearch() {
        this.modalService.open(this.templateSearch, {
            scrollable: false,
            footerShow: false,
        });
    }

    openFilterSort() {
        const modal = this.modalService.openWithData(this.templateFilterSort, {
            data: {
                test: true,
            },
            scrollable: false,
            footerShow: false,
        });

        modal.dismissed.pipe(take(1)).subscribe(() => {
            this.moveToId(this.currentSlide.id);
            this.filterBySystem(this.filter);
        });
    }

    onOutsideFiltersClick() {
        this.showFilters = false;
    }

    onOutsideMobileFiltersClick() {
        this.showMobileFilters = false;
    }

    onOutsideSearchClick() {
        this.showSymptomsSearch = false;
    }

    // Logic for show more clamping functionality
    calculateClamping() {
        if (this.currentSlide.textFull && this.currentSlide.textFull.length && this.currentSlide.synonym && this.currentSlide.synonym.length) {
            this.currentSlide.clampType = 'clampSmall';
        } else {
            this.currentSlide.clampType = 'clampBig';
        }
    }

    private doSearch(searchInputValue: string) {
        const term = searchInputValue?.toLowerCase() || '';
        this.allSearchResults.forEach(result => (result.filterText = term)); // let each search result filter synonyms internally

        if (!searchInputValue) {
            this.searchResults = this.allSearchResults;
            return;
        }

        this.searchResults = this.allSearchResults.filter(result => result.title.toLowerCase().includes(term) || result.synonym.toLowerCase().includes(term));

        if (this.searchResults.length === 0) {
            this.setNoSearchResults();
        }
        this.showSymptomsSearch = true;
    }

    private onSlideSetChange() {
        this.slides = this.allSlides.filter(slide => this.filter === ALL_SYSTEMS_FILTER_VALUE || slide.system.includes(this.filter));

        // this.slides = this.slides.sort((a, b) => (a.title < b.title ? -1 : 1));

        // Make sure the current slide is in the new slide deck (which may or may not have actually changed)
        const index = this.slides.findIndex(slide => this.currentSlide && slide.id === this.currentSlide.id);
        const newIndex = index >= 0 ? index : 0; // if not just grab the first slide

        this.switchToSlide(newIndex);
    }

    private switchToSlide(index: number) {
        this.currentIndex = index;
        this.currentSlide = this.slides[this.currentIndex];

        // Remove this for filter control only
        // this.currentSystem = this.allSystems.find(system => this.currentSlide.system === system.title);
        const i = this.currentIndex;
        const l = this.slides.length;

        this.next1 = this.slides[(i + 1 + l) % l].title;
        this.next2 = this.slides[(i + 2 + l) % l].title;
        this.next3 = this.slides[(i + 3 + l) % l].title;
        this.prev1 = this.slides[(i - 1 + l) % l].title;
        this.prev2 = this.slides[(i - 2 + l) % l]?.title;
        this.prev3 = this.slides[(i - 3 + l) % l]?.title;

        this.searchForm.controls.term.setValue('');
        this.searchFormMobile.controls.termMobile.setValue('');

        setTimeout(() => {
            this.calculateClamping();
            this.scrollList();
            this.switchFeatureOnSlideChange(this.currentSlide.system);
        });
    }

    toggleView() {
        if (this.viewMode === 'list' || !this.hideList) {
            this.filterBySystem(this.filter);
        }
    }

    private switchFeatureOnSlideChange(features: string[]) {
        // reset ALL
        // this.currentSystem = undefined;
        this.currentSystem2 = undefined;
        this.currentSystem3 = undefined;

        // sort for alphabetical order
        features = features.sort();

        if (this.viewMode === 'tile' || this.hideList) {
            if (features[0]) {
                this.currentSystem = this.allSystemsKAs.filter(ka => ka.title === features[0])[0];
                this.currentSystemImage = this.paths.articleImages + this.currentSystem?.images[0].url + '-150.webp';
                this.currentSystemImageAltText = this.currentSystem?.images[0].altText;
            }

            if (features[1]) {
                this.currentSystem2 = this.allSystemsKAs.filter(ka => ka.title === features[1])[0];
                this.currentSystem2Image = this.paths.articleImages + this.currentSystem2?.images[0].url + '-150.webp';
                this.currentSystem2ImageAltText = this.currentSystem2?.images[0].altText;
            }

            if (features[2]) {
                this.currentSystem3 = this.allSystemsKAs.filter(ka => ka.title === features[2])[0];
                this.currentSystem3Image = this.paths.articleImages + this.currentSystem3?.images[0].url + '-150.webp';
                this.currentSystem3ImageAltText = this.currentSystem3?.images[0].altText;
            }
        }

        this.currentSlide.systemsText = this.currentSlide.system.sort().join(', ');

        this.currentSlide.showTitle = this.currentSlide.system.length > 0 || this.slides.length > 0;
    }

    private setNoSearchResults() {
        this.searchResults = EMPTY_SEARCH_RESULTS;
    }

    private scrollList() {
        if (!this.currentSlide || !this.listParent) {
            return;
        }

        const parent = this.listParent.nativeElement;
        const child = parent.children[this.currentIndex];
        const parentRect = parent.getBoundingClientRect();
        const childRect = child.getBoundingClientRect();
        const isChildViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parent.clientHeight;

        // // if you can't see the child try to scroll parent
        if (!isChildViewable) {
            // should we scroll using top or bottom Find the smaller ABS adjustment
            const scrollTop = childRect.top - parentRect.top;
            const scrollBot = childRect.bottom - parentRect.bottom;
            if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                // we're near the top of the list
                parent.scrollTop += scrollTop;
            } else {
                // we're near the bottom of the list
                parent.scrollTop += scrollBot;
            }
        }
    }

    sortSlides(slides): void {
        return slides.sort((a, b) => a - b).join(', ');
    }
}
