import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent {
    isShown = false;
    private readonly showScrollOffset = 150;

    @HostListener('window:scroll')
    onScroll() {
        const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.isShown = offset >= this.showScrollOffset;
    }

    scrollToTop() {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }
}
