import { DiseaseTrimmed } from 'shared/types';

export interface DiseaseWithSynonymString extends DiseaseTrimmed {
    synonymString?: string;
    index?: number;
}

export interface SynonymWithIndex {
    synonym: string;
    index?: number;
}

export const diseasesToDiseasesWithSynonymString = (diseases: DiseaseTrimmed[]): DiseaseWithSynonymString[] => {
    return diseases.map((disease: DiseaseWithSynonymString) => {
        ['.', ',', '(', ')', '[', ']', '{', '}', '"', "'", '’', '‘', '“', '”', '!', '?', ':', ';', '–', '—', '_', '-', '  '].forEach((symbol: string) => {
            disease.name.replaceAll(symbol, ' ');
            // map and replace all synonyms
            if (!Object.hasOwn(disease, 'synonyms') || !Array.isArray(disease.synonyms)) {
                disease.synonyms = [];
            }
            disease.synonyms.map((synonyms: string) => synonyms.replaceAll(symbol, ' '));
            disease.synonymString = disease.synonyms.join(', ');
        });

        return disease;
    });
};

export const globalDiseaseSearch = (search: string, diseases: DiseaseWithSynonymString[]): DiseaseWithSynonymString[] => {
    search = search.toLowerCase();

    // filter by search term
    return diseases
        .map(disease => {
            // get index of search term in disease name
            let index = 1000;

            // contains search in english title
            const dzNameEnIndex = disease.name.toLowerCase().indexOf(search);

            // contains search in synonymString title
            const dzSynonymMatches: SynonymWithIndex[] = disease.synonyms
                .map((synonym: string) => {
                    // hard code offset of 2, so primary name matches come before secondary
                    let index = synonym.toLowerCase().indexOf(search);
                    if (index > -1) {
                        index += 2;
                    }

                    // check for exact match hard code offset of 1, so exact matches come before string start matches
                    if (synonym === search) {
                        index = 1;
                    }

                    return {
                        synonym,
                        index,
                    } as SynonymWithIndex;
                })
                .filter(result => result.index > -1); // remove non-matches

            // Primary name match
            if (dzNameEnIndex > -1) {
                index = Math.min(index, dzNameEnIndex);
            }

            // matches disease id
            if (search.length > 1 && String(disease.id).startsWith(search)) {
                index = 0;
            }

            // Secondary name match
            if (dzSynonymMatches.length) {
                dzSynonymMatches.forEach((synonymWithIndex: SynonymWithIndex) => {
                    index = Math.min(index, synonymWithIndex.index);
                });

                // map out index and join
                disease.synonymString = dzSynonymMatches.map((s: SynonymWithIndex) => s.synonym).join(', ');
            } else {
                // no matches, no other names...
                disease.synonymString = '';
            }

            // no matches
            if (index === 1000) {
                index = -1;
            }

            return {
                index,
                disease,
            };
        })
        .filter(result => result.index > -1) // filter out diseases that don't contain search term
        .sort((a, b) => a.index - b.index) // sort by index of search term in disease name
        .map(result => result.disease); // get disease
};
