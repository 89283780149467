<div
    class="d-flex justify-content-between align-items-center"
    [ngClass]="{ 'mb-2': maxDisplayedSynonyms > 0 }"
    (click)="disease.synonyms.length === 0 ? clickEvent.emit($event) : toggleDisplay()"
>
    <p class="fw-light fs-18" (click)="clickEvent.emit($event)" role="button">
        <i class="icon-gard-search me-2"></i>
        <span>{{ disease.name | titleCaseWord }}</span>
    </p>
    <i *ngIf="disease.synonyms.length > 0" class="small" [ngClass]="[maxDisplayedSynonyms > 0 ? 'icon-gard-drop-arrow-up' : 'icon-gard-drop-arrow-down']"></i>
</div>

<div (click)="clickEvent.emit($event)" role="button">
    <div class="fs-6 ps-4 mb-2 ms-2" *ngFor="let synonym of disease.synonyms | slice : 0 : maxDisplayedSynonyms">
        <i>Synonym: </i> <span>{{ synonym | titleCaseWord }}</span>
    </div>
</div>

<div class="more ps-4 mt-2" *ngIf="maxDisplayedSynonyms > 0 && nextSynonymsAmountToDisplay > 0" (click)="displayMore()">+{{ nextSynonymsAmountToDisplay }}</div>
