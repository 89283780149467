import { Component, ViewEncapsulation } from '@angular/core';
import { TopTab } from 'src/app/components/common/multitab/multitab.types';
import { PanelType } from 'src/app/components/common/multitab/multitab.types';
import { ArticleService } from 'src/app/services/article.service';
import { Page, Section } from 'shared/location-enums';

@Component({
    selector: 'about-gard-website-faq',
    templateUrl: './about-gard-website-faq.component.html',
    styleUrls: ['./about-gard-website-faq.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class Ab4PoliciesComponent {
    tabs = [
        {
            title: '',
            sectionHeader: '',
            type: PanelType.normal,
            items: [],
        },
    ] as TopTab[];

    constructor(articleService: ArticleService) {
        const a1 = articleService.getOne(Page.about, Section.policies, undefined, 1);
        const a2 = articleService.getOne(Page.about, Section.policies, undefined, 2);
        const a3 = articleService.getOne(Page.about, Section.policies, undefined, 3);
        const a4 = articleService.getOne(Page.about, Section.policies, undefined, 4);
        const a5 = articleService.getOne(Page.about, Section.policies, undefined, 5);
        const a6 = articleService.getOne(Page.about, Section.policies, undefined, 6);
        const a7 = articleService.getOne(Page.about, Section.policies, undefined, 7);

        this.tabs[0].sectionHeader = a1.title;
        this.tabs[0].items = [a2, a3, a4, a5, a6, a7];
    }
}
