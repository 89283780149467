/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ElementRef, QueryList, TemplateRef } from '@angular/core';
import { Component, HostListener, ViewChild, ViewChildren } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { AccountService } from 'src/app/services/account.service';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { ACCOUNT_RECORD_TYPES } from 'shared/constants';
import { Section, SubSection, Page } from 'shared/location-enums';
import { Account, Article, Disease } from 'shared/types';
import { arrayToCsv } from 'shared/utils/array-to-csv';
import { NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TitleCaseWordPipe } from 'src/app/pipes/title-case-word.pipe';
import { LocalOrganization, SALESFORCE_ACCOUNT_TITLE } from 'src/app/components/common/organizations/organizations-interfaces';
import { availablePatientAdvocacyGroups } from 'src/app/components/common/organizations/patient-advocacy-groups';

type PropertyNickname = {
    [K in keyof LocalOrganization]: string;
};

type Filter = {
    [K in keyof LocalOrganization]?: string[];
};

interface Sort {
    sortEnabled: boolean;
    field?: keyof LocalOrganization;
    asc: boolean;
}

interface SortState {
    field: keyof LocalOrganization | '';
    asc: boolean;
}

type ServiceArticles = {
    -readonly [K in keyof typeof SALESFORCE_ACCOUNT_TITLE]: Article;
};

@Component({
    selector: 'patient-organizations-accounts',
    templateUrl: './patient-organizations-accounts.component.html',
    styleUrls: ['./patient-organizations-accounts.component.scss'],
})
export class PatientOrganizationsAccountsComponent {
    @ViewChild('filterTemplate') filterTemplate: TemplateRef<ElementRef>;

    @ViewChildren('tooltip') tooltips: QueryList<NgbTooltip>;

    titleCaseWordPipe = new TitleCaseWordPipe();

    localOrganizations: LocalOrganization[] = [];

    organizations: LocalOrganization[] = [];

    tagSpecificOrganizations: LocalOrganization[] = [];

    diseaseSpecificOrganizations: LocalOrganization[] = [];

    currentDisease: Disease;

    patientOrgainationTitle: string = '';

    filterableFields: (keyof LocalOrganization)[] = ['whoTheyServe', 'helpfulLinks', 'country'];

    fieldsNicknames: Partial<PropertyNickname> = {
        whoTheyServe: 'Who They Serve',
        helpfulLinks: 'Helpful Links',
        country: 'Country',
    };

    filterableValues: Filter = {};

    sortState: SortState = {
        field: 'serveOrder',
        asc: true,
    };

    serviceArticles: Partial<ServiceArticles> = {};

    nameProperty: keyof LocalOrganization = 'name';

    serveOrderProperty: keyof LocalOrganization = 'serveOrder';

    linkOrderProperty: keyof LocalOrganization = 'linkOrder';

    countryProperty: keyof LocalOrganization = 'country';

    languagesProperty: keyof LocalOrganization = 'languages';

    private sort: Sort = {
        sortEnabled: false,
        field: 'serveOrder',
        asc: true,
    };

    private previousFilter: Filter = {};

    private filter: Filter = {};

    private modal: NgbModalRef;

    constructor(
        private modalService: ModalService,
        private diseaseService: DiseaseService,
        private fileDownloadService: FileDownloadService,
        private accountService: AccountService,
        private articleService: ArticleService,
    ) {
        this.filterableFields.forEach(field => {
            this.filter[field] = [];
            this.filterableValues[field] = [];
        });

        // Populate Array of Original Accounts

        const patientAdvocacyGroups: Account[] = accountService.getAccounts();

        this.localOrganizations = availablePatientAdvocacyGroups(patientAdvocacyGroups, this.diseaseService.disease, true, true, true);

        this.updateFilteredOrganizations();
        this.updateFilterableFields();

        const articles = articleService.getArticles(Page.livingWith, Section.findOthers, SubSection.organizations);

        const articlesForPatientOrganisation = articleService.getArticles(Page.about, Section.patientOrganizations, SubSection.sectionSubHeader);
        if (articlesForPatientOrganisation && articlesForPatientOrganisation.length) {
            this.patientOrgainationTitle = articlesForPatientOrganisation[0].title;
        }

        for (const article of articles) {
            switch (article.title) {
                case SALESFORCE_ACCOUNT_TITLE.research:
                    this.serviceArticles.research = article;
                    break;

                case SALESFORCE_ACCOUNT_TITLE.specialist:
                    this.serviceArticles.specialist = article;
                    break;

                default:
                    this.serviceArticles.information = article;
                    break;
            }
        }
    }

    @HostListener('scroll')
    onScroll(): void {
        this.tooltips.forEach(tooltip => {
            if (tooltip.isOpen()) {
                tooltip.close();
            }
        });
    }

    download() {
        this.fileDownloadService.csvFileDownload(
            arrayToCsv(
                this.organizations.map(org => ({
                    Name: org.name,
                    Country: org.country,
                    'Organization URL': org.url,
                    'Who They Serve': `People with ${org.whoTheyServe}`,
                    'Expert Directory URL': org.expertDirectoryUrl,
                    'Research Registry URL': org.patientRegistryUrl,
                })) as unknown as Record<string, string | number | Date>[],
            ),
            'organizations.csv',
        );
    }

    openFilterModal() {
        this.previousFilter = JSON.parse(JSON.stringify(this.filter));

        this.modal = this.modalService.open(this.filterTemplate, {
            customRightModal: true,
            footerShow: false,
        });

        this.modal.dismissed.pipe(take(1)).subscribe(() => {
            Object.assign(this.filter, this.previousFilter);

            this.updateFilteredOrganizations();
        });
    }

    sortBy(property: keyof LocalOrganization) {
        this.sort.sortEnabled = true;
        this.sort.asc = property === this.sort.field ? !this.sort.asc : true;
        this.sort.field = property;

        this.sortState = {
            field: this.sort.field,
            asc: this.sort.sortEnabled && this.sort.field === property ? this.sort.asc : false,
        };

        // Alphabetize before sorting (if property is not name)
        if (property !== 'name') {
            this.organizations.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        }

        this.updateFilteredOrganizations();
    }

    toggleFilterableField(field: string, value: string) {
        const list = this.filter[field];

        const index = list.indexOf(value);

        if (index > -1) {
            list.splice(index, 1);
        } else {
            list.push(value);
        }
    }

    isFilterableFieldActive(field: string, value: string) {
        return this.filter[field].indexOf(value) > -1;
    }

    clearFilter() {
        this.filterableFields.forEach(field => (this.filter[field] = []));

        this.updateFilteredOrganizations();
    }

    applyFilter() {
        this.updateFilteredOrganizations();

        this.modal.close();
    }

    private updateFilteredOrganizations() {
        const { sortEnabled, field: sortField, asc } = this.sort;

        this.organizations = this.localOrganizations;

        if (!this.isFilterEmpty()) {
            // For each filterable field, refine the list of organizations
            this.filterableFields.forEach(fieldName => {
                if (fieldName === 'country' && this.filter.country.length > 0) {
                    // Country field name is not an array (edge case)
                    this.organizations = this.organizations.filter(org => {
                        let containsCountry = false;
                        this.filter.country.forEach(country => {
                            if (org.country === country) {
                                containsCountry = true;
                            }
                        });

                        return containsCountry;
                    });
                } else {
                    // Ensure each filter is present within remaining organizations
                    this.organizations = this.organizations.filter(org => this.filter[fieldName].every(filter => (org[fieldName] as string).includes(filter)));
                }
            });
        }

        // Sort filtered organizations
        this.organizations.sort((a, b) => {
            if (!sortEnabled) {
                return 0;
            }

            if (Array.isArray(a[sortField])) {
                return this.compareStringArray(a[sortField] as string[], b[sortField] as string[], asc);
            }

            return this.compareString(a[sortField] as string, b[sortField] as string, asc);
        });
    }

    private compareString(a: string, b: string, asc = true) {
        return a > b ? (asc ? 1 : -1) : a < b ? (asc ? -1 : 1) : 0;
    }

    private compareStringArray(a: string[], b: string[], asc = true) {
        return a > b ? (asc ? 1 : -1) : a < b ? (asc ? -1 : 1) : 0;
    }

    private isFilterEmpty(): boolean {
        for (const key of Object.keys(this.filter)) {
            if (this.filter[key].length > 0) {
                return false;
            }
        }

        return true;
    }

    private addFilterableValue(key: string, value: string) {
        if (!this.filterableValues[key].includes(value)) {
            this.filterableValues[key].push(value);
        }
    }

    private updateFilterableFields() {
        this.localOrganizations.forEach(organization => {
            for (const key of this.filterableFields) {
                const filterableValue = organization[key];

                if (Array.isArray(filterableValue)) {
                    filterableValue.forEach(value => this.addFilterableValue(key, value));

                    continue;
                } else {
                    this.addFilterableValue(key, filterableValue as string);
                }

                this.addFilterableValue(key, filterableValue as string);
            }
        });
    }
}
