import { Component, ViewEncapsulation } from '@angular/core';
import { PanelType, TopTab } from 'src/app/components/common/multitab/multitab.types';
import { ArticleService } from 'src/app/services/article.service';
import { Section as SECTIONS, Page as TABS, SubSection as SUBSECTIONS, Page, Section } from 'shared/location-enums';
import { Article } from 'shared/types';

@Component({
    selector: 'contact-resources',
    templateUrl: './contact-resources.component.html',
    styleUrls: ['./contact-resources.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContactResourcesComponent {
    summary: string;
    textHeader: string;
    tabs = [
        {
            title: 'Suggested Resources to Consider',
            sectionHeader: '',
            type: PanelType.normal,
            items: [],
        },
    ] as TopTab[];

    constructor(private articleService: ArticleService) {
        const mainKa = this.articleService.getOne(TABS.livingWith, SECTIONS.resources, SUBSECTIONS.parent);
        this.textHeader = mainKa.title;
        this.summary = mainKa.bodyText;

        const all: Article[] = articleService.getArticles(TABS.contactUs, SECTIONS.resources);

        this.tabs[0].items = all;
    }
}
