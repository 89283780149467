import { Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Article } from 'shared/types';
import { Section, Page } from 'shared/location-enums';
import { LANGUAGE } from '../../../../../shared/injection-tokens';
import { Language } from '../../../../../shared/salesforce-types';

@Component({
    selector: 'h2-about-gard',
    templateUrl: './h2-about-gard.component.html',
    styleUrls: ['./h2-about-gard.component.scss'],
})
export class H2AboutGardComponent {
    buttonArticle: Article;
    contentArticle: Article;
    articleImagesPath = PATHS.articleImages;
    aboutUrl = `/${PATHS[this.language.value].about}`;
    imageUrl: string;

    constructor(articleService: ArticleService, @Inject(LANGUAGE) private language: BehaviorSubject<Language>) {
        this.contentArticle = articleService.getOne(Page.home, Section.aboutGARD, undefined, 1);
        this.buttonArticle = articleService.getOne(Page.home, Section.aboutGARD, undefined, 2);

        this.imageUrl = this.articleImagesPath + this.contentArticle.images[0]?.url + '-600.webp';
    }
}
