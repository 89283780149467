<ngb-accordion class="accordion-diag-med-team" [attr.id]="prefix" (panelChange)="onPanelChange($event)">
    <ngb-panel *ngFor="let article of articlesDisplayed; let i = index" [title]="article.title" [attr.id]="prefix + '-' + i" [ngClass]="{ active: currentSectionIndex === i }">
        <ng-template ngbPanelContent class="bg-lightblue row">
            <div class="col-12">
                <img class="me-3" *ngIf="article.images[0].url" [src]="articleImagesPath + article.images[0].url + '-250.webp'" [alt]="article.images[0].altText" />
                <div class="gard-align-middle d-inline text-link fs-18" embeddedHTML [innerHTML]="article.bodyText"></div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
