import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
    name: 'clipText',
})
export class ClipTextPipe implements PipeTransform {
    clipAffect = '...';
    transform(value: string, maxTextLength: number): string {
        let str: string;
        if (value.length > maxTextLength + this.clipAffect.length) {
            // Clip text without cutting off any words
            str = value.split(' ').reduce((a, b) => ((a + b).length < maxTextLength ? a + ' ' + b : a));
            str += this.clipAffect;
        } else {
            str = value;
        }
        return str;
    }
}
