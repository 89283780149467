import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { DecimalPipe } from '@angular/common';
@Pipe({
    name: 'numberOrStringToDecimal',
})
export class NumberOrStringToDecimalPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}
    transform(value: number | string, argsToDecimalPipe: string): string {
        if (typeof value === 'string') {
            return value;
        } else {
            return this.decimalPipe.transform(value, argsToDecimalPipe);
        }
    }
}
