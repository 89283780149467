<!-- Desktop -->
<div class="desktop clinical-studies-wrapper container-fluid d-none d-lg-block">
    <div class="container-xxxl">
        <div class="row">
            <div class="col-6 pt-5">
                <div *ngFor="let article of mainKAs; last as isLast" [ngClass]="{ 'mb-5': !isLast }">
                    <h3 class="fs-3 text-maroon mb-4" embeddedHTML [innerHTML]="article.title"></h3>
                    <p class="description fs-18 text-link" embeddedHTML [innerHTML]="article.bodyText"></p>
                </div>
            </div>

            <div class="col-1"></div>

            <div class="col-5 right-side bg-lightblue py-5 px-5">
                <div *ngFor="let article of infoKAs; last as isLast" [ngClass]="{ 'mb-5': !isLast }">
                    <h3 class="fs-3 text-blue mb-4" embeddedHTML [innerHTML]="article.title"></h3>
                    <p class="text-link fs-18 mb-0" embeddedHTML [innerHTML]="article.bodyText"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Mobile -->
<div class="mobile clinical-studies-wrapper d-block d-lg-none">
    <div class="container-fluid pt-4">
        <div class="container-xxxl pt-3">
            <div *ngFor="let article of mainKAs; let ind = index" [ngClass]="{ 'mb-4': true }">
                <h3 class="fs-5 text-maroon mb-4" embeddedHTML [innerHTML]="article.title"></h3>
                <clamper class="fs-6" [maxLines]="5" [text]="article.bodyText" (clampChanged)="clampChanged($event)"> </clamper>
            </div>
        </div>
    </div>

    <div class="container-fluid bg-lightblue py-4">
        <div class="container-xxxl py-3">
            <div *ngFor="let article of infoKAs; last as isLast" [ngClass]="{ 'mb-5': !isLast }">
                <h3 class="fs-5 text-blue mb-4" embeddedHTML [innerHTML]="article.title"></h3>
                <p class="text-link fs-6 mb-0" embeddedHTML [innerHTML]="article.bodyText"></p>
            </div>
        </div>
    </div>
</div>
