export class SearchFilter {
    static nextId = 0;
    id: number;
    filterTypeText: string;
    filterTypeInternal: string;

    constructor(public filterType: string, public filterText: string, public enabled: boolean = true) {
        this.id = ++SearchFilter.nextId;

        switch (filterType) {
            case 'letter':
                this.filterTypeText = 'Browse by Letter: ';
                break;
            case 'category':
                this.filterTypeText = '';
                break;
            default:
                throw new Error(`Unknown Filter Type: ${filterType}`);
        }
    }
}
