/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Disease } from 'shared/types';

@Component({
    selector: 'app-mobile-search-item',
    templateUrl: './mobile-search-item.component.html',
    styleUrls: ['./mobile-search-item.component.scss'],
})
export class MobileSearchItemComponent {
    // TODO: Refactor this...
    // id: number; name: string; encodedName: string; synonyms: string[]; tagsDiseaseCategory: any[]; tagsSpecialist: any[]; tagsCause: any[];
    // component: { id: number; name: string; encodedName: string; synonyms: string[]; tagsDiseaseCategory: any[]; tagsSpecialist: any[]; tagsCause: any[] };

    @Input() disease: Disease;
    @Output() clickEvent = new EventEmitter<PointerEvent>();

    maxDisplayedSynonyms = 0;

    get nextSynonymsAmountToDisplay(): number {
        return Math.min(4, this.disease.synonyms.length - this.maxDisplayedSynonyms);
    }

    displayMore() {
        this.maxDisplayedSynonyms += this.nextSynonymsAmountToDisplay;
    }

    toggleDisplay() {
        if (this.maxDisplayedSynonyms === 0) {
            this.displayMore();

            return;
        }

        this.maxDisplayedSynonyms = 0;
    }
}
