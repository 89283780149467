/* eslint-disable  @typescript-eslint/no-explicit-any */
import { TemplateRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalBaseComponent } from '../components/common/modal-base/modal-base.component';
import { Dictionary, NonFunctionProperties } from '../../../shared/types';

export interface ModalOptions<T = Dictionary<any>> extends NgbModalOptions {
    data?: NonFunctionProperties<Partial<T>>;
    customRightModal?: boolean;
    showCloseButtons?: boolean;
    templateContext?: Dictionary<any>;
    backHandler?: () => any;
    footerShow: boolean;
    footerFunctionsClear?: () => void;
    footerFunctionsApply?: () => void;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private defaultOptions: ModalOptions = {
        data: {},
        customRightModal: true,
        showCloseButtons: true,
        scrollable: true,
        templateContext: {},
        footerShow: false,
    };

    constructor(private mainService: NgbModal) {}

    open<T>(component: (new (...args: any[]) => T) | TemplateRef<any>, options?: ModalOptions<T>) {
        options = Object.assign({ ...this.defaultOptions }, options);

        if (options.customRightModal) {
            options.windowClass = 'custom-right-modal';
        }

        const modal = this.mainService.open(ModalBaseComponent, options);

        const instance = modal.componentInstance;
        instance.options = options;
        instance.component = component;

        return modal;
    }

    openWithData<T>(component: (new (...args: any[]) => T) | TemplateRef<any>, data: ModalOptions<T>['data'], options?: ModalOptions<T>) {
        return this.open(component, {
            ...options,
            data,
        });
    }

    get activeInstances() {
        return this.mainService.activeInstances;
    }

    dismissAll(reason?: any): void {
        this.mainService.dismissAll(reason);
    }

    hasOpenModals(): boolean {
        return this.mainService.hasOpenModals();
    }
}
