import { OnInit, ElementRef } from '@angular/core';
import { Component, Input } from '@angular/core';
import { randomId } from 'shared/utils/random-id';
import { DiseaseService } from 'src/app/services/disease.service';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Page, Section, SubSection } from 'shared/location-enums';
import { Article, Disease, Image } from 'shared/types';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'your-medical-team-accordion',
    templateUrl: './your-medical-team-accordion.component.html',
    styleUrls: ['./your-medical-team-accordion.component.scss'],
})
export class YourMedicalTeamAccordionComponent implements OnInit {
    @Input() articles: Article[];
    public prefix = randomId();
    public medTeamArticle: Article;
    public articlesDisplayed: Article[] = [];
    public currentSectionIndex: boolean | number = false;
    public articleImagesPath: string = PATHS.articleImages;

    constructor(articleService: ArticleService, diseaseService: DiseaseService, private elementRef: ElementRef) {}

    ngOnInit(): void {
        // select which group we are showing
        this.articlesDisplayed = this.articles;
    }

    onClick(i: number) {
        this.currentSectionIndex = i === this.currentSectionIndex ? -1 : i;
    }

    onPanelChange(event: NgbPanelChangeEvent) {
        if (event.nextState) {
            setTimeout(() => {
                const parentElement = this.elementRef.nativeElement.parentElement;
                const panelElement = document.getElementById(event.panelId);
                if (parentElement && panelElement) {
                    const parentRect = parentElement.getBoundingClientRect();
                    const panelRect = panelElement.getBoundingClientRect();
                    const scrollTop = panelRect.top - parentRect.top + parentElement.scrollTop - 70;
                    parentElement.scrollTop = scrollTop;
                }
            }, 200);
        }
    }
}
