/* eslint-disable prefer-rest-params */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Section as SECTIONS, SubSection as SUBSECTIONS, Page as TABS } from '../../../shared/location-enums';
import { TAG_CATEGORIES } from '../../../shared/constants';
import { PATHS } from '../../../shared/paths';
import { Account } from '../../../shared/types';
import { SalesforceAccountPayload, TagAccountAssociation } from '../../../shared/salesforce-types';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorLogService } from './error-log.service';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private accounts: Account[] = [];

    constructor(private http: HttpClient, private errorLogService: ErrorLogService) {}

    static convertSalesforceDataToAccount(account: SalesforceAccountPayload): Account {
        const diseaseCategory: string[] = [];
        (account.tagAccountAssociationList || []).forEach((tagAccountAssociation: TagAccountAssociation) => {
            if ((tagAccountAssociation.Tag_Category__c || '').split(';').includes(TAG_CATEGORIES.diseaseCategory)) {
                diseaseCategory.push(tagAccountAssociation.Tag_Name__c);
            }
        });

        const epidemiology: string[] = [];
        (account.tagAccountAssociationList || []).forEach((tagAccountAssociation: TagAccountAssociation) => {
            if ((tagAccountAssociation.Tag_Category__c || '').split(';').includes(TAG_CATEGORIES.epidemiology)) {
                epidemiology.push(tagAccountAssociation.Tag_Name__c);
            }
        });

        return {
            id: account.acct.Id,
            name: account.acct.Name,
            country: account.acct.Country__c,
            website: account.acct.Website,
            additionalPostedInfo: account.acct.Additional_Posted_Information__c,
            tab: account.acct.Web_Page_Tab__c,
            section: account.acct.Web_Page_Section__c,
            subSection: account.acct.Web_Page_Sub_Section__c,
            order: parseInt(account.acct.Web_Page_Order__c, 10),
            expertDirectoryUrl: account.acct.Expert_Directory_URL__c,
            patientRegistryUrl: account.acct.Patient_Registry_URL__c,
            contentDistributionList: account.contentDistributionList || [],
            diseaseCategory,
            epidemiology,
            tags: (account.tagAccountAssociationList || []).map(acctTag => acctTag.Tag_Name__c),
        } as Account;
    }

    fetch(): Observable<Account[]> {
        return this.httpGet().pipe(
            take(1),
            map(as => as.map(AccountService.convertSalesforceDataToAccount)),
            tap(accounts => {
                this.accounts = accounts.sort((a, b) => (a.order || 0) - (b.order || 0));
            }),
        );
    }

    getOne(tab?: TABS, section?: SECTIONS, subsection?: SUBSECTIONS): Account {
        // pull original account list, using filters, if provided
        const allAccounts: Account[] = this.getAccounts(tab, section, subsection);

        if (allAccounts.length === 1) {
            // Good return
            return allAccounts[0];
        } else {
            // Error cases
            const criteria: string[] = [];

            ['tab', 'section', 'subsection', 'allPages'].forEach((key, index) => {
                if (arguments[index] !== undefined) {
                    criteria.push(`${key}: ${arguments[index]}`);
                }
            });

            let errorMessage = '';
            if (allAccounts.length === 0) {
                errorMessage = `AccountService.getOne()\nNo account matching criteria:\n${criteria.join('\n')}\n`;
            } else {
                errorMessage = `AccountService.getOne()\nMultiple (${allAccounts.length}) accounts matching criteria:\n${criteria.join('\n')}\n`;
            }

            this.errorLogService.log('Account', errorMessage);

            return undefined;
        }
    }

    getAccounts(tab?: TABS, section?: SECTIONS, subsection?: SUBSECTIONS): Account[] {
        return this.accounts.filter(
            account =>
                (tab === undefined || account.tab === tab) &&
                (section === undefined || account.section === section) &&
                (subsection === undefined || account.subSection === subsection),
        );
    }

    httpGet(): Observable<SalesforceAccountPayload[]> {
        return this.http.get<SalesforceAccountPayload[]>(PATHS.accountApi);
    }
}
