import { OnDestroy } from '@angular/core';
import { Component, HostListener, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { MessageBusService } from 'src/app/services/message-bus.service';
import { MESSAGE_KEYS } from 'shared/constants';
import { PATHS } from 'shared/paths';
import { Page, Section } from 'shared/location-enums';
import { ArticleService } from '../../../services/article.service';
import { LANGUAGE } from '../../../../../shared/injection-tokens';
import { LABELS } from '../../../../../shared/labels';
import { Language } from '../../../../../shared/salesforce-types';

interface ContactCenter {
    title?: string;
    phone?: string;
}

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
    styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnDestroy {
    isMenuOpen = false;
    isSearchOpen = false;
    paths = {
        diseases: '/' + PATHS[this.language.value].diseases,
        resources: '/' + PATHS[this.language.value].resources,
        about: '/' + PATHS[this.language.value].about,
        contactUs: '/' + PATHS[this.language.value].contactUs,
    };

    contactCenter: ContactCenter = {};
    labels = LABELS[this.language.value];

    about = this.articleService.getOne(Page.about, Section.header, undefined, 1).title;
    resources = Section.resources;

    private sub = new Subscription();

    constructor(private messageBusService: MessageBusService, private articleService: ArticleService, @Inject(LANGUAGE) private language: BehaviorSubject<Language>) {
        this.sub.add(
            this.messageBusService.get(MESSAGE_KEYS.searchOpen).subscribe(isOpen => {
                this.isSearchOpen = !!isOpen;
            }),
        );

        const centerKa = this.articleService.getOne(Page.all, Section.header);
        this.contactCenter.title = centerKa.title;
        this.contactCenter.phone = centerKa.bodyText;
    }

    @HostListener('window:keydown.esc')
    closeSearch() {
        this.isSearchOpen = false;
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(false);
        (document.activeElement as HTMLElement)?.blur();
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.isSearchOpen = false;
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(false);
    }

    toggleSearch($event: MouseEvent) {
        $event.preventDefault();
        this.isSearchOpen = !this.isSearchOpen;
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(this.isSearchOpen);
        this.isMenuOpen = false;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
