import { Component, Input } from '@angular/core';
import { PATHS } from 'shared/paths';
import { Article } from 'shared/types';

@Component({
    selector: 'single-ka-left-only-lightgray',
    templateUrl: './single-ka-left-only-lightgray.component.html',
    styleUrls: ['./single-ka-left-only-lightgray.component.scss'],
})
export class SingleKaLeftOnlyLightgrayComponent {
    @Input() public article: Article;

    public imgPath = PATHS.articleImages;
}
