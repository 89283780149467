import { Component, ViewEncapsulation } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Article } from 'shared/types';
import { Page, Section, SubSection, SubSubsection } from 'shared/location-enums';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
    selector: 'clinical-trials-contact-us',
    templateUrl: './clinical-trials-contact-us.component.html',
    styleUrls: ['./clinical-trials-contact-us.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClinicalTrialsContactUsComponent {
    public articleImagesPath = PATHS.articleImages;
    diseaseSearchUrl: string;

    clinicalTrialArticle: Article;
    clinicalTrialButton: Article;

    contactCenter: Article;
    contactPhone: Article;
    contactArticle: Article;
    contactArticle2: Article;
    imgPathBase = PATHS.assets;

    contactButtonUrl: string;

    constructor(articleService: ArticleService, diseaseService: DiseaseService) {
        this.clinicalTrialArticle = articleService.getOne(Page.about, Section.research, SubSection.clinicalTrialsGov, undefined, undefined, false, SubSubsection.intro);

        this.clinicalTrialButton = articleService.getOne(Page.about, Section.research, SubSection.clinicalTrialsGov, undefined, undefined, false, SubSubsection.button);

        this.contactArticle = articleService.getOne(Page.about, Section.research, SubSection.GARDContactCenter, 1, undefined, false, SubSubsection.intro);

        this.contactArticle2 = articleService.getOne(Page.about, Section.research, SubSection.GARDContactCenter, 2, undefined, false, SubSubsection.intro);

        this.contactCenter = articleService.getOne(Page.all, Section.gardContactCenter, SubSection.contactCenterInfo, undefined, undefined, false);

        this.contactPhone = articleService.getOne(Page.all, Section.header);

        this.contactButtonUrl = this.contactCenter.linkUrl;

        // Get disease service URL
        const currentDisease = diseaseService.disease.name.toLowerCase().replace(/ /g, '+');
        this.diseaseSearchUrl = `https://www.clinicaltrials.gov/ct2/results?cond=${currentDisease}&recrs=b&recrs=a`;
    }
}
