export function encodeName(string: string) {
  if (!string) {
    return '';
  }

  const encodedString = encodeURI(
    string
      .replace(/%E2%80%93/g, '')
      .replace(/ /g, '-')
      .replace(/----/g, '-')
      .replace(/---/g, '-')
      .replace(/--/g, '-')
      .replace(/[^\w-]+/g, '')
      .toLowerCase(),
  );

  return encodedString || '';
}
