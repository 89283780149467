import { OnInit, ElementRef } from '@angular/core';
import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[autofocus]',
})
export class AutoFocusDirective implements OnInit {
    private autoFocus: boolean;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        if (this.autoFocus || typeof this.autoFocus == 'undefined') {
            this.el.nativeElement.focus();
        }
    }

    @Input() set autofocus(condition: boolean) {
        this.autoFocus = !condition;
    }
}
