import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dictionary, MessageKey } from '../../../shared/types';

@Injectable({
    providedIn: 'root',
})
export class MessageBusService {
    private cache: Dictionary<BehaviorSubject<unknown>> = {};

    public get<T>(key: MessageKey): BehaviorSubject<T> {
        const pKey = key.key;
        if (!this.cache[pKey]) {
            this.cache[pKey] = new BehaviorSubject<T>(undefined);
        }
        return this.cache[pKey] as BehaviorSubject<T>;
    }
}
