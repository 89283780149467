/* eslint-disable @typescript-eslint/naming-convention */
import { OnInit } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MultitabItem, TopTab } from 'src/app/components/common/multitab/multitab.types';
import { PanelType } from 'src/app/components/common/multitab/multitab.types';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { DiagnosisItem, Feature } from 'shared/salesforce-types';
import { DiagnosisItemTypePattern, SymptomPatterns } from 'shared/salesforce-types';
import { Article, Dictionary, Disease } from 'shared/types';
import { Page, Section, SubSubsection, SubSection } from 'shared/location-enums';

@Component({
    selector: 'appointments-and-diagnostic-evaluations',
    templateUrl: './appointments-and-diagnostic-evaluations.component.html',
    styleUrls: ['./appointments-and-diagnostic-evaluations.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppointmentsAndDiagnosticEvaluationsComponent implements OnInit {
    // create empty tabs
    public tabs: TopTab[] = [
        {
            title: 'Talking With Your Doctor',
            section: Section.workingWithDoctor,
            subSection: SubSection.talkingWithDoctor,
            order: 0,
            type: PanelType.normal,
            items: [] as MultitabItem[],
        },
        {
            title: 'Diagnostic Tests and Procedures',
            section: Section.workingWithDoctor,
            subSection: SubSection.diagnosticTests,
            order: 1,
            type: PanelType.normal,
            items: [] as MultitabItem[],
        },
    ];

    tagPatterns: Dictionary<() => boolean>;
    tagPatternsKeys: string[];
    subSections: string[];

    constructor(private articleService: ArticleService, private diseaseService: DiseaseService) {
        const disease = this.diseaseService.disease;

        // dictionary of conditional logic to filter out articles based on disease prop values, tag matching regex is used as key
        this.tagPatterns = {
            [`^Newborn Screening$`]: () => disease.diagnosis.some(d => DiagnosisItemTypePattern.NEWBORN.test(d.Type__c)),
            [`^GTR$`]: () => disease.diagnosis.some(d => DiagnosisItemTypePattern.GTR.test(d.Type__c)) && disease.tagsDiseaseCategory.includes('Genetics'),
            [`^Lab_Findings.*`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Lab.test(s.Feature_Type__c)),
            [`^Procedure_EKG$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Procedure_EKG.test(s.Feature_Type__c)),
            [`^Procedure_EEG$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Procedure_EEG.test(s.Feature_Type__c)),
            [`^Procedure_EMG$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Procedure_EMG.test(s.Feature_Type__c)),
            [`^Procedure_NCV$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Procedure_NCV.test(s.Feature_Type__c)),
            [`^Procedure_PFT$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Procedure_PFT.test(s.Feature_Type__c)),
            [`^Imaging_MRI$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Imaging_MRI.test(s.Feature_Type__c)),
            [`^Imaging_PET$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Imaging_PET.test(s.Feature_Type__c)),
            [`^Imaging_CT$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Imaging_CT.test(s.Feature_Type__c)),
            [`^Imaging_Ultrasound$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Imaging_Ultrasound.test(s.Feature_Type__c)),
            [`^Imaging_Ultrasound_Fetal$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Imaging_Ultrasound_Fetal.test(s.Feature_Type__c)),
            [`^Imaging_Echocardiogram$`]: () => disease.symptoms.some(s => DiagnosisItemTypePattern.Imaging_Echocardiogram.test(s.Feature_Type__c)),
        } as Dictionary<() => boolean>;
        this.tagPatternsKeys = Object.keys(this.tagPatterns);

        // store values as flat string arrays for easy index search
        this.subSections = this.tabs.map(s => s.subSection);
    }

    static isNull(disease: Disease) {
        return !(disease.diagnosis.length && disease.diagnosis.some(d => DiagnosisItemTypePattern.GTR.test(d.Type__c)) && disease.tagsDiseaseCategory.includes('Genetics'));
    }

    ngOnInit() {
        const talkingWithDoctorTab = this.talkingWithDoctor(this.tabs[0]);

        const diagnosticTestsTab = this.diagnosticTests(this.tabs[1]);

        // set binding
        this.tabs = [talkingWithDoctorTab, diagnosticTestsTab];
    }

    reduceArticles(articles: Article[]): Article {
        // copy because we're going to be modifing it
        const article: Article = JSON.parse(JSON.stringify(articles[0]));

        articles.forEach((a: Article, index: number) => {
            if (index > 0) {
                article.bodyText = `${article.bodyText} <p class='ka-spacer mt-3'>${a.bodyText}</p>`;
            }
        });

        return article;
    }

    removeAccounts(articles: Article[]): Article[] {
        articles.map(a => JSON.parse(JSON.stringify(a)));
        return articles.map((a: Article) => {
            a.accounts = [];
            return a;
        });
    }

    talkingWithDoctor(tab: TopTab): TopTab {
        const prepare: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.talkingWithDoctor, undefined, undefined, false, SubSubsection.prepare),
        );
        const engage: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.talkingWithDoctor, undefined, undefined, false, SubSubsection.engage),
        );
        const stress: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.talkingWithDoctor, undefined, undefined, false, SubSubsection.stress),
        );

        tab.items.push(this.reduceArticles(prepare));
        tab.items.push(this.reduceArticles(engage));
        tab.items.push(this.reduceArticles(stress));

        return tab;
    }

    diagnosticTests(tab: TopTab): TopTab {
        // medicalHx shows always
        const medicalHx: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.medicalHx),
        );
        tab.items.push(this.reduceArticles(medicalHx));

        // get disease tags into simple lowercase string array
        const tags: string[] = [];

        // Tests from Diagnosis
        const arrDiagnosis = this.diseaseService.disease.diagnosis.map((d: DiagnosisItem) => (d.Type__c as string).toLowerCase());
        arrDiagnosis.forEach(diagnosis => {
            if (diagnosis.indexOf(';')) {
                tags.push(...diagnosis.split('; '));
            } else if (diagnosis) {
                tags.push(diagnosis);
            }
        });

        // Tests from features
        const arrFeatures = this.diseaseService.disease.features.map((s: Feature) => (s.Feature_Type__c as string).toLowerCase());
        arrFeatures.forEach(feature => {
            if (feature.indexOf(';')) {
                tags.push(...feature.split('; '));
            } else if (feature) {
                tags.push(feature);
            }
        });

        // Newborn
        if (tags.includes('newborn')) {
            const newbornScreening: Article[] = this.removeAccounts(
                this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.newbornScreening),
            );
            tab.items.push(this.reduceArticles(newbornScreening));
        }

        // Genetics
        if (tags.includes('genetics') || tags.includes('gtr')) {
            let geneticTesting: Article[] = this.removeAccounts(
                this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.geneticTesting),
            );
            geneticTesting = geneticTesting.map((article: Article) => {
                article.bodyText = article.bodyText.replace('${DiseaseName}', this.diseaseService.disease.name);
                return article;
            });
            tab.items.push(this.reduceArticles(geneticTesting));
        }

        // Procedure
        const swProcedures = tags.filter((tag: string) => tag.startsWith('procedure'));
        if (swProcedures.length > 0) {
            const proceduresNotNull: MultitabItem = this.articleService.getOne(
                Page.diagnosis,
                Section.workingWithDoctor,
                SubSection.diagnosticTests,
                undefined,
                undefined,
                false,
                SubSubsection.procedures,
            );
            proceduresNotNull.items = [];

            // check for all procedures
            const specificProcedures = swProcedures.map((procedure: string) => {
                const split = procedure.split('_');
                if (split.length === 2) {
                    return split[1];
                } else {
                    return procedure;
                }
            });

            if (specificProcedures.includes('ekg')) {
                proceduresNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.ekg),
                );
            }

            if (specificProcedures.includes('eeg')) {
                proceduresNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.eeg),
                );
            }

            if (specificProcedures.includes('emg')) {
                proceduresNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.emg),
                );
            }

            if (specificProcedures.includes('ncv')) {
                proceduresNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.nerveCondition),
                );
            }

            if (specificProcedures.includes('pft')) {
                proceduresNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.ekg),
                );
            }

            tab.items.push(proceduresNotNull);
        } else {
            const proceduresNull: Article[] = this.removeAccounts(
                this.articleService.getArticles(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, true, SubSubsection.procedures),
            );
            tab.items.push(this.reduceArticles(proceduresNull));
        }

        // Laboratory
        const testsLaboratory = this.diseaseService.disease.labs.map((s: Feature) => (s.HPO_Name__c as string).toLowerCase());
        if (testsLaboratory.length > 0) {
            // Add first KA
            const laboratoryNotNull: Article[] = [];
            const laboratoryNotNullHeaderArticle: Article = this.articleService.getOne(
                Page.diagnosis,
                Section.workingWithDoctor,
                SubSection.diagnosticTests,
                undefined,
                undefined,
                false,
                SubSubsection.laboratory,
            );

            // convert findings into KA format for reducer
            testsLaboratory.forEach((finding: string) => {
                const ka: Article = {
                    bodyText: finding,
                    id: '',
                    title: '',
                    nullVersion: false,
                };
                laboratoryNotNull.push(ka);
            });

            const sorted: Article[] = laboratoryNotNull.sort((a: Article, b: Article) => a.bodyText.localeCompare(b.bodyText));

            // add the intro article, to the start of array
            sorted.unshift(laboratoryNotNullHeaderArticle);

            // sort, then reduce, then push for output
            tab.items.push(this.reduceArticles(sorted));
        } else {
            const laboratoryNull: Article = this.articleService.getOne(
                Page.diagnosis,
                Section.workingWithDoctor,
                SubSection.diagnosticTests,
                undefined,
                undefined,
                true,
                SubSubsection.laboratory,
            );
            tab.items.push(laboratoryNull);
        }

        // Imaging
        const swImaging = tags.filter((tag: string) => tag.startsWith('imaging'));
        if (swImaging.length > 0) {
            const imagingNotNull: MultitabItem = this.articleService.getOne(
                Page.diagnosis,
                Section.workingWithDoctor,
                SubSection.diagnosticTests,
                undefined,
                undefined,
                false,
                SubSubsection.imaging,
            );
            imagingNotNull.items = [];

            // check for all procedures
            const specificImaging = swImaging.map((imaging: string) => {
                const split = imaging.split('_');
                const imaging0 = split.shift();
                if (split.length === 0) {
                    return imaging0;
                } else {
                    return split.join('_');
                }
            });

            if (specificImaging.includes('mri')) {
                imagingNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.mri),
                );
            }

            if (specificImaging.includes('ct')) {
                imagingNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.ct),
                );
            }

            if (specificImaging.includes('ultrasound')) {
                imagingNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.ultrasound),
                );
            }

            if (specificImaging.includes('fetalultrasound') || specificImaging.includes('ultrasound_fetal')) {
                imagingNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.fetalUltrasound),
                );
            }

            if (specificImaging.includes('petscan') || specificImaging.includes('pet')) {
                imagingNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.petscan),
                );
            }

            if (specificImaging.includes('echo') || specificImaging.includes('echocardiogram')) {
                imagingNotNull.items.push(
                    this.articleService.getOne(Page.diagnosis, Section.workingWithDoctor, SubSection.diagnosticTests, undefined, undefined, false, SubSubsection.echo),
                );
            }

            tab.items.push(imagingNotNull);
        } else {
            const imagingNull: Article = this.articleService.getOne(
                Page.diagnosis,
                Section.workingWithDoctor,
                SubSection.diagnosticTests,
                undefined,
                undefined,
                true,
                SubSubsection.imaging,
            );
            tab.items.push(imagingNull);
        }

        return tab;
    }

    processArticleNoTags(article: Article) {
        // if this is a null article - skip it
        if (article.nullVersion) {
            return;
        }

        if (article.tags?.length > 0) {
            return;
        }

        const tabIndex = this.subSections.indexOf(article.subSection);
        // If it's a button, article.displayTitle === true, and no tags
        if (article.displayTitle && article.tags.length === 0) {
            try {
                this.tabs[tabIndex].items.push(article);
                this.tabs[tabIndex].items[this.tabs[tabIndex].items.length - 1].panelTitle = article.title;
                // eslint-disable-next-line no-empty
            } catch (e) {}
        } else {
            const parent = this.tabs[tabIndex].items.find(p => article.tags?.length && p.tags?.indexOf(article.tags[0]) > -1);
            if (parent) {
                parent.items = parent.items || [];
                parent.items.push(article);
                parent.items[parent.items.length - 1].panelTitle = article.title;
            }
        }
    }

    processArticleWithTags(articles: Article[], article: Article) {
        // if this is a null article - skip it
        if (article.nullVersion) {
            return;
        }

        let canBeAdded = false;

        if (article.tags?.length === 0) {
            return;
        }

        if (article.tags?.length > 0) {
            // find patterns that match any of the article tags
            const matchingPatterns = this.tagPatternsKeys.filter(ex => article.tags.some(tag => new RegExp(ex).test(tag)));
            if (matchingPatterns.length) {
                // found matching conditions
                const conditionPassed = matchingPatterns.some(ex => this.tagPatterns[ex]()); // run conditional logic
                if (conditionPassed) {
                    canBeAdded = true; // if regex matches and condition passes - add item
                } else {
                    // disease data does not match condition, try looking for a null article with the same order
                    const nullArticle = articles.find(a => a.order >= 0 && a.order === article.order && a.nullVersion === true);
                    if (nullArticle) {
                        // found a null article, add it in place of the origin article
                        article = nullArticle;
                        canBeAdded = true;
                    }
                }
            } else {
                canBeAdded = true; // if there are no regex that match the tag, no need to run condition check
            }
        } else {
            canBeAdded = true; // if there are no tags, no need to run condition check
        }

        if (canBeAdded) {
            const tabIndex = this.subSections.indexOf(article.subSection);
            // If it's a button, article.displayTitle === true, and no tags
            if (article.displayTitle && article.tags.length === 0) {
                try {
                    this.tabs[tabIndex].items.push(article);
                    this.tabs[tabIndex].items[this.tabs[tabIndex].items.length - 1].panelTitle = article.title;
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            } else {
                const parent = this.tabs[tabIndex].items.find(p => article.tags?.length && p.tags?.indexOf(article.tags[0]) > -1);
                if (parent) {
                    parent.items = parent.items || [];
                    parent.items.push(article);
                    parent.items[parent.items.length - 1].panelTitle = article.title;
                }
            }

            /*----  custom logic per requirements ----*/
            // add lab findings from disease object to article to display on page
            if (article.tags?.length > 0 && canBeAdded && article.tags.some(tag => new RegExp(`^Lab_Findings.*`).test(tag))) {
                const regex = new RegExp(SymptomPatterns.Lab);
                const findings = this.diseaseService.disease.symptoms.filter(s => regex.test(s.Feature_Type__c)).map(s => s.HPO_Name__c);
                (article as MultitabItem).bullets = findings;
            }
        }
    }
}
