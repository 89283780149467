import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Section, Page } from 'shared/location-enums';

@Component({
    selector: 'app-bottom-sources-date-information',
    templateUrl: './bottom-sources-date-information.component.html',
    styleUrls: ['./bottom-sources-date-information.component.scss'],
})
export class BottomSourcesDateInformationComponent {
    public lastUpdatedDate: string;

    constructor(articleService: ArticleService) {
        const articles = articleService.getArticles(Page.all, Section.lastUpdate);
        const article = articles.pop();
        this.lastUpdatedDate = article.bodyText;
    }
}
