<div class="main-banner-container" [ngClass]="headerClass">
    <div class="rings-container">
        <!-- Rings and Circles -->
        <div class="circle circle1"></div>
        <div class="circle circle2"></div>
        <div class="circle circle3"></div>
        <div class="circle circle4"></div>
        <div class="circle circle-ring-1"></div>
        <div class="circle circle-ring-2"></div>
        <div class="circle circle-ring-3"></div>
        <div class="circle circle-ring-4"></div>
    </div>

    <div class="position-relative all banner-image-cover header-section" [ngClass]="headerClass">
        <div *ngIf="shouldShowSearch">
            <!-- Search Overlay -->
            <div
                class="white-overlay bg-white position-fixed w-100 vh-100"
                (click)="hide()"
                (keyup.escape)="hide()"
                [hidden]="!isSearchOpen"
                [attr.aria-hidden]="!isSearchOpen"
            ></div>

            <div class="desktop px-4 px-md-0 h-100 w-100 d-flex align-items-center" (click)="show()" [ngClass]="{ home: !isSearchOpen, 'on-top': isSearchOpen }">
                <!-- Language Toggle -->
                <div *ngIf="!isSearchOpen" class="lang-toggle container-fluid px-0 py-2 w-100 position-absolute">
                    <div class="container-xxxl my-auto d-flex align-items-center">
                        <a class="lang-button ms-auto me-1 px-3 py-1 btn btn-outline-white flex-shrink-1 fw-400" [routerLink]="languageRedirect">{{ languageBtnText }}</a>
                    </div>
                </div>
                <div class="container-xxxl">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-10 px-0">
                            <!-- Search Input -->
                            <div class="input d-flex align-items-center py-2 px-0 text-white border-bottom border-white border-2">
                                <label class="d-none" for="homepage-search">Homepage Search</label>
                                <input
                                    class="fs-3 fs-md-1 fs-lg-0 p-0 border-0 fw-bold text-white w-100 bg-transparent"
                                    type="text"
                                    id="homepage-search"
                                    [placeholder]="inputTextArticle?.title"
                                    (keyup)="updateDiseases($event.target.value)"
                                    #desktopInput
                                    (keyup.enter)="redirectToSearchPage()"
                                    (focus)="show()"
                                    autocomplete="off"
                                />
                                <i class="icon-gard-search fs-4 fs-md-1 fs-lg-0 fw-200" (click)="redirectToSearchPage()" role="button" aria-label="Search"></i>
                            </div>
                            <!-- Search Results -->
                            <div class="search-results-wrapper position-relative" *ngIf="diseases.length">
                                <div class="search-results position-absolute bg-white w-100 py-4 mt-4 overflow-auto" [hidden]="areResultsHidden || !isSearchOpen">
                                    <p class="fw-light fs-18 mb-2 px-4 my-0">Suggestions</p>
                                    <ul class="flex-column w-100 m-0 p-0 list-unstyled">
                                        <li *ngFor="let disease of diseases" role="button">
                                            <a
                                                class="d-flex px-4 py-3 text-reset text-decoration-none"
                                                tabindex="0"
                                                (keydown.enter)="redirectToDisease($event, disease)"
                                                (click)="redirectToDisease($event, disease)"
                                            >
                                                <h5 class="text-green me-3">
                                                    <i class="icon-gard-search"></i>
                                                </h5>
                                                <div>
                                                    <h5
                                                        class="text-green fs-22 mb-3"
                                                        [ngClass]="[!disease.filteredSynonyms || disease.filteredSynonyms.length === 0 ? 'mb-0' : 'mb-2']"
                                                    >
                                                        {{ disease.name }}
                                                    </h5>
                                                    <div class="synonym mb-2 fs-18" *ngIf="disease.synonymString">
                                                        <i class="me-2 fw-light">Other Names: </i>
                                                        <b class="fw-500">{{ disease.synonymString }}</b>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="background-gradient">
                    <!-- Gradient Circles -->
                    <div class="gradient-container">
                        <div class="circle-top-left-transparent"></div>
                        <div class="circle-top-transparent"></div>
                        <div class="circle-small-left"></div>
                        <div class="circle-bottom-left"></div>
                        <div class="circle-bottom-transparent"></div>
                        <div class="circle-top-right-transparent"></div>
                        <div class="circle-small-right"></div>
                    </div>
                </div>
            </div>

            <div class="mobile-search w-100 h-100 d-lg-none position-fixed text-white" *ngIf="isSearchOpen" [ngClass]="{ 'on-top': isSearchOpen }">
                <!-- Mobile Search -->
                <div class="back-container px-4 py-4" aria-label="Back" role="button" (click)="hide()">
                    <div class="back d-flex align-items-center">
                        <div class="circle d-flex align-items-center justify-content-center me-2 border border-1 border-white rounded-circle">
                            <i class="icon-gard-arrow-left"></i>
                        </div>
                        <p class="fs-5 fw-200 d-inline-block mb-0">Back</p>
                    </div>
                </div>
                <div class="px-4">
                    <!-- Mobile Search Input -->
                    <div class="input d-flex mb-2 py-2 px-0 border-bottom border-white border-2">
                        <input
                            class="fs-3 p-0 bg-transparent w-100 border-0 text-white"
                            type="text"
                            [placeholder]="inputTextArticle?.title"
                            #mobileInput
                            [autofocus]
                            (keyup)="updateDiseases($event.target.value)"
                            (keyup.enter)="redirectToSearchPage()"
                            autocomplete="off"
                        />
                        <i class="icon-gard-search fs-4" (click)="redirectToSearchPage()" role="button"></i>
                    </div>
                    <!-- Mobile Search Results -->
                    <div class="search-results py-4 mt-4" [hidden]="areResultsHidden || !isSearchOpen" *ngIf="diseases.length">
                        <h4 class="fw-bold px-4 mb-4 fs-18">Suggestions</h4>
                        <ul class="flex-column w-100 m-0 p-0 list-unstyled">
                            <li *ngFor="let disease of diseases" role="button" class="px-4 py-3 pe-1 w-100">
                                <app-mobile-search-item [disease]="disease" (clickEvent)="redirectToDisease($event, disease)"></app-mobile-search-item>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="background-gradient">
                    <!-- Gradient Circles -->
                    <div class="circle-mobile-top"></div>
                    <div class="circle-mobile-bottom"></div>
                </div>
            </div>
        </div>
        <div *ngIf="shouldShowHeader">
            <!-- Breadcrumb and Content -->
            <div class="breadcrumb mb-0 fs-7 text-white w-100">
                <div class="container-xxxl align-items-center d-flex justify-content-between">
                    <div>
                        <ul class="list-inline d-none d-lg-block">
                            <li class="list-inline-item" *ngFor="let breadcrumb of breadcrumbs; let index = index">
                                <a [routerLink]="breadcrumb.link" *ngIf="breadcrumb.link">{{ breadcrumb.label }}</a>
                                <span *ngIf="!breadcrumb.link">{{ breadcrumb.label }}</span>
                            </li>
                        </ul>
                        <ul class="list-inline d-lg-none">
                            <li class="list-inline-item d-flex align-items-center">
                                <a [routerLink]="backUrl"><i class="icon-gard-arrow-left text-white"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <span>{{ lastBreadcrumbLabel }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="lang-btn-container pt-1">
                        <a *ngIf="!!spanishUrlResult" class="btn btn-outline-white spanish-button me-4 flex-shrink-1 fw-400" [routerLink]="spanishUrl"> Español </a>
                        <a *ngIf="englishURL" class="btn btn-outline-white spanish-button me-4 flex-shrink-1 fw-400" [routerLink]="englishURL"> English </a>
                        <a
                            *ngIf="showPageTranslateBtn"
                            class="btn btn-outline-white spanish-button me-4 flex-shrink-1 fw-400"
                            [routerLink]="englishToSpanishUrl || spanishToEnglishUrl"
                            >{{ translateButtonTitle }}</a
                        >
                    </div>
                </div>
            </div>
            <div class="container-xxxl banner-content align-items-center pt-lg-5 mt-lg-4" [ngClass]="{ 'banner-50': halfHeight }">
                <div class="text-white">
                    <div class="row mt-4 mt-lg-0 pt-3 pt-lg-0">
                        <div class="col-xl-12 ps-4" *ngIf="!disease; else regular">
                            <!-- Main Content -->
                            <h1 class="title fw-800 fs-2 fs-md-0 mb-4" *ngIf="title">{{ title }}</h1>
                            <p class="fs-md-18 my-0" [innerHtml]="description"></p>
                        </div>
                        <div class="col-12" *ngIf="spanishName" class="spanish">
                            <p class="fs-18 text-center mb-0"><strong>¡Atención:</strong> Esta y otras páginas de enfermedades en español aún están en desarrollo!</p>
                        </div>
                        <ng-template #regular>
                            <div class="col-12 mb-4 mb-lg-5">
                                <!-- Disease Title and Synonyms -->
                                <h1 class="title fw-800 fs-2 fs-md-0" [ngClass]="{ 'mb-5': !synonymList?.length }">{{ disease?.name }}</h1>
                                <dl *ngIf="synonymList?.length" class="synonyms pb-5">
                                    <dt class="fs-md-18">Other Names:&nbsp;</dt>
                                    <clamper [minimumHeight]="50" [text]="synonymList" maxLines="2"></clamper>
                                </dl>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="tabs-container position-absolute w-100">
                <disease-tabs *ngIf="shouldShowTabs"></disease-tabs>
            </div>
        </div>
    </div>
</div>
