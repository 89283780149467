import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { Article } from 'shared/types';

@Component({
    selector: 'app-research-participate-in-research-card',
    templateUrl: './research-participate-in-research-card.component.html',
    styleUrls: ['./research-participate-in-research-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ResearchParticipateInResearchCardComponent implements OnInit {
    @Input() cardTitle = '';
    @Input() cardBody = '';
    @Input() theme: string;
    @Input() iconClass: string;
    @Input() iconUrl: string;
    @Input() additionalArticles?: Article[];
    @Input() fixedHeight?: number;
    @Input() fullHeight?: boolean;

    resources: Article[] = [];

    ngOnInit(): void {
        this.resources = this.additionalArticles;
    }
}
