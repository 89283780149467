import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';
import { PATHS } from 'shared/paths';
import { randomId } from 'shared/utils/random-id';
import { TopTab } from './multitab.types';
import { PanelType } from './multitab.types';
import { ExternalLinkDirective } from '../../../directives/external-link/external-link.directive';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-multitab',
    templateUrl: './multitab.component.html',
    styleUrls: ['./multitab.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MultitabComponent {
    @Input() tabs: TopTab[];
    @Input() hideTabs = false;
    @Input() accountsTitle: string | undefined = undefined;

    public imgPath = PATHS.articleImages;
    public panelType = PanelType;
    public tabIndex = 0; // top tab index
    public stIndex = 0; // left tab current index (sub)
    public mstIndex = -1; // mobile sub tab index (sub)
    public sstIndex = -1; // right tab index (sub sub)
    public prefix = randomId();

    constructor(@Inject(DOCUMENT) private document: Document) {}

    onTabClick(i: number) {
        this.tabIndex = i;
        this.stIndex = 0;
        this.mstIndex = -1;
        this.sstIndex = -1;
    }

    onSubTabClick(i: number) {
        this.stIndex = i;
        this.sstIndex = -1;
    }

    onMobileSubTabClick(i: number) {
        this.mstIndex = this.mstIndex > -1 ? -1 : i;
        this.sstIndex = -1;
    }

    toggleSubSub(i: number) {
        this.sstIndex = this.sstIndex > -1 ? -1 : i;
    }

    isLinkInternal(link: string) {
        return ExternalLinkDirective.isLinkInternal(link, this.document.location.host);
    }

    onOutsideRedirect(ev: MouseEvent, link: string) {
        if (link && !ExternalLinkDirective.isLinkInternal(link, this.document.location.host)) {
            ev.preventDefault();
            ev.stopPropagation();
            if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
                const tab = window.open(link, '_blank');
                tab.opener = null;
            }
        }
    }
}
