/* eslint-disable  @typescript-eslint/no-explicit-any */
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { Component, Input, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '../../../services/modal.service';

@Component({
    templateUrl: './modal-base.component.html',
    styleUrls: ['./modal-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalBaseComponent implements AfterViewInit, OnDestroy, AfterViewChecked, OnInit {
    @Input() component: (new (...args: any[]) => any) | TemplateRef<any>;

    @Input() options: ModalOptions;

    footerShow: boolean = false;
    footerFunctionsClear: () => void;
    footerFunctionsApply: () => void;

    @ViewChild('viewContainerRef', { read: ViewContainerRef })
    viewContainerRef: ViewContainerRef;

    componentRef: ComponentRef<any>;

    constructor(private changeDetectorRef: ChangeDetectorRef, private activeModal: NgbActiveModal) {}

    ngOnInit() {
        if (this.options.templateContext) {
            Object.assign(this.options?.templateContext, { modal: this.activeModal });
        } else {
            this.options.templateContext = { modal: this.activeModal };
        }

        // Setup footer & buttons with function calls
        this.footerShow = this.options.footerShow;

        if (this.footerShow) {
            this.footerFunctionsClear = this.options.footerFunctionsClear;
            this.footerFunctionsApply = this.options.footerFunctionsApply;
        }
    }

    ngAfterViewInit(): void {
        if (!(this.component instanceof TemplateRef)) {
            this.viewContainerRef.clear();

            this.componentRef = this.viewContainerRef.createComponent(this.component);

            const instance = this.componentRef.instance;

            const data = this.options?.data || {};

            for (const key of Object.keys(data)) {
                instance[key] = data[key];
            }

            this.viewContainerRef.insert(this.componentRef.hostView);

            this.changeDetectorRef.detectChanges();
        }
    }

    /*
     * fix: default modal animation when customRightModal enabled.
     */
    ngAfterViewChecked() {
        if (!this.options.customRightModal) {
            return;
        }

        const element = document.querySelector(`.modal`);

        element?.classList.remove('fade');
    }

    ngOnDestroy() {
        this.componentRef?.destroy();
    }

    dismiss() {
        const backHandler = this.options.backHandler;

        if (backHandler) {
            backHandler();
        } else {
            this.activeModal.dismiss();
        }
    }

    isTemplate() {
        return this.component instanceof TemplateRef;
    }
}
