<div class="symptoms overflow-hidden position-relative pt-5" id="symptoms" [formGroup]="searchForm" (window:resize)="onWindowResize()">
    <div class="container-fluid">
        <!--NULL VIEW -->
        <div class="container-xxxl desktop pb-5 mb-2 pt-3" *ngIf="isNull">
            <app-bar-header textHeader="Symptoms" theme="white"></app-bar-header>
            <div class="header-sub text-white fs-18" [innerHTML]="nullMessage"></div>
        </div>

        <!-- DESKTOP VIEW -->
        <div class="container-xxxl desktop pt-4 mt-2" *ngIf="!isNull">
            <div class="row pb-4">
                <!-- title row -->
                <div class="col col-lg-6">
                    <app-bar-header textHeader="Symptoms" theme="white"></app-bar-header>
                    <div class="header-sub text-white fs-18" [innerHtml]="intro.bodyText"></div>
                </div>
            </div>

            <!-- Feature System -->
            <div class="row pt-0 pb-2 text-white d-lg-none">
                <div class="col d-flex justify-content-between">
                    <span *ngIf="!!currentSlide.systemsText" class="d-md-none cursor-pointer text-capitalize fw-bold" (click)="openSymptomSystemModal(currentSlide.system)"
                        >{{ currentSlide.systemsText }} <i class="icon icon-gard-info ms-1" aria-label="info"></i
                    ></span>

                    <span *ngIf="!!currentSlide.systemsText" class="d-none d-md-block cursor-pointer text-capitalize fw-bold" (click)="openSymptomSystemModal(currentSlide.system)"
                        >{{ sortSlides(currentSlide.system) }}
                        <i class="icon icon-gard-info ms-1" aria-label="info"></i>
                    </span>
                </div>
                <div class="col d-flex justify-content-between">
                    <p class="mb-0 ms-auto text-nowrap text-white">{{ slides.length }} Symptom{{ slides.length === 0 || slides.length > 1 ? 's' : '' }}</p>
                </div>
            </div>

            <div class="row pt-3">
                <!-- filter and search row -->
                <div class="d-none d-xl-block col-3">
                    <!-- blank column for xl screens -->
                </div>
                <div class="col col-xl-9">
                    <!-- Search | Filter | Tile/List row for xxl screens-->
                    <div class="d-none d-xxxl-flex flex-row align-items-center justify-content-end pb-5">
                        <!-- Search -->
                        <div class="search-wrapper pe-4 w-100" (outsideClick)="onOutsideSearchClick()">
                            <div class="input-border search position-relative">
                                <i class="icon-gard-search"></i>
                                <input
                                    formControlName="term"
                                    (focus)="onSearchFocus()"
                                    autocomplete="off"
                                    placeholder="Search for symptoms for this disease"
                                    class="search-input"
                                />

                                <div class="dropdown p-3 sym-bg-bluish" [hidden]="!showSymptomsSearch">
                                    <div class="dropdown-list">
                                        <button class="border-0 bg-transparent w-100 text-start mb-2" *ngFor="let result of searchResults" (click)="onClickSearchResult(result)">
                                            <span class="main-term">{{ result.title | titleCaseWord }}</span>
                                            <i>{{ result.current ? '(Viewing)' : '' }}</i>
                                            <div class="ms-4" *ngFor="let synonym of result.synonyms"><i>Synonym:</i> {{ synonym | titleCaseWord }}</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Filter -->
                        <div class="filter-wrapper ps-4 position-relative h-100 w-100" (outsideClick)="onOutsideFiltersClick()">
                            <button class="w-100 search-wrapper btn btn-outline-white system-button text-start d-flex align-items-center" (click)="onFilterClick()">
                                <i class="icon-gard-filter me-3"></i>
                                <span class="filter-text me-auto">{{ filter === 'All Systems' ? 'Filter: ' : '' }}{{ filter | clipText : 18 }}</span>
                                <i class="icon-gard-drop-arrow-down drop-down-icon fs-8" [ngClass]="{ open: showFilters }"></i>
                            </button>

                            <div [hidden]="!showFilters" class="mobile-filter-sort-search desktop">
                                <div class="top-buttons text-start">
                                    <div class="position-relative">
                                        <ul class="filters s-18">
                                            <button
                                                class="bg-transparent border-0 text-green text-start px-4 py-4 pb-2"
                                                (click)="filterBySystem('All Systems')"
                                                [ngClass]="{ current: filter === 'All Systems' }"
                                            >
                                                <i class="icon-gard-check me-2" [hidden]="filter !== 'All Systems'"></i>
                                                All Systems
                                            </button>
                                            <li *ngFor="let f of filters; let last = last" (click)="filterBySystem(f)">
                                                <button class="bg-transparent border-0 text-green px-4 py-4" [ngClass]="{ current: f === filter, 'pb-2': !last }">
                                                    <i class="icon-gard-check me-2" [hidden]="filter !== f"></i>
                                                    {{ f }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- View Toggler -->
                        <div class="ps-4">
                            <div class="ps-4">
                                <p class="mb-0 ms-auto text-nowrap text-white">{{ slides.length }} Symptom{{ slides.length === 0 || slides.length > 1 ? 's' : '' }}</p>
                                <!-- <toggle-controller (click)="toggleView()" [(viewMode)]="viewMode"></toggle-controller> -->
                            </div>
                        </div>
                    </div>

                    <!-- Search | Filter and Sort | View  for regular screens -->
                    <div class="d-flex d-xxxl-none options flex-row align-items-center justify-content-between justify-content-md-center pb-4">
                        <!-- Search -->
                        <button class="search btn rounded-circle text-white me-3 d-flex align-items-center justify-content-center" (click)="openSearch()">
                            <i class="icon-gard-search icon fs-18 d-flex align-items-center justify-content-center"></i>
                        </button>

                        <!-- Filter and Sort -->
                        <button class="btn text-white w-75 w-md-100" (click)="openFilterSort()">Filter and Sort</button>

                        <!-- View Picker-->
                        <div class="ps-4 h-100 d-none d-lg-block">
                            <p class="mb-0 ms-auto text-nowrap text-white">{{ slides.length }} Symptom{{ slides.length === 0 || slides.length > 1 ? 's' : '' }}</p>
                            <!-- <toggle-controller (click)="toggleView()" [(viewMode)]="viewMode"></toggle-controller> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pb-4 pb-xl-5">
                <!-- Visuals row -->
                <div class="col-3 d-none d-xl-block">
                    <!-- Person Diagram -->
                    <div class="text-white px-0 my-0">
                        <ng-template *ngTemplateOutlet="tplSystemInfo"></ng-template>
                    </div>
                </div>
                <div class="col col-xl-9">
                    <!-- TILE VIEW -->
                    <div class="carousel-main-wrapper d-flex" *ngIf="viewMode === 'tile' || hideList">
                        <div class="carousel-main">
                            <div class="carousel-container pt-3">
                                <button aria-label="Previous Symptom" *ngIf="slides?.length > 1" (click)="move(-1)" class="mx-3 btn btn-outline-white nav-button d-none d-xl-block">
                                    <i class="icon-gard-arrow-left"></i>
                                </button>

                                <button
                                    [attr.aria-label]="prev3"
                                    *ngIf="slides?.length >= 7"
                                    class="slider-left-three-out d-none d-md-block cursor-pointer border-0"
                                    (click)="move(-3)"
                                ></button>

                                <div *ngIf="slides?.length >= 5" class="slider-left-two-out d-none d-md-block cursor-pointer" (click)="move(-2)">
                                    <button *ngIf="prev2" class="rotated-text-left-two-out fw-600 text-maroon border-0 bg-transparent">
                                        {{ prev2 | clipText : 40 }}
                                    </button>
                                </div>

                                <div *ngIf="slides?.length >= 3" class="slider-left-one-out cursor-pointer" (click)="move(-1)">
                                    <button *ngIf="prev1" class="rotated-text-left-one-out fw-600 text-maroon border-0 bg-transparent">
                                        {{ prev1 | clipText : 40 }}
                                    </button>
                                </div>

                                <div class="carousel-slide" #carouselSlide>
                                    <div *ngIf="currentSlide" class="d-flex flex-column">
                                        <div class="symptom-list-header text-blue" #medicalTerm>
                                            <h5 class="medical-term text-blue fs-6 fs-md-18">Medical Term</h5>
                                            <h3 class="medical-term-header text-maroon fs-18 fs-md-3" #title>{{ currentSlide.title }}</h3>
                                        </div>
                                        <div class="symptom-list-frequency fs-7 fs-md-6" #symptomListFrequency>
                                            <symptoms-section-frequency-slider
                                                [tooltipText]="tooltipText"
                                                [frequencyValue]="currentSlide.frequencyValue"
                                                [frequencyText]="currentSlide.frequencyText"
                                                [showInfo]="true"
                                            ></symptoms-section-frequency-slider>
                                        </div>
                                        <div>
                                            <div class="symptom-list-synonym-or-description fs-md-18">
                                                <div id="description" class="mb-2 mb-md-3 position-relative" *ngIf="currentSlide.textFull">
                                                    <div class="symptom-list-synonym-or-description-header">Description</div>
                                                    <div [class]="currentSlide.clampType">{{ currentSlide.textFull }}</div>
                                                </div>

                                                <div id="synonym" *ngIf="currentSlide.synonym">
                                                    <div class="symptom-list-synonym-or-description-header">Synonym</div>
                                                    <div [class]="currentSlide.clampType">{{ currentSlide.synonym }}</div>
                                                </div>
                                                <div *ngIf="!currentSlide.textFull && !currentSlide.synonym" [innerHTML]="noSymptomInfoMessage"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fixed-bottom-link">
                                        <a href="#" class="cursor-pointer mt-lg-4 text-green" (click)="openReadMoreModal($event, currentSlide)">
                                            Open detail view
                                            <i class="icon-gard-arrow-right ms-1"></i>
                                        </a>
                                    </div>
                                </div>

                                <div *ngIf="slides?.length >= 3" class="slider-right-one-out cursor-pointer" (click)="move(1)">
                                    <button *ngIf="next1" class="rotated-text-right-one-out fw-600 text-maroon border-0 bg-transparent">
                                        {{ next1 | clipText : 40 }}
                                    </button>
                                </div>

                                <div *ngIf="slides?.length >= 5" class="slider-right-two-out d-none d-md-block cursor-pointer" (click)="move(2)">
                                    <button *ngIf="next2" class="rotated-text-right-two-out fw-600 text-maroon border-0 bg-transparent">
                                        {{ next2 | clipText : 40 }}
                                    </button>
                                </div>

                                <button
                                    *ngIf="slides?.length >= 7"
                                    [attr.aria-label]="next3"
                                    class="slider-right-three-out d-none d-md-block cursor-pointer border-0"
                                    (click)="move(3)"
                                ></button>

                                <button aria-label="Next Symptom" *ngIf="slides?.length > 1" (click)="move(1)" class="mx-3 btn btn-outline-white nav-button d-none d-xl-block">
                                    <i class="icon-gard-arrow-right"></i>
                                </button>
                            </div>

                            <div class="d-xl-none mobile-prev-next-buttons" *ngIf="slides?.length > 1">
                                <button class="icon-gard-arrow-left btn btn-outline-white nav-button" (click)="move(-1)"></button>
                                <button class="icon-gard-arrow-right btn btn-outline-white nav-button" (click)="move(1)"></button>
                            </div>

                            <div class="text-center text-white mt-3 d-none d-xl-block d-xxxl-none">{{ slides.length }} Symptom{{ slides.length != 1 ? 's' : '' }}</div>
                        </div>

                        <div class="symptom-slide-nav-wrap system-desktop d-none d-xxxl-block">
                            <div *ngIf="slides">
                                <div class="symptom-list-box pt-3">
                                    <div class="symptom-list-menu-header text-green" (click)="toggleSort()">
                                        <button class="border-0 bg-transparent text-green fw-bold">
                                            <i class="bi bi-filter-left" [ngClass]="[arrowRotateHaveWe ? 'icon-gard-sort-up' : 'icon-gard-sort-down']"></i>
                                            Sort by: Medical Term
                                        </button>
                                    </div>
                                    <div class="symptom-list-container" #symptomListParent>
                                        <button
                                            class="list-item border-0 text-bluegray text-start"
                                            *ngFor="let slide of slides"
                                            (click)="moveToId(slide.id)"
                                            [ngClass]="{ 'list-item-selected': slide === currentSlide }"
                                        >
                                            {{ slide.title }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- LIST VIEW -->
                    <div class="list-wrapper w-100" *ngIf="viewMode === 'list' && !hideList">
                        <div class="list-header bg-white text-green row d-flex align-items-center px-4 mx-0 fs-6 fw-bold">
                            <div class="col-3 px-0" (click)="toggleSort()" role="button">
                                Medical Term
                                <i
                                    class="ms-1 fs-6"
                                    [ngClass]="{
                                        'icon-gard-sort': !medicalSort,
                                        'icon-gard-sort-down': medicalSort === 'dsc',
                                        'icon-gard-sort-up': medicalSort === 'asc'
                                    }"
                                ></i>
                            </div>
                            <div class="col-5 px-0">Description and Synonyms</div>
                            <div class="col-4 px-0 d-flex" (click)="sortByFreq()" role="button">
                                <div class="fw-bold me-2">
                                    <app-tooltip class="mx-0" [text]="tooltipText" mobileIconClass="icon-small"></app-tooltip>
                                </div>
                                Frequency<i
                                    class="ms-1 fs-6"
                                    [ngClass]="{
                                        'icon-gard-sort': !freqSort,
                                        'icon-gard-sort-down': freqSort === 'dsc',
                                        'icon-gard-sort-up': freqSort === 'asc'
                                    }"
                                ></i>
                            </div>
                        </div>

                        <div class="list-item-wrapper row mt-2 w-100 mx-0 px-0" #listView>
                            <div
                                class="list-item bg-white row mb-2 w-100 mx-0 px-0"
                                *ngFor="let slide of slides"
                                [ngClass]="{ highlighted: searchForm.value.term === slide.title }"
                            >
                                <div class="col-3 fw-bold fs-22 text-maroon py-4 ps-4">
                                    {{ slide.title }}
                                </div>

                                <div *ngIf="slide.textFull || slide.synonym" class="col-5 py-4 pe-4">
                                    <p>
                                        {{ slide.textFull }}
                                    </p>
                                    <p *ngIf="slide.synonym"><span class="text-blue fw-bold me-2">Synonyms:</span>{{ slide.synonym }}</p>
                                </div>
                                <div *ngIf="!slide.textFull && !slide.synonym" class="col-5 py-4 pe-4" [innerHTML]="noSymptomInfoMessage"></div>

                                <div class="col-4 frequency-container pl-2 pt-3">
                                    <div class="my-auto">
                                        <symptoms-section-frequency-slider
                                            [tooltipText]="tooltipText"
                                            [frequencyValue]="slide.frequencyValue"
                                            [frequencyText]="slide.frequencyText"
                                            [showInfo]="false"
                                        ></symptoms-section-frequency-slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- FOOTER -->
    <div class="container-fluid symptom-footer" *ngIf="!isNull">
        <div class="text-white fs-8 fs-md-6 text-center py-4 mt-4 mt-xl-0">
            This information comes from the
            <a class="text-white" [href]="jaxorg" (click)="redirectToHPO($event)" rel="noopener noreferrer" target="_blank"
                >Human Phenotype Ontology (HPO)<i class="icon-gard-open-link ms-2"></i>
            </a>
        </div>
    </div>

    <!-- BACKGROUND -->
    <div class="background-gradient">
        <div class="circle-top-left"></div>
        <div class="circle-lower-right"></div>
    </div>
</div>

<!-- PERSON TEMPLATE -->
<ng-template #systemPopup>
    <div class="system-popup-main">
        <ng-template *ngTemplateOutlet="tplSystemInfo; context: { $implicit: 'popup' }"></ng-template>
    </div>
</ng-template>

<ng-template #tplSystemInfo let-theme>
    <!-- Filter System -->
    <div class="system-wrapper" [ngClass]="theme" *ngIf="currentSystem">
        <!-- Person -->
        <div class="person d-flex justify-content-center">
            <img [src]="currentSystemImage" [alt]="currentSystemImageAltText" />
        </div>
        <!-- Text  -->
        <div class="person-text">
            <h3 class="header">{{ currentSystem?.title }}</h3>
            <div class="text" [innerHtml]="currentSystem?.bodyText"></div>
        </div>
    </div>
    <div class="system-wrapper" [ngClass]="theme" *ngIf="currentSystem2">
        <!-- Person -->
        <div class="person d-flex justify-content-center">
            <img [src]="currentSystem2Image" [alt]="currentSystem2ImageAltText" />
        </div>
        <!-- Text  -->
        <div class="person-text">
            <h3 class="header">{{ currentSystem2?.title }}</h3>
            <div class="text" [innerHtml]="currentSystem2?.bodyText"></div>
        </div>
    </div>

    <div class="system-wrapper" [ngClass]="theme" *ngIf="currentSystem3">
        <!-- Person -->
        <div class="person d-flex justify-content-center">
            <img [src]="currentSystem3Image" [alt]="currentSystem3ImageAltText" />
        </div>
        <!-- Text  -->
        <div class="person-text">
            <h3 class="header">{{ currentSystem3?.title }}</h3>
            <div class="text" [innerHtml]="currentSystem3?.bodyText"></div>
        </div>
    </div>
</ng-template>

<!-- FILTER AND SORT MODAL -->
<ng-template #templateFilterSort let-modal="modal">
    <div class="mobile-filter-sort-search d-flex flex-column">
        <div class="px-4 mt-lg-5 mb-4 d-flex justify-content-between align-items-center">
            <h3 class="text-blue p-0 ps-3 m-0">Symptoms</h3>
            <p class="m-0">{{ slides.length }} Symptom{{ slides.length === 0 || slides.length > 1 ? 's' : '' }}</p>
        </div>
        <div class="filter-sort d-flex flex-column flex-grow">
            <div class="flex-grow px-4 position-relative">
                <div class="position-relative flex-grow h-100">
                    <div class="top-buttons d-flex flex-column justify-content-between">
                        <div class="position-relative z-4" (outsideClick)="onOutsideMobileFiltersClick()">
                            <button class="btn w-100 text-start px-4 py-2 d-flex align-items-center justify-content-between" (click)="onMobileFilterClick()">
                                <span><i class="icon-gard-filter me-2"></i> Filter by: {{ filter | clipText : 18 }}</span>
                                <i class="small icon-gard-drop-arrow-down drop-down-icon" [ngClass]="{ open: showMobileFilters }"></i>
                            </button>

                            <ul class="filters z-4" *ngIf="showMobileFilters">
                                <li class="px-5 py-4 pb-2" role="button" (click)="filterBySystem('All Systems')" [ngClass]="{ current: filter === 'All Systems' }">
                                    <i class="icon-gard-check me-2" [hidden]="filter !== 'All Systems'"></i>
                                    All Systems
                                </li>
                                <li
                                    class="px-5 py-4"
                                    role="button"
                                    *ngFor="let f of filters"
                                    (click)="filterBySystem(f)"
                                    [ngClass]="{ current: f === filter, 'pb-2': f !== filters[filters.length - 1] }"
                                >
                                    <i class="icon-gard-check me-2" [hidden]="filter !== f"></i>
                                    {{ f }}
                                </li>
                            </ul>
                        </div>

                        <button class="btn w-100 text-start px-4 py-2" (click)="toggleSort()">
                            <i class="icon-gard-list me-2"></i> Sort by: Medical Terms |
                            {{ !arrowRotateHaveWe ? 'A to Z' : 'Z to A' }}
                        </button>

                        <div class="symptoms-container">
                            <ul class="symptoms-ul">
                                <li role="button" *ngFor="let slide of slides" (click)="moveToId(slide.id)" [ngClass]="{ current: slide === currentSlide }">
                                    {{ slide.title }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="buttons d-flex justify-content-between p-4">
                <button class="btn btn-white" (click)="onClearFilterAndSort()">Clear</button>
                <button class="btn btn-green" (click)="modal.close()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- SEARCH MODAL -->
<ng-template #templateSearch let-modal="modal">
    <div class="symptoms-text">{{ slides.length }} Symptom{{ slides.length === 0 || slides.length > 1 ? 's' : '' }}</div>

    <div class="mobile-filter-sort-search d-flex flex-column" [formGroup]="searchFormMobile">
        <div class="search-type d-flex flex-column flex-grow mt-lg-4">
            <div class="flex-grow px-4 position-relative">
                <div class="position-relative flex-grow h-100">
                    <div class="top-buttons d-flex flex-column justify-content-between">
                        <div class="input-border search w-100">
                            <i class="icon-gard-search text-blue"></i>
                            <input #searchInputMobile formControlName="termMobile" (focus)="onSearchFocus()" autocomplete="off" placeholder="Search for symptoms" />
                        </div>
                        <div class="symptoms-container">
                            <ul class="symptoms-ul">
                                <li *ngFor="let result of searchResults" (click)="onClickSearchResult(result)" [ngClass]="{ current: result.current }">
                                    {{ result.title }} <i>{{ result.current ? ' (Viewing)' : '' }}</i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
