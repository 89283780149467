<div class="container-fluid">
    <div class="container-xxxl position-relative px-0">
        <div class="position-absolute upper-tabs w-100">
            <ul class="nav nav-tabs border-0" role="tablist" #nav>
                <li class="nav-item col-lg-3" *ngFor="let link of upperLinks; let i = index; let f = first; let l = last" [ngClass]="{ 'ms-lg-2': f, 'me-4 me-lg-0': l }">
                    <a
                        class="nav-link text-center fs-18 fs-md-4 text-white cursor-pointer lh-1"
                        [ngClass]="{ active: i === activeIndex }"
                        [routerLink]="i !== activeIndex ? link.link : null"
                        #navLink
                    >
                        {{ link.description }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="container-fluid white-container d-flex align-items-center position-relative">
    <div class="container-xxxl normal">
        <div class="row sub-tabs" *ngIf="links.length > 0">
            <ul class="nav" class="d-none d-xl-flex">
                <div *ngFor="let section of links">
                    <li class="nav-item py-0" *ngIf="!section.hidden">
                        <a href="#{{ section.id }}" class="nav-link" (click)="jumpToSection($event, section.id)">
                            <span class="link-text fs-18">{{ section.description }}</span>
                        </a>
                    </li>
                </div>
            </ul>
            <div class="position-relative d-xl-none">
                <button class="bg-transparent border-0 w-100 text-start d-flex align-items-center justify-content-between p-0" (click)="toggleTabs()">
                    <span>Navigate to sub-section</span>
                    <i [ngClass]="[isTabsOpen ? 'icon-gard-drop-arrow-up' : 'icon-gard-drop-arrow-down']"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="mobile d-xl-none" *ngIf="isTabsOpen">
        <div class="container-xxxl">
            <ul class="d-flex flex-column">
                <div *ngFor="let section of links">
                    <li class="py-3" *ngIf="!section.hidden">
                        <a href="#{{ section.id }}" class="jump-link" (click)="jumpToSection($event, section.id)">
                            <span class="link-text">{{ section.description }}</span>
                        </a>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</div>
