import { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';
import { Article } from 'shared/types';
import { Page, Section } from 'shared/location-enums';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements AfterViewInit {
    headerArticle: Article;

    constructor(articleService: ArticleService) {
        this.headerArticle = articleService.getOne(Page.about, Section.header, undefined, 1);
    }

    ngAfterViewInit() {
        const links = Object.values(document.getElementsByTagName('a')).filter(link => !link.href.includes('.gov') && !link.baseURI.includes(link.hostname));

        links.forEach(linkEl => {
            const href = linkEl.href;
            linkEl.addEventListener('click', (ev: MouseEvent) => {
                ev.preventDefault();
                ev.stopPropagation();
                if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
                    const tab = window.open(href, '_blank');
                    tab.opener = null;
                }
            });
        });
    }
}
