import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

const delimiters = [' ', '/', '\\', ':', '-', '('];
const conjunctions = ['for', 'and', 'nor', 'but', 'or', 'yet', 'so'];
const prepositions = [
    'about',
    'above',
    'across',
    'after',
    'against',
    'among',
    'around',
    'at',
    'before',
    'behind',
    'below',
    'beside',
    'between',
    'by',
    'down',
    'during',
    'for',
    'from',
    'in',
    'inside',
    'into',
    'near',
    'of',
    'off',
    'on',
    'out',
    'over',
    'through',
    'to',
    'toward',
    'under',
    'up',
    'with',
];
const wordsExcludedFromCapitalization: Set<string> = new Set([...conjunctions, ...prepositions]);

@Pipe({
    name: 'titleCaseWord',
})
export class TitleCaseWordPipe implements PipeTransform {
    private static perWord(word = ''): string {
        if (!word || wordsExcludedFromCapitalization.has(word)) {
            return word;
        }
        return word[0]?.toUpperCase() + word.substr(1);
    }

    private static perDelimiter(delimiter = delimiters[0], wordString = ''): string {
        const arr = wordString.split(delimiter).map(word => TitleCaseWordPipe.perWord(word));
        return arr.join(delimiter);
    }

    transform(wordString = ''): string {
        if (!wordString) {
            return '';
        }
        let returnWordString = wordString.toLowerCase();
        delimiters.forEach(delimiter => {
            returnWordString = TitleCaseWordPipe.perDelimiter(delimiter, returnWordString);
        });
        return returnWordString;
    }
}
