<ng-template #modal>
    <div class="container-fluid">
        <div class="row d-none d-md-block">
            <div class="col-12">
                <h5 class="text-maroon fw-bold fs-3">Browse By Disease Category</h5>
            </div>
        </div>

        <div class="row" *ngFor="let category of categoriesWithDescriptions">
            <div class="col-12">
                <h5 class="category-name text-blue fs-4">{{ category.nameCurated }}</h5>
                <div class="category-textSnippet text-bluegray fs-6">{{ category.textSnippet }}</div>
            </div>
        </div>
    </div>
</ng-template>

<ngb-accordion class="accordion-filter-by-category" [animation]="true" [closeOthers]="true" activeIds="disease-category">
    <ngb-panel id="disease-category">
        <ng-template ngbPanelTitle>
            Browse by Disease Category <i class="info-icon icon icon-gard-info text-green" tabindex="0" (click)="openModal($event, modal)"></i>
        </ng-template>

        <ng-template ngbPanelContent>
            <div *ngFor="let diseaseCategoryName of allDiseaseCategories; let last = last" class="row">
                <div class="col-12">
                    <div class="check-entry" [ngClass]="{ 'last-of-type': last }">
                        <input
                            type="checkbox"
                            [id]="'check-' + diseaseCategoryName"
                            [checked]="activeDiseaseCategories.includes(diseaseCategoryName)"
                            (change)="clickFilter(diseaseCategoryName)"
                            (keyup.enter)="clickFilter(diseaseCategoryName)"
                            tabindex="0"
                        />
                        <label class="name" [for]="'check-' + diseaseCategoryName">{{ diseaseCategoryName }}</label>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
