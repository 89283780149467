import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { ACCOUNT_RECORD_TYPES } from 'shared/constants';
import { PATHS } from 'shared/paths';
import { Account, Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { DiseaseService } from 'src/app/services/disease.service';
import { AccountService } from 'src/app/services/account.service';
import { LocalOrganization } from 'src/app/components/common/organizations/organizations-interfaces';
import { availablePatientAdvocacyGroups } from 'src/app/components/common/organizations/patient-advocacy-groups';

@Component({
    selector: 'rare-disease-expert',
    templateUrl: './rare-disease-expert.component.html',
    styleUrls: ['./rare-disease-expert.component.scss'],
})
export class RareDiseaseExpertComponent {
    public imgPath = PATHS.articleImages;

    public mainKA: Article;

    public linkKA: Article;

    public nullKA: Article;

    public orgsWithExpertDirectory: LocalOrganization[] = [];

    constructor(private articleService: ArticleService, private diseaseService: DiseaseService, private accountService: AccountService) {
        this.mainKA = this.articleService.getOne(Page.diagnosis, Section.findingExpert, SubSection.parent, undefined, undefined, false);

        this.linkKA = this.articleService.getOne(Page.diagnosis, Section.findingExpert, SubSection.moreInfo, undefined, undefined, false);

        this.nullKA = this.articleService.getOne(Page.diagnosis, Section.findingExpert, SubSection.moreInfo, undefined, undefined, true);

        // add replacement for disease name
        this.linkKA.bodyText = this.linkKA.bodyText.replace('${DiseaseName}', this.diseaseService.disease.name);

        // Accounts logic
        const patientAdvocacyGroups: Account[] = this.accountService.getAccounts(); //.filter((acct: Account) => acct.recordType === ACCOUNT_RECORD_TYPES.patientAdvocacyGroup);
        const patientAdvocacyGroupsFiltered: Account[] = patientAdvocacyGroups.filter((acct: Account) => acct.recordType === ACCOUNT_RECORD_TYPES.patientAdvocacyGroup);

        // PAGs logic
        const pagsForDiseaseAndTag = availablePatientAdvocacyGroups(patientAdvocacyGroups, this.diseaseService.disease, true, true, false);
        const orgsWithExpertDirectoryAll = pagsForDiseaseAndTag.filter((org: LocalOrganization) => org.expertDirectoryUrl);

        // USA only
        this.orgsWithExpertDirectory = orgsWithExpertDirectoryAll.filter((org: LocalOrganization) => org.country === 'United States');
    }
}
