<div class="card participate-in-research-card overflow-hidden" [ngClass]="{ 'h-100': fullHeight }" [ngStyle]="{ 'height.px': fixedHeight ? fixedHeight : null }" [class]="theme">
    <div class="card-header d-flex align-items-center">
        <i *ngIf="iconClass?.length" [ngClass]="iconClass" class="text-white rounded-circle p-0 fs-3 fs-md-1 d-flex align-items-center justify-content-center"></i>
        <div>
            <img *ngIf="iconUrl?.length" [src]="iconUrl" alt="" />
        </div>
        <div class="fs-18 fs-md-3 fw-bold d-inline-block ps-3 ps-md-4">{{ cardTitle }}</div>
    </div>

    <div class="card-body fs-6 fs-md-18" [ngStyle]="{ 'height.px': fixedHeight ? fixedHeight - 160 : null }">
        <p class="text-link" [innerHTML]="cardBody"></p>

        <div *ngIf="resources?.length">
            <div *ngFor="let article of resources" class="additional-article">
                <h1 *ngIf="article.displayTitle" class="fs-5 fs-md-4 fw-bold mt-4 mt-md-5 mb-3">
                    {{ article.title }}
                </h1>
                <div class="text-link fs-6 fs-md-18 mt-4" embeddedHTML [innerHTML]="article.bodyText"></div>
            </div>
        </div>
    </div>
</div>
