import { SYMPTOM_FREQUENCIES } from 'shared/constants';
import { Feature } from 'shared/salesforce-types';
import { TitleCaseWordPipe } from '../../../../pipes/title-case-word.pipe';

export class SymptomSlide {
    public frequencyValue = 0;
    public title: string;
    public textTrimmed: string;
    public textFull: string;
    public frequencyText: string;
    public system: string[];
    public synonym: string;
    public synonymTrimmed: string;
    public id: number;
    public clampType: 'clampBig' | 'clampSmall';
    public systemsText: string;
    public showTitle: boolean;

    constructor(symptom: Feature) {
        if (!symptom) {
            throw new Error('symptomData required for all Symptoms');
        }

        this.title = symptom.HPO_Name__c;
        this.textFull = symptom.HPO_Description__c || '';
        this.textTrimmed = this.textFull;
        this.synonym = symptom.HPO_Synonym__c;
        this.synonymTrimmed = this.synonym;
        this.id = SymptomSlide.getRandomID();
        this.title = symptom.HPO_Name__c;
        const titleCaseWordPipe = new TitleCaseWordPipe();
        this.system = symptom.Feature_System__c?.split('; ').map(val => titleCaseWordPipe.transform(val.toLowerCase())) || [];

        if (symptom.HPO_Frequency__c) {
            this.frequencyText = SymptomSlide.frequencyTextToDisplayText(symptom.HPO_Frequency__c);
            this.frequencyValue = SymptomSlide.frequencyTextToNumber(this.frequencyText);
        } else {
            this.frequencyText = 'Not Applicable or Unknown';
            this.frequencyValue = 0;
        }
    }

    static getRandomID(): number {
        return Math.floor(Math.random() * 30000);
    }

    static frequencyTextToNumber(frequencyText: string): number {
        switch (frequencyText.toLowerCase()) {
            case 'always':
                return SYMPTOM_FREQUENCIES.always;
            case 'very frequent':
                return SYMPTOM_FREQUENCIES.veryFrequent;
            case 'frequent':
                return SYMPTOM_FREQUENCIES.frequent;
            case 'occasional':
                return SYMPTOM_FREQUENCIES.occasional;
            case 'uncommon':
                return SYMPTOM_FREQUENCIES.uncommon;
            default:
                return SYMPTOM_FREQUENCIES.default;
        }
    }

    static frequencyTextToDisplayText(frequencyText: string): string {
        return frequencyText.substring(0, frequencyText.indexOf('(') - 1);
    }
}
