import { Component, ViewEncapsulation } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';

@Component({
    selector: 'clinical-studies',
    templateUrl: './clinical-studies.component.html',
    styleUrls: ['./clinical-studies.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClinicalStudiesComponent {
    mainKAs: Article[];
    infoKAs: Article[];

    constructor(articleService: ArticleService) {
        this.mainKAs = articleService.getArticles(Page.about, Section.research, SubSection.clinicalStudy, undefined, undefined, false);

        this.infoKAs = articleService.getArticles(Page.about, Section.research, SubSection.clinicalStudyReferences, undefined, undefined, false);
    }
}
