import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DiseaseService } from './disease.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DiseasesCategoriesResolver {
    constructor(private diseaseService: DiseaseService) {}

    resolve(): Observable<boolean> {
        return this.diseaseService.fetchDiseasesCategories().pipe(map(() => true));
    }
}
