<div class="container-fluid bg-lightblue px-0 mx-0">
    <div class="container-xxxl px-4 px-lg-5">
        <div class="py-5 px-lg-2">
            <div class="row">
                <div class="col-lg-6 d-flex justify-content-center d-lg-block mb-4 mb-lg-0">
                    <img [src]="imageUrl" class="img-fluid mb-2 rounded" [alt]="article.images[0]?.altText" />
                </div>
                <div class="col-lg-6">
                    <h3 class="text-blue mb-3">{{ article.title }}</h3>
                    <div class="fs-md-18 bodyText text-link" [innerHTML]="article.bodyText"></div>
                </div>
            </div>
        </div>
    </div>
</div>
