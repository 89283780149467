import { Component, Input } from '@angular/core';

@Component({
    selector: 'symptoms-section-frequency-slider',
    templateUrl: './symptoms-section-frequency-slider.component.html',
    styleUrls: ['./symptoms-section-frequency-slider.component.scss'],
})
export class SymptomsSectionFrequencySliderComponent {
    @Input() public tooltipText: string;
    @Input() set frequencyValue(frequencyValue: number) {
        if (frequencyValue === 0) {
            this.frequencyValueForMarker = frequencyValue;
            this.frequencyValueForText = frequencyValue;
            this.frequencyValueForTextNoMarker = frequencyValue;
        } else if (frequencyValue < 50) {
            this.frequencyValueForMarker = Math.max(this.minMarkerValue, frequencyValue);
            this.frequencyValueForText = Math.max(this.minValueWithLabels, frequencyValue);
            this.frequencyValueForTextNoMarker = Math.max(this.minValueNoLabels, frequencyValue);
        } else {
            this.frequencyValueForMarker = Math.min(this.maxMarkerValue, frequencyValue);
            this.frequencyValueForText = Math.min(this.maxValueWithLabels, frequencyValue);
            this.frequencyValueForTextNoMarker = Math.min(this.maxValueNoLabels, frequencyValue);
        }
    }

    @Input() public frequencyText: string;
    @Input() public showInfo: boolean;
    @Input() public showFrequencyText = true;
    @Input() public forceLabels = false;

    // minimums to allow for wireframe display
    minMarkerValue = 6;
    minValueNoLabels = 28;
    minValueWithLabels = -25;

    // Maximums to allow for wireframe display
    maxMarkerValue = 93;
    maxValueNoLabels = 71;
    maxValueWithLabels = 125;

    frequencyValueForMarker: number;
    frequencyValueForText: number;
    frequencyValueForTextNoMarker: number;
}
