import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PATHS } from '../../../shared/paths';
import { DiseaseService } from './disease.service';

@Injectable({
    providedIn: 'root',
})
export class DiseaseResolver {
    constructor(private diseaseService: DiseaseService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot) {
        const diseaseId = route.params.diseaseId as number;
        return this.diseaseService.fetch(diseaseId).pipe(
            map(() => true),
            catchError(() => {
                this.router.navigate([PATHS.notFound], { skipLocationChange: true });
                return of(false);
            }),
        );
    }
}
