<div class="container-fluid py-5 position-relative">
    <div class="container-xxxl py-0 py-lg-2 my-0 my-lg-4">
        <!-- Desktop view -->
        <div class="d-none d-lg-flex align-items-center justify-content-between fs-18">
            <div class="col left-part">
                <div class="row">
                    <app-bar-header [textHeader]="articleUnderstandIntro.title" theme="maroon"></app-bar-header>
                </div>
                <div class="row mb-4 ps-3" [innerHTML]="articleUnderstandIntro.bodyText"></div>
                <div>
                    <a class="btn btn-arrow text-white btn-green blue-effect mt-3" [routerLink]="diseasePagesUrl">
                        <span [innerHTML]="articleExploreDiseasePagesButtonText.title"></span>
                        <i class="icon-gard-arrow-right ms-2"></i>
                    </a>
                </div>
            </div>
            <div class="col right-part">
                <div class="row pb-5">
                    <div class="col pe-5">
                        <div class="d-flex flex-column mb-2">
                            <img class="h3-icon mb-3" [src]="imgPath + articleTabAAboutDisease.images[0].url + '-200.webp'" alt="" />
                            <div class="mb-2 mt-0 mx-0 fs-22 fw-bold text-maroon" [innerHTML]="articleTabAAboutDisease.title"></div>
                        </div>
                        <div [innerHTML]="articleTabAAboutDisease.bodyText"></div>
                    </div>
                    <div class="col ps-4">
                        <div class="d-flex flex-column mb-2">
                            <img class="h3-icon mb-3" [src]="imgPath + articleTabCLivingDisease.images[0].url + '-200.webp'" alt="" />
                            <div class="mb-2 mt-0 mx-0 fs-22 fw-bold text-maroon" [innerHTML]="articleTabCLivingDisease.title"></div>
                        </div>
                        <div [innerHTML]="articleTabCLivingDisease.bodyText"></div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col pe-5">
                        <div class="d-flex flex-column mb-2">
                            <img class="h3-icon mb-3" [src]="imgPath + articleTabBDiagnosisTreatment.images[0].url + '-200.webp'" alt="" />
                            <div class="mb-2 mt-0 mx-0 fs-22 fw-bold text-maroon" [innerHTML]="articleTabBDiagnosisTreatment.title"></div>
                        </div>
                        <div [innerHTML]="articleTabBDiagnosisTreatment.bodyText"></div>
                    </div>
                    <div class="col ps-4">
                        <div class="d-flex flex-column mb-2">
                            <img class="h3-icon mb-3" [src]="imgPath + articleTabDResearch.images[0].url + '-200.webp'" alt="" />
                            <div class="mb-2 mt-0 mx-0 fs-22 fw-bold text-maroon" [innerHTML]="articleTabDResearch.title"></div>
                        </div>
                        <div [innerHTML]="articleTabDResearch.bodyText"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex d-lg-none flex-column">
            <app-bar-header [textHeader]="articleUnderstandIntro.title" theme="maroon"></app-bar-header>
            <div class="mb-5" [innerHTML]="articleUnderstandIntro.bodyText"></div>

            <div class="text-center mb-5">
                <img class="h3-icon" [src]="imgPath + articleTabAAboutDisease.images[0].url + '-200.webp'" alt="" />
                <div class="mt-3 mb-2 mx-0 fs-5 fw-bold text-maroon" [innerHTML]="articleTabAAboutDisease.title"></div>
                <div [innerHTML]="articleTabAAboutDisease.bodyText"></div>
            </div>
            <div class="text-center mb-5">
                <img class="h3-icon" [src]="imgPath + articleTabBDiagnosisTreatment.images[0].url + '-200.webp'" alt="" />
                <div class="mt-3 mb-2 mx-0 fs-5 fw-bold text-maroon" [innerHTML]="articleTabBDiagnosisTreatment.title"></div>
                <div [innerHTML]="articleTabBDiagnosisTreatment.bodyText"></div>
            </div>
            <div class="text-center mb-5">
                <img class="h3-icon" [src]="imgPath + articleTabCLivingDisease.images[0].url + '-200.webp'" alt="" />
                <div class="mt-3 mb-2 mx-0 fs-5 fw-bold text-maroon" [innerHTML]="articleTabCLivingDisease.title"></div>
                <div [innerHTML]="articleTabCLivingDisease.bodyText"></div>
            </div>
            <div class="text-center mb-5">
                <img class="h3-icon" [src]="imgPath + articleTabDResearch.images[0].url + '-200.webp'" alt="" />
                <div class="mt-3 mb-2 mx-0 fs-5 fw-bold text-maroon" [innerHTML]="articleTabDResearch.title"></div>
                <div [innerHTML]="articleTabDResearch.bodyText"></div>
            </div>

            <div class="text-center">
                <a class="btn text-white btn-green btn-arrow blue-effect" [routerLink]="diseasePagesUrl">
                    <span [innerHTML]="articleExploreDiseasePagesButtonText.title"></span>
                    <i class="icon-gard-arrow-right ms-2"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="background">
        <div class="circle-top-left"></div>
        <div class="circle-outline-top-left"></div>
        <div class="circle-outline-huge-left"></div>
        <div class="circle-outline-small-center"></div>
        <div class="circle-bottom-right-center"></div>
        <div class="circle-big-right"></div>
        <div class="circle-outline-right"></div>
    </div>
</div>
