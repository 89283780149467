<app-top-navigation></app-top-navigation>
<main-banner [disease]="disease"></main-banner>

<!-- A1 -->
<disease-at-a-glance [id]="sections.disease.id"></disease-at-a-glance>

<!-- A2 -->
<age-at-onset [id]="sections.ageAtOnset.id"></age-at-onset>

<!-- A3 -->
<symptoms-section></symptoms-section>

<!-- A4 -->
<causes [id]="sections.causes.id"></causes>

<!-- inheritance -->
<inheritance></inheritance>

<!-- A5 Find Your Community -->
<advocacy-and-support-groups [id]="sections.supportGroups.id"></advocacy-and-support-groups>

<!-- A6 PAG Accounts -->
<patient-organizations-accounts></patient-organizations-accounts>

<!-- A7 -->
<participating-in-clinical-studies [id]="sections.clinicalStudies.id"></participating-in-clinical-studies>

<!-- A8 -->
<clinical-studies></clinical-studies>

<!-- A9 -->
<clinical-trials-contact-us></clinical-trials-contact-us>

<div class="mb-5"></div>

<!-- <app-prev-next-button forwardLink="diagnosis" forwardDescription="Getting a Diagnosis"></app-prev-next-button> -->
<disease-footer></disease-footer>
<app-bottom-sources-date-information></app-bottom-sources-date-information>
<app-footer></app-footer>
