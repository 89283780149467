import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root',
})
export class AccountsResolver {
    constructor(private accountService: AccountService) {}

    resolve() {
        const subAccount = this.accountService.fetch();

        return subAccount.pipe(map(() => true));
    }
}
