import { Component } from '@angular/core';
import { Article } from 'shared/types';
import { ArticleService } from 'src/app/services/article.service';
import { Section as SEC, SubSection as SS, Page as TAB } from 'shared/location-enums';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
    selector: 'inheritance',
    templateUrl: './inheritance.component.html',
    styleUrls: ['./inheritance.component.scss'],
})
export class InheritanceComponent {
    // inheritance
    articles: Article[] = [];
    mainArticle: Article;
    isNull = false;

    constructor(private diseaseService: DiseaseService, private articleService: ArticleService) {
        this.articles = this.getArticles();
        this.mainArticle = articleService.getOne(TAB.about, SEC.causes, SS.transmission, 1);

        this.isNull = this.articles.length === 0;
    }

    getArticles(): Article[] {
        const articles = this.articleService.getArticles(TAB.about, SEC.causes, SS.transmission);

        const diseaseInheritance = this.diseaseService.disease.inheritance.map((title: string) => title.toLowerCase());

        const matchingInheritanceArticles = articles.filter((article: Article) => {
            // change to two way match
            let included = false;

            article.tags.forEach((tag: string) => {
                if (diseaseInheritance.includes(tag.toLowerCase())) {
                    included = true;
                }
            });

            return included;
        });

        return matchingInheritanceArticles;
    }
}
