import { OnInit } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MultitabItem, TopTab } from 'src/app/components/common/multitab/multitab.types';
import { PanelType } from 'src/app/components/common/multitab/multitab.types';
import { ArticleService } from 'src/app/services/article.service';
import { Section as SECTIONS, Page as TABS, SubSection as SUBSECTIONS } from 'shared/location-enums';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';

@Component({
    selector: 'c4-suggested-resources',
    templateUrl: './suggested-resources.component.html',
    styleUrls: ['./suggested-resources.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuggestedResourcesComponent implements OnInit {
    mainKa: Article;
    textHeader: string;
    tabs: TopTab[] = [
        {
            title: 'Suggested Resources to Consider',
            sectionHeader: '',
            type: PanelType.normal,
            items: [] as MultitabItem[],
        },
    ] as TopTab[];

    patientResources: Article;
    constructor(private articleService: ArticleService) {
        this.mainKa = this.articleService.getOne(TABS.livingWith, SECTIONS.patientResources, SUBSECTIONS.parent);

        // const subsections = [SUBSECTIONS.caregiver, SUBSECTIONS.disability, SUBSECTIONS.financial, SUBSECTIONS.international, SUBSECTIONS.crisis];
        // const accounts = this.accountService.getAccounts(TABS.livingWith, SECTIONS.resources).sort((acc1, acc2) => acc1.order - acc2.order);
        // const articles = this.articleService.getArticles(TABS.livingWith, SECTIONS.resources);
        // this.tabs[0].items = subsections.map(subsection => ({
        //     id: subsection,
        //     accounts: accounts.filter(acc => acc.subSection === subsection) as unknown as GardAccount[],
        //     title: articles.find(article => article.subSection === subsection).title,
        //     bodyText: '',
        //     nullVersion: false,
        // }));
    }

    ngOnInit() {
        this.tabs[0] = this.generateTab(this.tabs[0]);
    }

    reduceArticles(articles: Article[]): Article {
        // copy because we're going to be modifing it
        const article: Article = {
            id: '',
            title: '',
            bodyText: '',
            nullVersion: false,
        } as Article;

        articles.forEach((a: Article, index: number) => {
            if (index === 0) {
                // for Multi-Tab Buttons
                article.title = a.title;
            } else {
                // add title if should be shown
                if (a.displayTitle) {
                    article.bodyText = article.bodyText + `<h4>${a.title}</h4>`;
                }
            }

            // add the body, if not blank
            if (a.bodyText) {
                article.bodyText = article.bodyText + `<p class='ka-spacer mt-3'></p>${a.bodyText}`;
            }
        });
        return article;
    }

    removeAccounts(articles: Article[]): Article[] {
        articles.map(a => JSON.parse(JSON.stringify(a)));
        return articles.map((a: Article) => {
            a.accounts = [];
            return a;
        });
    }

    generateTab(tab: TopTab): TopTab {
        const financial: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.financial, undefined, undefined, false),
        );
        const managing: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.managing, undefined, undefined, false),
        );
        const dayToDay: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.dayToDay, undefined, undefined, false),
        );

        tab.items.push(this.reduceArticles(financial));
        tab.items.push(this.reduceArticles(managing));
        tab.items.push(this.reduceArticles(dayToDay));

        return tab;
    }
}
