/* eslint-disable @typescript-eslint/prefer-for-of, @typescript-eslint/explicit-module-boundary-types */
import { DOCUMENT } from '@angular/common';
import { AfterViewChecked } from '@angular/core';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    ngAfterViewChecked() {
        const links = this.document.querySelectorAll('a:not([rel])[target]');
        if (links.length > 0) {
            for (let i = 0; i < links.length; i++) {
                links[i].setAttribute('rel', 'noopener noreferrer');
            }
        }
    }
}
