import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'filter-by-letter',
    templateUrl: './filter-by-letter.component.html',
    styleUrls: ['./filter-by-letter.component.scss'],
})
export class FilterByLetterComponent {
    @Input() activeLetter: string;
    @Output() selectedLetter = new EventEmitter<string>();

    filterLetters1 = ['0-9', 'A', 'B', 'C', 'D', 'E'];
    filterLetters2 = ['F', 'G', 'H', 'I', 'J', 'K'];
    filterLetters3 = ['L', 'M', 'N', 'O', 'P', 'Q'];
    filterLetters4 = ['R', 'S', 'T', 'U', 'V', 'W'];
    filterLetters5 = ['X', 'Y', 'Z'];

    clickFilterLetter(letter: string) {
        this.selectedLetter.emit(letter);
    }
}
