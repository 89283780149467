<div class="back-container fs-5 d-block d-lg-none px-4 py-4" *ngIf="options?.customRightModal && options?.showCloseButtons">
    <div class="back text-green bg-transparent border-0" aria-label="Back" (click)="dismiss()">
        <div class="btn btn-outline-green circle">
            <i class="icon-gard-arrow-left"></i>
        </div>
        <span>Back</span>
    </div>
</div>

<div class="close-container" *ngIf="options?.customRightModal && options?.showCloseButtons">
    <button class="close d-none d-lg-block fs-2 bg-transparent border-0" aria-label="Close" (click)="dismiss()">&times;</button>
</div>

<div [ngClass]="{ scrollable: options?.customRightModal && options?.scrollable }" class="flex-grow position-relative h-100">
    <ng-template #viewContainerRef></ng-template>

    <div *ngIf="isTemplate()">
        <ng-container *ngTemplateOutlet="component; context: options.templateContext"></ng-container>
    </div>
</div>

<div *ngIf="footerShow" class="buttons d-flex justify-content-between p-4">
    <button class="btn btn-white" (click)="footerFunctionsClear()">Clear</button>
    <button class="btn btn-green" (click)="footerFunctionsApply()">Apply</button>
</div>
