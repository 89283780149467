import { Component, ViewEncapsulation } from '@angular/core';
// import { StorageService } from 'src/app/services/storage.service';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';

// const STORAGE_KEY = 'feedbackBannerHidden';
const REDIRECT_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSd7xqpcAu0E0rMLWqWeg9-ii4pAik6aff2Lgl4LMOgV0pquNw/viewform';

@Component({
    selector: 'feedback-banner',
    templateUrl: './feedback-banner.component.html',
    styleUrls: ['./feedback-banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FeedbackBannerComponent {
    isVisible = false;

    // constructor(private storageService: StorageService) {
    // const val = this.storageService.getItem(STORAGE_KEY);
    // if (val) {
    //     this.isVisible = false;
    // }
    // }

    close() {
        // this.storageService.setItem(STORAGE_KEY, true);
        this.isVisible = false;
    }

    gotoForm(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
            const tab = window.open(REDIRECT_URL, '_blank');
            tab.opener = null;
        }
    }
}
