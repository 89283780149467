import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ElementRef, OnChanges, SimpleChange } from '@angular/core';
import { Component, EventEmitter, HostListener, Inject, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'clamper',
    templateUrl: './clamp.component.html',
    styleUrls: ['./clamp.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClampComponent implements AfterViewInit, OnChanges {
    @ViewChild('host', { static: true }) host: ElementRef<HTMLSpanElement>;
    @ViewChild('ghost', { static: true }) ghost: ElementRef<HTMLSpanElement>;

    @Input() text = '';
    @Input() maxLines = 5;
    @Input() minimumHeight: number;
    @Output() populateReadMoreVisibility = new EventEmitter();
    @Output() clampChanged = new EventEmitter<boolean>();

    /**
     * @description Use this binding to active line clamping logic when element is toggled
     * for example: <clamper [isActive]="selectedIndex === i"></clamper>
     */
    @Input()
    isActive = false;
    isClamped = true;
    collapsed = true;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.calculateLines(); // recalculate lines when window is resized
    }

    ngAfterViewInit(): void {
        this.calculateLines(); // recalculate lines when view is fully rendered
    }

    ngOnChanges(changes: { isActive: SimpleChange; slideId: SimpleChange }) {
        const isActive = changes.isActive?.currentValue as boolean;
        if (isActive) {
            this.calculateLines(); // recalculate lines when component is activated
        }
    }

    toggle() {
        this.collapsed = !this.collapsed;
        setTimeout(() => this.clampChanged.emit(this.collapsed), 10);
    }

    calculateLines() {
        setTimeout(() => {
            const window = this.document.defaultView;
            const ghostElement = this.ghost.nativeElement;
            const style = window.getComputedStyle(ghostElement);
            if (style.height !== 'auto') {
                const fullHeight = parseInt(style.height, 10);
                const lineHeight = parseInt(style.lineHeight, 10);
                const numberOfLines = Math.round(fullHeight / lineHeight);

                if (numberOfLines > this.maxLines) {
                    this.isClamped = true;

                    this.host.nativeElement.style['-webkit-line-clamp'] = this.maxLines;
                    this.ghost.nativeElement.style['display'] = 'none';

                    if (this.minimumHeight) {
                        // Set minimum height for safari browser
                        this.host.nativeElement.style.minHeight = `${this.minimumHeight}px`;
                    }
                } else {
                    this.isClamped = false;
                    this.host.nativeElement.style['-webkit-line-clamp'] = 'initial';
                    this.ghost.nativeElement.style['display'] = 'block';
                }
            }
        });
    }
}
