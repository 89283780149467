import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PATHS } from '../../../../shared/paths';
import { DiseaseService } from 'src/app/services/disease.service';
import { DiseaseTrimmed } from 'shared/types';

@Injectable({
    providedIn: 'root',
})
export class EspanolService {
    englishPath = PATHS.diseasePage;
    spanishPath = PATHS.diseasePageEspanol;
    diseasePageId = PATHS.diseasePageId;
    diseasePageName = PATHS.diseasePageName;

    constructor(private http: HttpClient, private diseaseService: DiseaseService) {
        const trimmedDiseases = diseaseService.trimmedDiseases;
        if (!trimmedDiseases || !trimmedDiseases.length) {
            diseaseService.fetchTrimmed();
        }
    }

    static encodeName(str: string) {
        return encodeURI(
            str
                .replace(/%E2%80%93/g, '')
                .replace(/ /g, '-')
                .replace(/----/g, '-')
                .replace(/---/g, '-')
                .replace(/--/g, '-')
                .replace(/[^\w-]+/g, '')
                .toLowerCase(),
        );
    }

    getSpanishPage(spanishPageURL: string) {
        const compiledURL = PATHS.diseasePageEspanolSource + spanishPageURL;
        return this.http.get(compiledURL, { responseType: 'text' });
    }

    getLinkToEnglish(spanishDiseaseID: string): string | boolean {
        try {
            const spanishDiseaseIDAsNumber = parseInt(spanishDiseaseID, 10);

            const record = this.diseaseService.trimmedDiseases.find((diseaseTrimmed: DiseaseTrimmed) => diseaseTrimmed?.spanishId === spanishDiseaseIDAsNumber);

            if (!record) {
                return false;
            }
            return this.paramsToLinkEnglish(record.id, record.encodedName);
        } catch (e) {
            return false;
        }
    }

    getLinkToSpanish(englishDiseaseID: number): string | boolean {
        const record = this.diseaseService.trimmedDiseases.find((diseaseTrimmed: DiseaseTrimmed) => diseaseTrimmed.id === englishDiseaseID);

        if (!record || !record?.spanishId) {
            // new Error(`Cannot convert to Spanish link, data not found in list for, englishDiseaseID: ${englishDiseaseID}`);
            return false;
        }
        return this.paramsToLinkSpanish(record.spanishId, record.spanishName);
    }

    private paramsToLinkEnglish(diseaseID: number, diseaseName: string): string {
        return '/' + this.englishPath.replace(this.diseasePageId, diseaseID.toString(10)).replace(this.diseasePageName, EspanolService.encodeName(diseaseName));
    }

    private paramsToLinkSpanish(diseaseID: number, diseaseName: string): string {
        return '/' + this.spanishPath.replace(this.diseasePageId, diseaseID.toString(10)).replace(this.diseasePageName, diseaseName);
    }
}
