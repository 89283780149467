<app-top-navigation></app-top-navigation>

<main-banner></main-banner>

<h2-about-gard class="offset"></h2-about-gard>

<h3-understand></h3-understand>

<!-- <h4-not-alone></h4-not-alone> -->

<h5-contact-us></h5-contact-us>

<app-footer></app-footer>

<script type="application/ld+json">
    {
        "@context": "https://schema.org",
        "@type": "GovernmentOrganization",
        "name": "Genetic and Rare Diseases Information Center",
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "telephone": "+1-888-205-2311"
        }
    }
</script>
