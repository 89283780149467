import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { DiseaseService } from './disease.service';

@Injectable({
    providedIn: 'root',
})
export class MetaResolver {
    constructor(private diseaseService: DiseaseService, private meta: Meta, private title: Title) {}

    resolve(route: ActivatedRouteSnapshot) {
        const disease = this.diseaseService.disease;

        if (route.data.meta) {
            if (disease) {
                const diseaseName = disease.name;
                const metaDescription = route.data.meta.description?.replace('{diseaseName}', diseaseName) || '';
                const pageTitle = route.data.meta.title?.replace('{diseaseName}', diseaseName) || '';
                this.meta.updateTag({ name: 'description', content: metaDescription });
                this.title.setTitle(pageTitle);
            } else {
                this.meta.updateTag({ name: 'description', content: route.data.meta.description });
                this.title.setTitle(route.data.meta.title);
            }
        }
        return true;
    }
}
