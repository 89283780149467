import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PATHS } from 'shared/paths';
import { Location } from '@angular/common';
import { DiseaseService } from '../services/disease.service';
import { getDiseasePath } from 'shared/utils/get-disease-path';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private location: Location, private diseaseService: DiseaseService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                switch (err.status) {
                    // Handle error by status code
                    case 404:
                        this.handle404();
                        break;
                    case 308:
                        this.handle308();
                }

                return throwError(err.message);
            }),
        );
    }

    // Disease not found
    private handle404() {
        const previousUrl = window.location.pathname;
        this.router.navigate([PATHS.notFound]).then(() => {
            this.location.replaceState(previousUrl);
        });
    }

    // Incorrect disease spelling
    private handle308() {
        if (this.diseaseService.disease) {
            this.router.navigate(getDiseasePath(this.diseaseService.disease));
        } else {
            this.handle404();
        }
    }
}
