export const arrayToCsv = (rows: Record<string, string | number | Date>[]) => {
  if (!rows || !rows.length) {
    return '';
  }

  const separator = ',';
  const keys = Object.keys(rows[0]);

  return (
    keys.join(separator) +
    '\n' +
    rows
      .map(row =>
        keys
          .map(k => {
            let cell = row[k] ?? '';

            cell =
              cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');

            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }

            return cell;
          })
          .join(separator),
      )
      .join('\n')
  );
};
