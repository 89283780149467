<div class="clamper">
    <span class="host fs-md-18" #host [ngClass]="{ clamped: isClamped && collapsed }" [innerHTML]="text"></span>

    <!-- ghost span is identical to the host span, except it is not clamped so we can measure its line count -->
    <span class="ghost" #ghost [innerHTML]="text"></span>

    <div class="mt-2 position-relative text-end">
        <div class="read-button text-green cursor" [hidden]="!isClamped || !collapsed" (click)="toggle()">
            Read More
            <i class="icon-gard-arrow-down"></i>
        </div>
        <div class="read-button text-green cursor" [hidden]="!isClamped || collapsed" (click)="toggle()">
            Read Less
            <i class="icon-gard-arrow-up"></i>
        </div>
    </div>
</div>
