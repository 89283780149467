import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchFilter } from '../search-filter';

@Component({
    selector: 'filter-bubbles',
    templateUrl: './filter-bubbles.component.html',
    styleUrls: ['./filter-bubbles.component.scss'],
})
export class FilterBubblesComponent {
    @Output() filterEvent = new EventEmitter<SearchFilter>();
    @Input() filter: SearchFilter;

    close() {
        this.filter.enabled = false;
        this.filterEvent.emit(this.filter);
    }
}
