<div class="container-fluid overflow-hidden position-relative getting-diag px-0">
    <div class="background-gradient">
        <div class="circle-top-left"></div>
        <div class="circle-lower-right"></div>
    </div>

    <div class="container-xxxl px-4">
        <div class="col-12 mt-4">
            <h1 class="h1 text-white border-white my-5">Appointments and Diagnostic Evaluations</h1>
        </div>
    </div>

    <app-multitab [tabs]="tabs" accountsTitle="Resources:"></app-multitab>
</div>
