import { ElementRef } from '@angular/core';
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive to detect click outside of an element
 *
 * @example
 * <div (outsideClick)="onClickOutside()"></div>
 */

@Directive({
    selector: '[outsideClick]',
})
export class OutsideClickDirective {
    @Output() outsideClick = new EventEmitter<MouseEvent>();

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:mousedown', ['$event'])
    onClick(event: MouseEvent): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside) {
            this.outsideClick.emit(event);
        }
    }
}
