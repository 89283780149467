<div class="summary-wrapper d-flex flex-column justify-content-between">
    <!-- Desktop -->
    <div class="desktop d-none d-lg-block">
        <div class="mb-4 mb-xl-0 pb-3 pb-xl-0">
            <h5 class="text-blue fw-bold fs-md-3">{{ textHeader }}</h5>
            <div class="fs-md-18 mb-5" [innerHTML]="summaryText"></div>
        </div>
    </div>

    <!-- Mobile -->
    <div class="mobile d-lg-none mb-5">
        <h5 class="text-blue fw-bold fs-md-3 mb-2">{{ textHeader }}</h5>
        <clamper class="fs-6 fs-md-18" [maxLines]="8" [text]="summaryText"></clamper>
    </div>

    <div>
        <div class="fs-5 fw-700 text-blue mb-4" [innerHTML]="infoTitle"></div>
        <ul class="ps-2 text-link fs-6 fs-md-18">
            <li class="ps-0" *ngIf="showOrphanet">
                <span class="ml-neg-pt-6 ps-4">
                    <a target="_blank" [href]="linkOrphanet" link>Orphanet</a>&nbsp;
                    <span [innerHTML]="articleOrphanet.bodyText"></span>
                </span>
            </li>
            <li class="ps-0" *ngIf="showRareSource">
                <span class="ml-neg-pt-6 ps-4">
                    <a target="_blank" [href]="linkRareSource" link>RARe-SOURCE™</a>&nbsp;
                    <span [innerHTML]="articleRareSource.bodyText"></span>
                </span>
            </li>
            <li class="ps-0" *ngIf="showGeneSource">
                <span class="ml-neg-pt-6 ps-4">
                    <a target="_blank" [href]="linkGeneSource" link>GeneReviews</a>&nbsp;
                    <span [innerHTML]="articleGeneSource.bodyText"></span>
                </span>
            </li>
            <li class="ps-0" *ngIf="showBiomedicalDataTranslator">
                <span class="ml-neg-pt-6 ps-4">
                    <a target="_blank" [href]="linkBiomedicalDataTranslator" link>Biomedical Data Translator</a>&nbsp;
                    <span [innerHTML]="articleBiomedicalDataTranslator.bodyText"></span>
                </span>
            </li>
        </ul>

        <div *ngIf="showNullInfo" class="fs-6 fs-md-18">{{ infoNullArticle.bodyText }}</div>
    </div>
</div>
