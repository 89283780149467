import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Article } from '../../../shared/types';
import { ArticleParams, ArticleService } from './article.service';

@Injectable({
    providedIn: 'root',
})
export class SingleArticleResolver {
    constructor(private articleService: ArticleService) {}

    resolve(route: ActivatedRouteSnapshot): Article {
        const articleParams = route.data.singleArticleParams as ArticleParams;
        return this.articleService.getOneDestruct(articleParams);
    }
}
