import { Component, ViewEncapsulation } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { ACCOUNT_RECORD_TYPES, ACCOUNT_TYPES, DISEASE_TAGS } from 'shared/constants';
import { DiagnosisItemType } from 'shared/salesforce-types';
import { Account, Article, Categories, Disease } from 'shared/types';
import { Page, Section, SubSection, SubSubsection } from 'shared/location-enums';
import { availablePatientAdvocacyGroups } from 'src/app/components/common/organizations/patient-advocacy-groups';
import { LocalOrganization } from 'src/app/components/common/organizations/organizations-interfaces';
import { CauseSnippets } from '../../../common/cause/cause-functions';
import { PatientOrganizationsAccountsComponent } from '../patient-organizations-accounts/patient-organizations-accounts.component';

@Component({
    selector: 'disease-at-a-glance-snippets',
    templateUrl: './disease-at-a-glance-snippets.component.html',
    styleUrls: ['./disease-at-a-glance-snippets.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DiseaseAtAGlanceSnippetsComponent {
    public mainTitle: string;
    public mainSummary: string;

    public populationEstimateTitle: string;
    public populationEstimateBody: string;
    public populationEstimateBodyLastWord: string;
    public populationEstimateTooltip: string;

    public ageAtOnsetTitle: string;
    public ageAtOnsetBody: string;

    public causeArticleTitle: string;
    public causeArticleBody: string;

    public patientOrgTitle: string;
    public patientOrgBody: string;

    public newbornTitle: string;
    public newbornBody: string;
    public newbornBodyLastWord: string;
    public newbornTooltip: string;

    public categoriesTitle = 'Categories:';
    public categories: Categories[] = [];

    private diseaseSpecificAccounts: LocalOrganization[];

    constructor(private articleService: ArticleService, private accountService: AccountService, private diseaseService: DiseaseService) {
        // Populate disease specific accounts
        this.getDiseaseSpecificAccounts(diseaseService.disease);

        this.categories = diseaseService.disease.diseaseCategoriesWithDescriptions || [];

        /* Main Article */
        const mainArticle = this.articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.introSnippet, undefined, undefined, false);
        if (mainArticle) {
            this.mainTitle = mainArticle.title.replace('${DiseaseName}', diseaseService.disease.name);

            // Remove any line breaks to ensure correct spacing
            this.mainSummary = mainArticle.bodyText.replace('${DiseaseName}', diseaseService.disease.name).replace(/<br>/g, '');
        }

        /* Population Estimate */
        const populationArticle = this.articleService.getOne(
            Page.about,
            Section.diseaseAtAGlance,
            SubSection.estimateSnippet,
            undefined,
            undefined,
            this.isPopulationNull(diseaseService.disease),
        );
        const populationTooltip = this.articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.epidemiology, undefined, undefined, false, SubSubsection.usaInfo);

        if (populationArticle) {
            this.populationEstimateTooltip = populationTooltip.bodyText;
            this.populationEstimateTitle = populationArticle.title;
            const fullBody = populationArticle.bodyText.replace('${CuratedUSAEstimate}', diseaseService.disease.caseEstimateUsa).split(' ');
            this.populationEstimateBodyLastWord = fullBody.pop();
            this.populationEstimateBody = fullBody.join(' ');
        }

        /* Age At Onset */
        const ageAtOnsetArticle = this.articleService.getOne(
            Page.about,
            Section.diseaseAtAGlance,
            SubSection.ageAtOnsetSnippet,
            undefined,
            undefined,
            this.isAgeAtOnsetNull(diseaseService.disease),
        );
        if (ageAtOnsetArticle) {
            this.ageAtOnsetTitle = ageAtOnsetArticle.title;
            this.ageAtOnsetBody = ageAtOnsetArticle.bodyText.replace('${AgeAtOnsetSnippetText}', diseaseService.disease.ageAtOnsetSnippet);
        }

        /* Cause */
        const causeArticle: Article = this.getCauseSnippetKA(diseaseService.disease);
        if (causeArticle) {
            this.causeArticleTitle = causeArticle.title;
            this.causeArticleBody = causeArticle.bodyText;
        }

        const x = this.isPatientOrgNull();

        /* Patient Organizations */
        const patientOrgArticle = this.articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.pagSnippet, undefined, undefined, this.isPatientOrgNull());

        if (patientOrgArticle) {
            this.patientOrgTitle = patientOrgArticle.title;
            this.patientOrgBody = patientOrgArticle.bodyText;
        }

        /* Newborn Screening */
        const newbornArticle = this.articleService
            .getArticles(Page.about, Section.diseaseAtAGlance, SubSection.newbornScreenSnippet, undefined, undefined, this.isNewbornNull(diseaseService.disease))
            .filter(article => !article.subSubsection)
            .pop(); // Grab the article with no subsubsection
        const newbornTooltip = this.articleService.getOne(
            Page.about,
            Section.diseaseAtAGlance,
            SubSection.newbornScreenSnippet,
            undefined,
            undefined,
            false,
            SubSubsection.newbornScreenDesc,
        );

        if (newbornArticle) {
            this.newbornTitle = newbornArticle.title;

            const fullBody = newbornArticle.bodyText.split(' ');
            this.newbornBodyLastWord = fullBody.pop();
            this.newbornBody = fullBody.join(' ');

            this.newbornTooltip = newbornTooltip.bodyText;
        }
    }

    isPopulationNull(disease: Disease) {
        return !disease.caseEstimateUsa;
    }

    isAgeAtOnsetNull(disease: Disease) {
        return !disease.ageAtOnsetSnippet;
    }

    isPatientOrgNull() {
        // const patientOrgArticle = this.articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.pagSnippet, undefined, undefined, this.isPatientOrgNull());

        const patientOrganizationsAccountsComponent: PatientOrganizationsAccountsComponent = new PatientOrganizationsAccountsComponent(
            undefined,
            this.diseaseService,
            undefined,
            this.accountService,
            this.articleService,
        );

        return patientOrganizationsAccountsComponent.localOrganizations.length < 5;
    }

    isNewbornNull(disease: Disease) {
        const newbornDiagnosis = disease.diagnosis.filter(diagnosis => diagnosis.Type__c === DiagnosisItemType.NEWBORN);

        return !newbornDiagnosis.length;
    }

    getCauseSnippetKA(disease: Disease): Article {
        const causeArticles = this.articleService.getArticles(Page.about, Section.diseaseAtAGlance, SubSection.causeSnippet, undefined, undefined, false);
        const nullKa = this.articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.causeSnippet, undefined, undefined, true);
        return CauseSnippets(disease, causeArticles, nullKa);
    }

    getDiseaseSpecificAccounts(disease: Disease) {
        const accounts = this.accountService.getAccounts().filter((acct: Account) => acct.recordType === ACCOUNT_RECORD_TYPES.patientAdvocacyGroup);

        const accountsFiltered = availablePatientAdvocacyGroups(accounts, disease, true, true, false);

        this.diseaseSpecificAccounts = accountsFiltered;
    }
}
