import { Article, Disease } from '../../../../../shared/types';
import { DISEASE_TAGS } from '../../../../../shared/constants';
import { Page, Section, SubSection } from '../../../../../shared/location-enums';

export function CauseSnippets(disease: Disease, causeArticles: Article[], nullKa: Article): Article {
    let ret: Article = nullKa;

    const diseaseTags = disease.tagsCause;

    // PER https://ncats-nih.atlassian.net/browse/G20W-3370
    // IF disease is tagged with only Genetic & Chromosomal tags,
    //  - THEN display chromosomal cause snippet KA
    // ELSE IF disease is tagged with only the Genetic tag AND NOT Chromosomal,
    //  - THEN display genetic cause snippet.
    // ELSE IF disease is tagged with only one of the n=5 infectious disease cause tags (Mycosis, Parasite, Bacterial, Congenerical and Viral)
    //  - THEN display corresponding cause snippet KA
    // ELSE IF disease is tagged with multiple cause tags , THEN display the multiple causes KA
    //  - e.g., genetic +chromosomal + one or more of 5 infectious tags
    //  - e.g., genetic + one or more of 5 infectious tags
    //  - e.g., more than one of 5 infectious tags
    // ELSE display NULL cause snippet KA

    if (diseaseTags.length >= 1) {
        // ELSE IF disease is tagged with only one of the n=5 infectious disease cause tags (Mycosis, Parasite, Bacterial, Congenerical and Viral)
        // Make list here
        const infectiousDiseaseCauseTags = [
            DISEASE_TAGS.rareMycosis,
            DISEASE_TAGS.rareParasiticInfectiousDiseases,
            DISEASE_TAGS.rareBacterialInfectiousDisease,
            DISEASE_TAGS.rareCongenitalInfectiousDiseases,
            DISEASE_TAGS.rareViralDisease,
        ];
        const infectiousDiseaseCauseTagsOnThisDisease = infectiousDiseaseCauseTags.filter((tag: string) => diseaseTags.includes(tag));

        // IF disease is tagged with only Genetic & Chromosomal tags,
        if (diseaseTags.length === 2 && diseaseTags.includes(DISEASE_TAGS.genetics) && diseaseTags.includes(DISEASE_TAGS.chromosomalAnomaly)) {
            //  - THEN display chromosomal cause snippet KA
            ret = causeArticles.find(article => article.tags[0] === DISEASE_TAGS.chromosomalAnomaly);
        }

        // ELSE IF disease is tagged with only Genetic tag and Cancer, PER: https://ncats-nih.atlassian.net/browse/G20W-3674
        else if (diseaseTags.length === 2 && diseaseTags.includes(DISEASE_TAGS.genetics) && diseaseTags.includes(DISEASE_TAGS.cancer)) {
            // Article for multiple found tags has no tag while others have one or more: This disease has more than one possible cause.
            ret = causeArticles.find(article => !article.tags.length);
        }

        // ELSE IF disease is tagged with only Genetic tag and Cancer, PER: https://ncats-nih.atlassian.net/browse/G20W-3674
        else if (diseaseTags.length === 2 && diseaseTags.includes(DISEASE_TAGS.genetics) && diseaseTags.includes(DISEASE_TAGS.infectiousEmbryofetopathy)) {
            // Article for multiple found tags has no tag while others have one or more: This disease has more than one possible cause.
            ret = causeArticles.find(article => !article.tags.length);
        }

        // ELSE IF disease is tagged with only the Genetic tag AND NOT Chromosomal,
        else if (diseaseTags.length === 2 && diseaseTags.includes(DISEASE_TAGS.genetics) && !diseaseTags.includes(DISEASE_TAGS.chromosomalAnomaly)) {
            //  - THEN display genetic cause snippet.
            ret = causeArticles.find(article => article.tags[0] === DISEASE_TAGS.genetics);
        }

        // Any time there is only one tag, show that KA
        else if (diseaseTags.length === 1) {
            //  - THEN display genetic cause snippet.
            ret = causeArticles.find(article => article.tags[0] === diseaseTags[0]);
        }

        // ELSE IF disease is tagged with only one of the n=5 infectious disease cause tags (Mycosis, Parasite, Bacterial, Congenerical and Viral)
        else if (infectiousDiseaseCauseTagsOnThisDisease.length === 1) {
            //  - THEN display corresponding cause snippet KA
            ret = causeArticles.find(article => article.tags[0] === infectiousDiseaseCauseTagsOnThisDisease[0]);
        }

        // ELSE disease is tagged with multiple cause tags , THEN display the multiple causes KA
        //  - e.g., genetic +chromosomal + one or more of 5 infectious tags
        //  - e.g., genetic + one or more of 5 infectious tags
        //  - e.g., more than one of 5 infectious tags
        else {
            // Article for multiple found tags has no tag while others have one or more: This disease has more than one possible cause.
            ret = causeArticles.find(article => !article.tags.length);
        }
    } else {
        // Null KA
        return nullKa;
    }
    return ret;
}

export function CauseSectionButtons(disease: Disease, articles: Article[], questions: Article[], articleGene: Article): Article[] {
    // debugger;

    const geneList = disease.genes.filter(d => d.geneCausal);
    const diseaseGeneSymbols = geneList.map(gene => gene.geneSymbol).join(', ') || 'UNKNOWN';

    // do dynamic KA replacement
    articles.forEach(article => {
        article.bodyText = article.bodyText.replace('${DiseaseName}', disease.name);
        article.bodyText = article.bodyText.replace('${DiseaseGeneSymbols}', diseaseGeneSymbols);
        article.bodyText = article.bodyText.trim();
        return article;
    });

    // Get disease tags that apply to this section
    const diseaseTags: string[] = disease.tagsCause;

    const topQuestions: Article[] = [];

    // get Articles and Questions, filter by tag

    // Only Genetics
    if (diseaseTags.includes(DISEASE_TAGS.cancer)) {
        const cancerQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.cancer));

        topQuestions.push(...cancerQuestions);
    }

    // Only Genetics
    if (diseaseTags.includes(DISEASE_TAGS.genetics)) {
        // const geneticsArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.genetics))[0];
        const geneticsQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.genetics));

        // this.mainArticle = geneticsNewArticle;
        topQuestions.push(...geneticsQuestions);
    }

    // Genetics, mitochondrial Anomaly
    if (diseaseTags.includes(DISEASE_TAGS.mitochondrial)) {
        // const mitochondrialAnomalyArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.mitochondrial))[0];
        const mitochondrialAnomalyQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.mitochondrial));

        // this.mainArticle = geneticsNewArticle;
        topQuestions.push(...mitochondrialAnomalyQuestions);
    }

    // Genetics, Chromosomal Anomaly
    if (diseaseTags.includes(DISEASE_TAGS.chromosomalAnomaly)) {
        // const chromosomalAnomalyArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.chromosomalAnomaly))[0];
        const chromosomalAnomalyQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.chromosomalAnomaly));

        // this.mainArticle = geneticsNewArticle;
        topQuestions.push(...chromosomalAnomalyQuestions);
    }

    // Infectious Disease
    if (diseaseTags.includes(DISEASE_TAGS.infectiousDisease)) {
        // const infectiousDiseaseArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.infectiousDisease))[0];
        const infectiousDiseaseQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.infectiousDisease));

        // this.mainArticle = infectiousDiseaseArticle;
        topQuestions.push(...infectiousDiseaseQuestions);
    }

    if (diseaseTags.includes(DISEASE_TAGS.infectiousEmbryofetopathy)) {
        // const infectiousEmbryofetopathyArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.infectiousEmbryofetopathy))[0];
        const infectiousEmbryofetopathyQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.infectiousEmbryofetopathy));

        // this.mainArticle = infectiousEmbryofetopathyArticle;
        topQuestions.push(...infectiousEmbryofetopathyQuestions);
    }

    if (diseaseTags.includes(DISEASE_TAGS.rareBacterialInfectiousDisease)) {
        // const rareBacterialInfectiousDiseaseArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.rareBacterialInfectiousDisease))[0];
        const rareBacterialInfectiousDiseaseQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.rareBacterialInfectiousDisease));

        // this.mainArticle = rareBacterialInfectiousDiseaseArticle;
        topQuestions.push(...rareBacterialInfectiousDiseaseQuestions);
    }

    if (diseaseTags.includes(DISEASE_TAGS.rareMycosis)) {
        // const rareMycosisArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.rareMycosis))[0];
        const rareMycosisQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.rareMycosis));

        // this.mainArticle = rareMycosisArticle;
        topQuestions.push(...rareMycosisQuestions);
    }

    if (diseaseTags.includes(DISEASE_TAGS.rareParasiticDisease)) {
        // const rareParasiticDiseaseArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.rareParasiticDisease))[0];
        const rareParasiticDiseaseQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.rareParasiticDisease));

        // this.mainArticle = rareParasiticDiseaseArticle;
        topQuestions.push(...rareParasiticDiseaseQuestions);
    }

    if (diseaseTags.includes(DISEASE_TAGS.rareViralDisease)) {
        // const rareViralDiseaseArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.rareViralDisease))[0];
        const rareViralDiseaseQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.rareViralDisease));

        // this.mainArticle = rareViralDiseaseArticle;
        topQuestions.push(...rareViralDiseaseQuestions);
    }

    // inheritedMetabolicDiseases
    if (diseaseTags.includes(DISEASE_TAGS.inheritedMetabolicDiseases)) {
        // const infectiousDiseaseArticle: Article = articles.filter(a => a.tags.includes(DISEASE_TAGS.inheritedMetabolicDiseases))[0];
        const inheritedMetabolicDiseasesQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.inheritedMetabolicDiseases));
        // this.mainArticle = infectiousDiseaseArticle;
        topQuestions.push(...inheritedMetabolicDiseasesQuestions);
    }

    // leukodystrophy
    if (diseaseTags.includes(DISEASE_TAGS.leukodystrophy)) {
        const leukodystrophyDiseasesQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.leukodystrophy));
        topQuestions.push(...leukodystrophyDiseasesQuestions);
    }

    // lsysosomal
    if (diseaseTags.includes(DISEASE_TAGS.lsysosomal)) {
        const lsysosomalDiseasesQuestions: Article[] = questions.filter(a => a.tags.includes(DISEASE_TAGS.lsysosomal));
        topQuestions.push(...lsysosomalDiseasesQuestions);
    }

    // add the article
    if (geneList.length > 0) {
        // Gene

        topQuestions.push(articleGene);
    }

    topQuestions.map((article: Article) => {
        article.bodyText = article.bodyText.replace('${DiseaseName}', disease.name);
        article.bodyText = article.bodyText.replace('${DiseaseGeneSymbols}', diseaseGeneSymbols);
        return article;
    });

    // sort KAs
    return topQuestions.sort((a: Article, b: Article) => a.order - b.order);
}
