import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PATHS } from '../../../../shared/paths';
import { Subscription } from 'rxjs';

interface ErrorEntry {
    errorMessage: string;
    callstack: string;
}
interface ErrorTypes {
    ka: ErrorEntry[];
    account: ErrorEntry[];
    disease: ErrorEntry[];
    img: ErrorEntry[];
}

@Component({
    selector: 'error-list',
    templateUrl: './error-list.component.html',
    styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnDestroy {
    errorTypes = ['ka', 'account', 'disease', 'img'];
    errorData: ErrorTypes;
    subscription: Subscription;

    constructor(private http: HttpClient) {
        this.subscription = this.http.get<ErrorTypes>(PATHS.errorFile).subscribe(data => {
            this.errorData = data;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
