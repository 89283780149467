import { OnDestroy } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { filter } from 'rxjs/operators';
import { MessageBusService } from 'src/app/services/message-bus.service';
import { MESSAGE_KEYS } from '../../../../shared/constants';
import { PATHS } from '../../../../shared/paths';
import { BehaviorSubject } from 'rxjs';
import { LANGUAGE } from '../../../../shared/injection-tokens';
import { Language } from '../../../../shared/salesforce-types';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnDestroy {
    languagePaths = PATHS[this.language.value];
    isSearchResultPage: boolean;
    paths = PATHS;

    private sub = new Subscription();

    constructor(messageBusService: MessageBusService, router: Router, @Inject(LANGUAGE) private language: BehaviorSubject<Language>, route: ActivatedRoute) {
        this.sub.add(
            messageBusService
                .get(MESSAGE_KEYS.searchOpen)
                //.pipe(filter(x => x))
                .subscribe(() => {
                    router.navigate([PATHS.searchResultEmpty]);
                }),
        );
        this.isSearchResultPage = route.snapshot.routeConfig.path.startsWith(PATHS.searchResultEmpty);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
