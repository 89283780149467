import { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { DiseaseService } from 'src/app/services/disease.service';
import { DIAGNOSIS_SECTIONS } from 'shared/constants';
import { Disease } from 'shared/types';
import { Section } from 'shared/location-enums';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';

@Component({
    selector: 'app-disease-diagnosis',
    templateUrl: './disease-diagnosis.component.html',
    styleUrls: ['./disease-diagnosis.component.scss'],
})
export class DiseaseDiagnosisComponent implements AfterViewInit {
    public sections = DIAGNOSIS_SECTIONS;
    public section: typeof Section = Section;

    public disease: Disease;

    constructor(diseaseService: DiseaseService) {
        this.disease = diseaseService.disease;
    }

    ngAfterViewInit(): void {
        // Add on click methods to links that are not .gov
        const links = Object.values(document.getElementsByTagName('a')).filter(link => !link.href.includes('.gov') && !link.baseURI.includes(link.hostname));
        links.forEach(linkEl => {
            const href = linkEl.href;
            linkEl.addEventListener('click', (ev: MouseEvent) => this.onOutsideRedirect(ev, href));
        });
    }

    onOutsideRedirect(ev: MouseEvent, link: string) {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
            const tab = window.open(link, '_blank');
            tab.opener = null;
        }
    }
}
