import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

const LOGGING_SERVICE_ADDRESS_AND_PORT = 'http://localhost:3000/';
const LOGGING_SERVICE_ADDRESS_AND_PORT_RUNNING = LOGGING_SERVICE_ADDRESS_AND_PORT + 'running';

@Injectable({
    providedIn: 'root',
})
export class ErrorLogService {
    private loggingFunction: (type: string, error: string) => void;

    constructor(private http?: HttpClient) {
        // Log only to console until we see if webservice is running
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.loggingFunction = this.logError;

        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (window && window['ScullyIO'] === 'running') {
            // we have http, which means we're not testing
            this.http
                .get(LOGGING_SERVICE_ADDRESS_AND_PORT_RUNNING)
                .pipe(take(1))
                .subscribe(
                    res => {
                        if (res === true) {
                            // webservice is running
                            this.loggingFunction = this.logToFile;
                        }
                    },
                    err => {
                        console.error(err);
                        // service not running, do nothing
                    },
                );
        }
    }

    static formatCallstack(callstack: string): string {
        callstack = callstack.replace(/Error\n /g, '');
        callstack = callstack.replace(/ \n/g, '\n');
        callstack = callstack.replace(/ {3}at /g, '');
        const arrCallstack = callstack
            .split('\n')
            .map(str => str.trim())
            .slice(2);
        return arrCallstack.join('|');
    }

    log(type: string, error: string) {
        this.loggingFunction(type, error);
    }

    private logError(type: string, error: string): void {
        // eslint-disable-next-line no-console
        console.error(`${type} Error:\n${error}`);
    }

    private logToFile(type: string, error: string): void {
        // (Also) Log
        this.logError(type, error);

        // call stack
        // const callstack = ErrorLogService.formatCallstack(new Error().stack);

        // Log to node service
        this.http
            .post(LOGGING_SERVICE_ADDRESS_AND_PORT, {
                type,
                error,
            })
            .subscribe();
    }
}
