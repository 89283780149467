import { Component, Input } from '@angular/core';
import { SymptomSlide } from '../symptoms-section/symptom-slide';

@Component({
    selector: 'app-symptoms-section-readmore-popup',
    templateUrl: './symptoms-section-readmore-popup.component.html',
    styleUrls: ['./symptoms-section-readmore-popup.component.scss'],
})
export class SymptomsSectionReadmorePopupComponent {
    @Input() symptomSlide: SymptomSlide;
    @Input() frequencyKa: string;
    @Input() frequencyTexts: string[] = [];
    @Input() frequencyNumbers: string[] = [];

    get frequencyTextsWithBr(): string {
        return this.frequencyTexts.join('<br />');
    }

    get frequencyNumbersWithBr(): string {
        return this.frequencyNumbers.join('<br />');
    }
}
