import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Section, Page } from 'shared/location-enums';
import { Article } from 'shared/types';

@Component({
    selector: 'app-view-sources-references',
    templateUrl: './view-sources-references.component.html',
    styleUrls: ['./view-sources-references.component.scss'],
})
export class ViewSourcesReferencesComponent {
    areSourcesVisible = false;
    article: Article;

    constructor(articleService: ArticleService) {
        this.article = articleService.getOne(Page.all, Section.allReferences);
    }

    toggleSources() {
        this.areSourcesVisible = !this.areSourcesVisible;
    }
}
