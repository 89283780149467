<div *ngIf="showFrequencyText && frequencyValueForMarker > 0" class="frequency-header">
    <div class="d-flex align-items-center">
        <div>
            <div class="frequency-text">Frequency</div>
        </div>
        <div>
            <app-tooltip *ngIf="showInfo" [text]="tooltipText" mobileIconClass="icon-small"></app-tooltip>
        </div>
    </div>
</div>

<div *ngIf="showFrequencyText && frequencyValueForMarker === 0" class="frequency-header d-none d-md-block">
    <div class="d-flex align-items-center">
        <div>
            <div class="frequency-text">Frequency: <span class="unknown">Not Applicable or Unknown</span></div>
        </div>
        <div>
            <app-tooltip *ngIf="showInfo" [text]="tooltipText" mobileIconClass="icon-small"></app-tooltip>
        </div>
    </div>
</div>

<div *ngIf="showFrequencyText && frequencyValueForMarker === 0" class="frequency-header d-md-none">
    <div class="row">
        <div class="col-6">
            <div class="frequency-text">Frequency</div>
        </div>
        <div class="col-6 align-right">
            <app-tooltip *ngIf="showInfo" [text]="tooltipText" mobileIconClass="icon-small"></app-tooltip>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <span class="unknown">Not Applicable or Unknown</span>
        </div>
    </div>
</div>

<div class="wrap" [ngClass]="{ 'grey-cover': frequencyValueForMarker === 0 }">
    <div class="label label-left" [ngClass]="{ 'hide-when-small': !forceLabels }">Uncommon</div>
    <div class="frequency-wrap">
        <div class="ltblue1"></div>
        <div class="ltblue2"></div>
        <div class="mdblue3"></div>
        <div class="mdblue4"></div>
        <div class="dkblue5"></div>
        <div class="marker-text d-none d-md-block" [style.left.%]="frequencyValueForText" *ngIf="frequencyValueForMarker">
            <div class="text">{{ frequencyText }}</div>
        </div>
        <div class="marker-text d-md-none" [style.left.%]="frequencyValueForTextNoMarker" *ngIf="frequencyValueForMarker">
            <div class="text">{{ frequencyText }}</div>
        </div>
        <div class="marker" [style.left.%]="frequencyValueForMarker" *ngIf="frequencyValueForMarker">
            <div class="line"></div>
            <div class="dot"></div>
        </div>
    </div>
    <div class="label label-right" [ngClass]="{ 'hide-when-small': !forceLabels }">Always</div>
</div>
