import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { ArticleService } from './article.service';

@Injectable({
    providedIn: 'root',
})
export class ArticlesResolver {
    constructor(private articleService: ArticleService) {}

    resolve() {
        const subArticle = this.articleService.fetch();
        return subArticle.pipe(map(() => true));
    }
}
