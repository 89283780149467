import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[phoneLink]',
})
export class PhoneLinkDirective implements AfterViewInit {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.linkPhoneNumbers();
    }

    private linkPhoneNumbers(): void {
        const phoneNumberRegex = /(\+?1?[\s.-]?\(?(\d{3})\)?[\s.-]?\d{3}[\s.-]?\d{4})/g;
        const content = this.el.nativeElement.innerHTML;

        const updatedContent = content.replace(phoneNumberRegex, (match: string) => {
            return `<a href="tel:${match.replace(/[\s.-]/g, '')}">${match}</a>`;
        });

        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', updatedContent);
    }
}
