import { DOCUMENT } from '@angular/common';
import { Directive, Inject, HostListener } from '@angular/core';
import { EXTERNAL_POPUP_TEXT_EN } from '../../../../shared/constants';
import { ExternalLinkDirective } from '../external-link/external-link.directive';

@Directive({
    selector: '[embeddedHTML]',
})
export class EmbeddedHtmlDirective {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        let link: HTMLAnchorElement;
        if ((event.target as HTMLAnchorElement).tagName === 'A') {
            link = event.target as HTMLAnchorElement;
        } else if ((event.target as HTMLElement).tagName === 'I' && (event.target as HTMLElement).className === 'icon-gard-open-link') {
            link = (event.target as HTMLElement).parentElement as HTMLAnchorElement;
        }

        if (link) {
            if (!ExternalLinkDirective.isLinkInternal(link.href, this.document.location.host)) {
                event.preventDefault();
                event.stopPropagation();
                if (confirm(EXTERNAL_POPUP_TEXT_EN)) {
                    const tab = window.open(link.href, '_blank');
                    tab.opener = null;
                }
            }
        }
    }
}
