import { Component, ViewEncapsulation } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from '../../../../../shared/location-enums';

@Component({
    selector: 'h5-contact-us',
    templateUrl: './h5-contact-us.component.html',
    styleUrls: ['./h5-contact-us.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class H5ContactUsComponent {
    public image: string;
    public imageAlt: string;
    public titleKa: Article;
    public phoneKa: Article;
    public tollKa: Article;
    public formKa: Article;
    public buttonKa: Article;
    public buttonUrl: string;

    constructor(articleService: ArticleService) {
        this.titleKa = articleService.getOne(Page.home, Section.contactUs, undefined, 1);
        this.phoneKa = articleService.getOne(Page.home, Section.contactUs, undefined, 2);
        this.tollKa = articleService.getOne(Page.home, Section.contactUs, undefined, 3);
        this.formKa = articleService.getOne(Page.home, Section.contactUs, undefined, 4);
        this.buttonKa = articleService.getOne(Page.home, Section.contactUs, undefined, 5);
        this.buttonUrl = articleService.getOne(Page.home, Section.contactUs, SubSection.homeContactFormButton).bodyText;
        this.image = PATHS.articleImages + this.titleKa.images[0]?.url + '-800.webp';
        this.imageAlt = this.titleKa.images[0]?.altText;
    }
}
