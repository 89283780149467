import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { ArticleService } from '../../../services/article.service';
import { PATHS } from 'shared/paths';
import { Language } from 'shared/salesforce-types';

@Component({
    selector: 'h3-understand',
    templateUrl: './h3-understand.component.html',
    styleUrls: ['./h3-understand.component.scss'],
})
export class H3UnderstandComponent {
    public imgPath = PATHS.articleImages;
    articleUnderstandIntro: Article;
    articleExploreDiseasePagesButtonText: Article;
    articleTabAAboutDisease: Article;
    articleTabBDiagnosisTreatment: Article;
    articleTabCLivingDisease: Article;
    articleTabDResearch: Article;
    diseasePagesUrl: string;

    constructor(articleService: ArticleService, route: ActivatedRoute) {
        const pageLanguage = route?.snapshot?.data?.language || Language.En;
        this.diseasePagesUrl = '/' + PATHS[pageLanguage].diseases;

        this.articleUnderstandIntro = articleService.getOne(Page.home, Section.gardServices, SubSection.intro);

        this.articleExploreDiseasePagesButtonText = articleService.getOne(Page.home, Section.gardServices, SubSection.browseDiseaseButton);

        this.articleTabAAboutDisease = articleService.getOne(Page.home, Section.gardServices, SubSection.browseDiseases);

        this.articleTabCLivingDisease = articleService.getOne(Page.home, Section.gardServices, SubSection.contactUsOverview);

        this.articleTabBDiagnosisTreatment = articleService.getOne(Page.home, Section.gardServices, SubSection.findResources);

        this.articleTabDResearch = articleService.getOne(Page.home, Section.gardServices, SubSection.findSupport);
    }
}
