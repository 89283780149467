<div class="container-fluid bg-lightblue">
    <div class="container-xxxl cause-container">
        <div>
            <app-bar-header textHeader="Causes" theme="maroon"></app-bar-header>
        </div>

        <div class="row" *ngIf="isNull">
            <div class="col-lg-6 col-12">
                <p class="causes-text" [innerHTML]="topNullArticle.bodyText"></p>
            </div>
        </div>

        <div class="etiology-section" *ngIf="!isNull">
            <causes-tabs [articles]="topQuestions" [isNull]="isNull" [nullArticle]="topNullArticle" [mainArticle]="subArticle"></causes-tabs>
        </div>
    </div>
</div>
