import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Article } from 'shared/types';
import { Page, Section, SubSection, SubSubsection } from 'shared/location-enums';
import { ArticleService } from 'src/app/services/article.service';

@Component({
    selector: 'disease-section-contact-us',
    templateUrl: './disease-section-contact-us.component.html',
    styleUrls: ['./disease-section-contact-us.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DiseaseSectionContactUsComponent implements OnInit {
    contactCenter: Article;
    contactPhone: Article;
    contactArticle: Article;

    leftArticle: Article;

    @Input() page: Page;

    contactButtonUrl: string;

    constructor(public articleService: ArticleService) {
        this.contactArticle = articleService.getOne(this.page, Section.research, SubSection.GARDContactCenter, 2, undefined, false, SubSubsection.intro);

        this.contactCenter = articleService.getOne(Page.all, Section.gardContactCenter, SubSection.contactCenterInfo, undefined, undefined, false);

        this.contactPhone = articleService.getOne(Page.all, Section.header);

        this.contactButtonUrl = this.contactCenter.linkUrl;
    }

    ngOnInit(): void {
        this.leftArticle = this.articleService.getOne(this.page, Section.contactUs, SubSection.parent);
    }
}
