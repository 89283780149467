import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-bar-header',
    templateUrl: './bar-header.component.html',
    styleUrls: ['./bar-header.component.scss'],
})
export class BarHeaderComponent {
    @Input() theme = 'maroon';
    @Input() textHeader = '';
}
