import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LANGUAGE } from '../../../shared/injection-tokens';
import { Language } from '../../../shared/salesforce-types';

@Injectable({
    providedIn: 'root',
})
export class LanguageResolver {
    constructor(@Inject(LANGUAGE) private language: BehaviorSubject<Language>) {}

    resolve(route: ActivatedRouteSnapshot): Language {
        this.language.next(route.data.language as Language);
        return route.data.language as Language;
    }
}
