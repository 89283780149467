import { Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArticleService } from '../../../services/article.service';
import { FOOTER_LINKS, PATHS } from '../../../../../shared/paths';
import { LANGUAGE } from '../../../../../shared/injection-tokens';
import { LABELS } from '../../../../../shared/labels';
import { Language } from '../../../../../shared/salesforce-types';
import { Page, Section } from '../../../../../shared/location-enums';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    paths = PATHS[this.language.value];

    footerLinks = FOOTER_LINKS;

    labels = LABELS[this.language.value];

    // Section titles
    about = this.articleService.getOne(Page.about, Section.header, undefined, 1).title;
    privacyPolicy = this.articleService.getOne(Page.privacyPolicy).title;
    accessibility = this.articleService.getOne(Page.accessibility).title;
    disclaimer = this.articleService.getOne(Page.disclaimer).title;
    foia = this.articleService.getOne(Page.foia).title;
    resources = Section.resources;

    constructor(@Inject(LANGUAGE) public language: BehaviorSubject<Language>, private articleService: ArticleService) {}
}
