import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { Directive, HostBinding, HostListener, Inject } from '@angular/core';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';

@Directive({
    selector: '[link]',
})
export class ExternalLinkDirective implements AfterViewInit {
    @HostBinding('attr.rel') rel = 'noopener noreferrer';
    private isInternal = true;

    constructor(private el: ElementRef<HTMLAnchorElement>, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

    static isLinkInternal(link: string, host?: string) {
        if (link === '') {
            return true;
        }
        const url = new URL(link);
        if (url.protocol === 'mailto:') {
            return true;
        }
        if (url.host.endsWith('gov')) {
            return true;
        }
        // for testing only, excluded current host, as if it was a .gov
        if (url.host === host) {
            return true;
        }
        return false;
    }

    @HostListener('click', ['$event'])
    onClick(ev: Event) {
        if (!this.isInternal) {
            ev.preventDefault();
            ev.stopPropagation();
            if (confirm(EXTERNAL_POPUP_TEXT_EN)) {
                const tab = window.open(this.el.nativeElement.href, '_blank');
                tab.opener = null;
            }
        }
    }

    ngAfterViewInit() {
        this.isInternal = ExternalLinkDirective.isLinkInternal(this.el.nativeElement.href, this.document.location.host);

        if (!this.isInternal) {
            const icon = this.renderer.createElement('i') as HTMLElement;
            this.renderer.addClass(icon, 'icon-gard-open-link');
            this.renderer.appendChild(this.el.nativeElement, icon);
        }
    }
}
