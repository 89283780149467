import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { PATHS } from 'shared/paths';
import { randomId } from 'shared/utils/random-id';
import { ExternalLinkDirective } from 'src/app/directives/external-link/external-link.directive';
import { TopTab, PanelType } from '../multitab/multitab.types';

@Component({
    selector: 'button-detail-list-larger',
    templateUrl: './button-detail-list-larger.component.html',
    styleUrls: ['./button-detail-list-larger.component.scss'],
})
export class ButtonDetailListLargerComponent {
    @Input() tabs: TopTab[];
    @Input() hideTabs = false;
    @Input() accountsTitle: string | undefined = undefined;

    public imgPath = PATHS.articleImages;
    public panelType = PanelType;
    public tabIndex = 0; // top tab index
    public stIndex = 0; // left tab current index (sub)
    public mstIndex = -1; // mobile sub tab index (sub)
    public sstIndex = -1; // right tab index (sub sub)
    public prefix = randomId();
    public buttonClass = 'nav-link';

    constructor(@Inject(DOCUMENT) private document: Document) {}

    onTabClick(i: number) {
        this.tabIndex = i;
        this.stIndex = 0;
        this.mstIndex = -1;
        this.sstIndex = -1;
    }

    onSubTabClick(i: number) {
        this.stIndex = i;
        this.sstIndex = -1;
    }

    onMobileSubTabClick(i: number) {
        this.mstIndex = this.mstIndex > -1 ? -1 : i;
        this.sstIndex = -1;
    }

    toggleSubSub(i: number) {
        this.sstIndex = this.sstIndex > -1 ? -1 : i;
    }
}
