/* eslint-disable max-len */
import { DOCUMENT } from '@angular/common';
import { OnInit, Renderer2 } from '@angular/core';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ExternalLinkDirective } from 'src/app/directives/external-link/external-link.directive';
import { EspanolService } from './espanol.service';

const POPUP_TEXT = `Usted está saliendo del sitio web de GARD.

Este enlace externo provee información adicional que es consistente con el propósito previsto del sitio. GARD no puede dar fe de la exactitud de un sitio no federal.

La vinculación a un sitio no federal no constituye un respaldo de GARD o cualquiera de sus empleados a los patrocinadores o la información y los productos presentados en el sitio. Usted estará sujeto a la política de privacidad del sitio de destino al hacer clic en el enlace.`;

interface LinkTransform {
    currentLink: string;
    replaceLink: string;
}

@Component({
    selector: 'app-espanol-disease-detail',
    templateUrl: './espanol-disease-detail.component.html',
    styleUrls: ['./espanol-disease-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EspanolDiseaseDetailComponent implements OnInit {
    badDomains: string[] = ['app-na.readspeaker.com', 'fqdeluruguay.org', 'fundafiq.com', 'omer.org.mx'];
    badDomainsWww: string[] = ['rarediseases.info.nih.gov'];

    badLinks: string[] = [
        'http://www.genome.gov/GlossaryS/about.cfm?id=134',
        'http://www.cff.org/UploadedFiles/LivingWithCF/Espanol/19-Respiratorio-Lo-Que-Usted-Debe-Saber-Sobre-Los-Germenes.pdf',
        'https://www.fda.gov/NewsEvents/Newsroom/ComunicadosdePrensa/ucm453925.htm',
        'https://umm.edu/health/medical/spanishency/articles/trasplante-de-pulmon',
        'https://umm.edu/health/medical/spanishency/articles/prueba-de-tolerancia-a-la-glucosa',
        'http://www.umm.edu/Health/Medical/SpanishEncy/Articles/Pruebas-de-la-funcion-hepatica',
        'http://fq.org.mx/sitio/',
        'http://www.fqarg.com.ar/asocia.htm',
        'http://www.cff.org/LivingWithCF/Espanol/',
        'http://www.medicinapreventiva.com.ve/espirometria.htm',
        'https://www.genome.gov/GlossaryS/about.cfm?id=29',
        'http://www.medicinapreventiva.com.ve/espirometria.htm',
        'http://www.genome.gov/page.cfm?pageID=10001213',
        'http://www.nhlbi.nih.gov/health/health-topics/topics/cf/diagnosis',
    ];

    linkTransform: LinkTransform[] = [
        { currentLink: 'https://www.cff.org/', replaceLink: 'https://www.cff.org/es/bienvenido' },
        { currentLink: 'https://espanol.cdc.gov/enes/flu/protect/keyfacts.htm', replaceLink: 'https://espanol.cdc.gov/vaccines/vpd/flu/public/' },
        { currentLink: 'http://ghr.nlm.nih.gov/dynamicImages/chromomap/ANKRD11.jpeg', replaceLink: 'https://medlineplus.gov/spanish/genetica/' },
        { currentLink: 'https://espanol.cdc.gov/enes/flu/protect/keyfacts.htm', replaceLink: 'https://espanol.cdc.gov/vaccines/vpd/flu/public/' },
    ];

    spanishContent: string;
    englishURL: string | boolean;
    public spanishDiseaseName: string;

    constructor(
        private route: ActivatedRoute,
        private espanolServiceService: EspanolService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private meta: Meta,
        private title: Title,
    ) {
        // construct bad domains list with and without WWW
        this.badDomains.forEach(domain => {
            this.badDomainsWww.push(domain);
            this.badDomainsWww.push(`www.${domain}`);
        });
    }

    ngOnInit(): void {
        const diseaseId = this.route.snapshot.paramMap.get('diseaseId');
        const diseasePageName = `Disease_${diseaseId}.dat`;
        this.englishURL = this.espanolServiceService.getLinkToEnglish(diseaseId);
        this.espanolServiceService.getSpanishPage(diseasePageName).subscribe(pageSource => {
            const domParser = new DOMParser();
            const parsedHTML = domParser.parseFromString(pageSource, 'text/html');

            // Set meta data for SEO
            this.spanishDiseaseName = parsedHTML.getElementsByClassName('main-page-title')[0].children[0].innerHTML.trim();
            const metaDescription = this.route.snapshot.data?.meta?.description?.replace('{diseaseName}', this.spanishDiseaseName) || '';
            this.meta.updateTag({ name: 'description', content: metaDescription });

            // Spanish page title
            let pageTitle: string = this.route.snapshot.data?.meta?.title || '';
            pageTitle = pageTitle.replace('{diseaseName}', this.spanishDiseaseName);
            pageTitle = `${pageTitle}`;
            this.title.setTitle(pageTitle);

            // Remove styles on top row
            const elCol = parsedHTML.getElementById('diseasePageContent');
            elCol.className = 'col';

            // diseaseIntroPage
            const elTitle = parsedHTML.getElementsByClassName('main-page-title');
            Array.from(elTitle).forEach(el => el.remove());

            // diseaseIntroPage
            const elDiseaseIntroPage = parsedHTML.getElementById('diseaseIntroPage');
            elDiseaseIntroPage.remove();

            // suggestion-statistics
            const elsDiseaseSuggestionStatistics = parsedHTML.getElementsByClassName('suggestion-statistics');
            Array.from(elsDiseaseSuggestionStatistics).forEach(el => el.remove());

            // suggestion-div
            const elsDiseaseSuggestionDiv = parsedHTML.getElementsByClassName('suggestion-div');
            Array.from(elsDiseaseSuggestionDiv).forEach(el => el.remove());

            // Remove double title
            const elDoubleTitle = parsedHTML.getElementsByClassName('panel-title');
            Array.from(elDoubleTitle).forEach(el => {
                Array.from(el.getElementsByTagName('span')).forEach(elSpan => {
                    elSpan.remove();
                });
            });

            // rsp-panel
            const elsRspPanel = parsedHTML.getElementsByClassName('rsp-panel');
            Array.from(elsRspPanel).forEach((el, index, arr) => {
                // remove original <hr /> tags from below titles
                Array.from(el.getElementsByTagName('hr')).forEach(hrEl => {
                    hrEl.remove();
                });

                // add new <hr /> tag at the end if it is not the last item
                const newHr = this.document.createElement('hr');

                if (index !== arr.length - 1) {
                    el.appendChild(newHr);
                }

                Array.from(el.getElementsByTagName('h2')).forEach(elH2 => {
                    if (elH2.id === 'diseaseGardAnswersSection') {
                        el.remove();
                    }
                });
            });

            // remove "Escuchar" text
            const elsRsbtn = parsedHTML.getElementsByClassName('rsbtn');
            Array.from(elsRsbtn).forEach(el => {
                // remove original <hr /> tags from below titles
                el.remove();
            });

            const elsLastUpdated = parsedHTML.querySelectorAll('div[align="right"]') as NodeListOf<HTMLAnchorElement>;
            Array.from(elsLastUpdated).forEach(el => {
                // remove original <hr /> tags from below titles
                el.removeAttribute('align');
                el.className = 'updated';
            });

            // Change hrefs
            const elA = parsedHTML.getElementsByTagName('a');
            Array.from(elA).forEach(el => {
                // parse link
                let url: URL | boolean = false;

                if (el.href) {
                    try {
                        url = new URL(el.href);
                    } catch (e) {
                        // trap;
                    }
                }

                // remove link
                if (el.href.indexOf(this.document.location.origin) === 0) {
                    // inside link, remove
                    el.outerHTML = el.text;
                } else if (url && (this.badDomains.includes(url.hostname) || this.badDomainsWww.includes(url.hostname))) {
                    // bad domain, remove link
                    if (el.text.includes(el.href)) {
                        // text includes bad link
                        el.remove();
                    } else {
                        // remove link, use text
                        el.outerHTML = el.text;
                    }
                } else if (this.badLinks.includes(el.href)) {
                    if (el.text.includes(el.href)) {
                        // text includes bad link
                        el.remove();
                    } else {
                        // remove link, use text
                        el.outerHTML = el.text;
                    }
                } else if (this.linkTransform.filter(link => link.currentLink === el.href).length) {
                    // bad links, change to new links and also change text displayed
                    const transform = this.linkTransform.filter(link => link.currentLink === el.href);
                    el.href = transform[0].replaceLink;
                    el.text = transform[0].replaceLink;
                } else if (el.href.indexOf('http') === 0) {
                    // outside link, nothing for now
                    el.target = '_blank';
                } else if (el.href.indexOf('@') > -1) {
                    // email, nothing for now
                } else if (!el.text) {
                    // empty, remove
                    el.remove();
                } else {
                    // const text = el.text;
                }
            });

            // find all links with target and ensure rel
            Array.from(parsedHTML.querySelectorAll('a:not([rel])[target]') as NodeListOf<HTMLAnchorElement>).forEach(link => {
                link.setAttribute('rel', 'noopener noreferrer');
            });

            // find all http links and add icon
            Array.from(parsedHTML.querySelectorAll('a[href]') as NodeListOf<HTMLAnchorElement>).forEach(link => {
                const isInternal = ExternalLinkDirective.isLinkInternal(link.href, this.document.location.host);
                if (!isInternal) {
                    const icon = this.renderer.createElement('i') as HTMLElement;
                    this.renderer.addClass(icon, 'icon-gard-open-link');
                    this.renderer.appendChild(link, icon);
                }
            });

            // Back to string
            this.spanishContent = parsedHTML.documentElement.innerHTML;
        });
    }

    onClick(event: MouseEvent) {
        let link: HTMLAnchorElement;
        if ((event.target as HTMLAnchorElement).tagName === 'A') {
            link = event.target as HTMLAnchorElement;
        } else if ((event.target as HTMLElement).tagName === 'I' && (event.target as HTMLElement).className === 'icon-gard-open-link') {
            link = (event.target as HTMLElement).parentElement as HTMLAnchorElement;
        }

        if (link) {
            if (!ExternalLinkDirective.isLinkInternal(link.href, this.document.location.host)) {
                event.preventDefault();
                event.stopPropagation();
                if (confirm(POPUP_TEXT)) {
                    const tab = window.open(link.href, '_blank');
                    // tab.opener = null;
                }
            }
        }
    }
}
