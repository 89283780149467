<app-top-navigation></app-top-navigation>
<main-banner [title]="headerArticle.title" [description]="headerArticle.bodyText"></main-banner>

<ng-template #filterTemplate let-modal="modal">
    <div class="mobile-filter-template position-absolute top-0 w-100">
        <div class="px-2 px-sm-4 pt-4 position-relative flex-grow main-content">
            <div class="position-relative flex-grow main-content">
                <div class="mx-auto">
                    <div class="filter-by">Filter by</div>

                    <filter-by-letter (selectedLetter)="letterWasSelectedMobile($event)" [activeLetter]="mobileLetterPendingApply"></filter-by-letter>
                    <filter-by-category (selectedCategories)="categoriesWereSelectedMobile($event)" [searchFilter]="filters"></filter-by-category>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container-fluid gray-bg py-3 d-md-none">
    <div class="row justify-content-center mobile-search">
        <div class="col-8">
            <div class="search-input d-flex align-items-center position-relative me-2">
                <label for="searchInput-sm"><i class="icon-gard-search fs-5" role="button"></i></label>
                <!-- <input id="searchInput-sm" type="text" placeholder="Search" (keyup)="updateResults($event.target.value)" autocomplete="off" /> -->
                <input #searchInput id="searchInput-sm" type="text" placeholder="Search by Name and Synonym" [formControl]="searchControl" autocomplete="off" />
            </div>
        </div>
        <div class="col-2">
            <button class="btn text-truncate sort float-right" (click)="toggleSort()">
                <span class="d-none d-xl-inline-block me-1">Sort by: Name</span>
                <i [ngClass]="[sort.sortEnabled ? (sort.asc ? 'icon-gard-sort-up' : 'icon-gard-sort-down') : 'icon-gard-sort']"></i>
            </button>
        </div>
        <div class="col-2">
            <button class="btn text-truncate sort float-right d-md-none btn-filter" (click)="openFilterModal()">
                <i class="icon-gard-filter"></i>
            </button>
        </div>
    </div>
</div>

<div class="container-fluid mt-2 py-2 d-md-none" *ngIf="filters.length">
    <div class="row d-md-none justify-content-center mobile-filters">
        <div class="col">
            <div class="row">
                <div class="col-6 fs-14">Filtered by</div>
                <div class="col-6 align-right">
                    <span class="clear-all fs-14 me-2" (click)="clearAllFilters()">Clear All</span>
                </div>
            </div>
            <div class="row pt-1">
                <div class="col">
                    <filter-bubbles *ngFor="let filter of filters" [filter]="filter" (filterEvent)="filterEvent($event)"></filter-bubbles>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pt-3 mb-0 gray-bg">
    <div class="row">
        <div class="col-12">
            <div class="container-xxxl">
                <div class="row d-flex justify-content-center">
                    <div class="d-none d-md-block col-md-5 col-lg-4 col-xl-3 left-menu">
                        <div class="keyword">Keyword</div>
                        <div class="search-input d-flex align-items-center position-relative me-2">
                            <label for="searchInput"><i class="icon-gard-search fs-5" role="button"></i></label>
                            <input #searchInput id="searchInput" type="text" placeholder="Search by Name and Synonym" [formControl]="searchControl" autocomplete="off" />
                        </div>

                        <div>
                            <div class="filter-by">Filter by</div>

                            <filter-by-letter (selectedLetter)="letterWasSelected($event)" [activeLetter]="filterLetter"></filter-by-letter>
                            <filter-by-category (selectedCategories)="categoriesWereSelected($event)" [searchFilter]="filters"></filter-by-category>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row mb-1 mt-2 mt-md-0">
                            <div class="col">
                                <p class="fs-18">{{ currentlyShowing }}</p>
                            </div>
                            <div class="col d-none d-md-block">
                                <button class="btn text-truncate sort float-right" (click)="toggleSort()">
                                    <span class="d-none d-xl-inline-block me-1">Sort by: Name</span>
                                    <i [ngClass]="[sort.sortEnabled ? (sort.asc ? 'icon-gard-sort-up' : 'icon-gard-sort-down') : 'icon-gard-sort']"></i>
                                </button>

                                <button class="btn text-truncate sort float-right d-md-none btn-filter" (click)="toggleSort()">
                                    <i class="icon-gard-filter"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row d-none d-md-block mb-2" *ngIf="filters.length">
                            <div class="col clearfix">
                                <filter-bubbles *ngFor="let filter of filters" [filter]="filter" (filterEvent)="filterEvent($event)"></filter-bubbles>
                                <span class="clear-all fs-14 ms-2 mt-2" (click)="clearAllFilters()">Clear All</span>
                            </div>
                        </div>

                        <div class="results pt-md-2">
                            <div *ngIf="displayedDiseases.length === 0">
                                <div class="no-results">
                                    Sorry, we couldn't find a match<span *ngIf="searchControl.value"> for '{{ searchControl.value }}'</span>. Please try another search term.
                                </div>
                            </div>
                            <div *ngIf="displayedDiseases.length > 0">
                                <div class="result mb-5 cursor-pointer" *ngFor="let disease of displayedDiseases; let first = first">
                                    <a [href]="generateDiseaseURL(disease)" class="text-reset">
                                        <div class="box p-4">
                                            <h5 class="text-green fs-md-3">{{ disease.name }}</h5>
                                            <p class="mb-0" *ngIf="disease.synonymString">Other names: {{ disease.synonymString }}</p>
                                        </div>
                                        <div class="button border-0 px-4 py-3 text-start text-white" [id]="first ? 'first' : ''">
                                            Learn More <i class="icon-gard-arrow-right"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex d-xl-block justify-content-between align-items-center pb-5">
                            <div class="page-navigation d-flex justify-content-between justify-content-xl-end flex-grow" *ngIf="pages.length > 1">
                                <a
                                    [hidden]="!showPrevButton"
                                    [routerLink]="diseasePagesUrl"
                                    [queryParams]="{ page: currentPage - 1, search: searchControl.value }"
                                    (click)="updateCurrentPage(currentPage - 1, searchControl.value)"
                                    ><i class="icon-gard-arrow-left"></i
                                ></a>
                                <div class="page" [ngClass]="{ current: page === currentPage }" *ngFor="let page of pages; index as i">
                                    <span *ngIf="page === -1" class="align-self-end dots-start">...</span>
                                    <a
                                        *ngIf="page !== -1"
                                        [routerLink]="diseasePagesUrl"
                                        [queryParams]="{ page: page, search: searchControl.value }"
                                        (click)="updateCurrentPage(page, searchControl.value)"
                                        >{{ page }}</a
                                    >
                                </div>
                                <a
                                    [hidden]="!showNextButton"
                                    [routerLink]="diseasePagesUrl"
                                    [queryParams]="{ search: searchControl.value, page: currentPage + 1 }"
                                    (click)="updateCurrentPage(currentPage + 1, searchControl.value)"
                                    ><i class="icon-gard-arrow-right"></i
                                ></a>
                            </div>
                            <p class="d-xl-none m-0" *ngIf="displayedDiseases.length > 0">{{ totalPages }} Pages</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
