export class SymptomSearchResult {
    public current = false;
    public get synonyms() {
        return this.pSynonyms;
    }
    public get filterText() {
        return this.pFilterText;
    }
    public set filterText(value: string) {
        this.pFilterText = value;
        this.pSynonyms = this.allSynonyms.filter(synonym => synonym.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1);
    }
    private pFilterText = '';
    private pSynonyms: string[] = [];

    /**
     * @param title - slide title
     * @param slideID - slide id
     * @param synonym - single string containing all synonyms separated by a semicolon
     * @param allSynonyms - array of all synonyms
     */
    constructor(public title: string, public slideID: number, public synonym: string, public allSynonyms: string[]) {}
}
