<app-top-navigation></app-top-navigation>

<main-banner [title]="patientResources.title" [description]="patientResources.bodyText"></main-banner>

<!-- C1 -->
<!-- <living-with-intro [id]="sections.livingIntro.id"></living-with-intro> -->

<!-- C3 -->
<c4-suggested-resources [id]="sections.patientResources.id"></c4-suggested-resources>

<!-- C4 -->
<app-caregiver-resources [id]="sections.caregiverResources.id"></app-caregiver-resources>

<!-- C5 -->
<app-living-with-informed-decisions [id]="sections.decisions.id"></app-living-with-informed-decisions>

<app-footer></app-footer>
