<div *ngIf="!errorData">
    <h1>Loading Error Data...</h1>
</div>
<div *ngIf="errorData">
    <h1>Data Errors</h1>
    <div *ngFor="let errorType of errorTypes">
        <h3>Errors ({{ errorType }})</h3>
        <ol>
            <li *ngFor="let errorEntry of errorData[errorType]">
                {{ errorEntry.errorMessage }}
                <br />
                Callstack:
                <br />
                {{ errorEntry.callstack }}
            </li>
        </ol>
    </div>
</div>
