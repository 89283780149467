import { Component } from '@angular/core';
import { DiseaseService } from 'src/app/services/disease.service';
import { ABOUT_SECTIONS } from 'shared/constants';
import { Disease } from 'shared/types';

@Component({
    selector: 'app-disease-about',
    templateUrl: './disease-about.component.html',
    styleUrls: ['./disease-about.component.scss'],
})
export class DiseaseAboutComponent {
    public sections = ABOUT_SECTIONS;
    public disease: Disease;

    constructor(diseaseService: DiseaseService) {
        this.disease = diseaseService.disease;
    }
}
