<div class="container-fluid">
    <div class="container-xxxl disease-page-top-element-padding">
        <div class="row pt-5">
            <div class="col">
                <app-bar-header textHeader="Disease at a Glance" theme="blue"> </app-bar-header>
            </div>
        </div>
        <div class="row py-5">
            <div class="col-12 col-xl-6 left-side">
                <app-disease-at-a-glance-summary></app-disease-at-a-glance-summary>
            </div>

            <div class="col-12 col-xl-6 right-side">
                <disease-at-a-glance-snippets></disease-at-a-glance-snippets>
            </div>
        </div>
    </div>
</div>
