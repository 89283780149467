import { Component, ViewEncapsulation } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { TopTab } from 'src/app/components/common/multitab/multitab.types';
import { PanelType } from 'src/app/components/common/multitab/multitab.types';

@Component({
    selector: 'app-living-with-informed-decisions',
    templateUrl: './living-with-informed-decisions.component.html',
    styleUrls: ['./living-with-informed-decisions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LivingWithInformedDecisionsComponent {
    parentKA: Article;

    resourcesforYourHealthCareDecisions: Article;

    tabs = [
        {
            title: 'Resources for Your Health Care Decisions',
            sectionHeader: '',
            type: PanelType.normal,
            items: [],
        },
    ] as TopTab[];

    constructor(private articleService: ArticleService) {
        this.parentKA = articleService.getOne(Page.livingWith, Section.informedDecisions, SubSection.parent, undefined, undefined, false);
        this.resourcesforYourHealthCareDecisions = articleService.getOne(Page.livingWith, Section.informedDecisions, SubSection.sectionHeader);

        this.tabs[0].title = this.resourcesforYourHealthCareDecisions.title;

        this.populateButtonKAs();
    }

    private populateButtonKAs() {
        /* Complimentary Care */
        const complimentaryCareArticles = this.articleService.getArticles(Page.livingWith, Section.informedDecisions, SubSection.complementaryCare, undefined, undefined, false);
        const complimentaryParent = complimentaryCareArticles.shift();

        this.tabs[0].items.push({ ...complimentaryParent, panelTitle: complimentaryParent.title, additionalArticles: complimentaryCareArticles });

        /* Transitioning Care */
        const transitioningCareArticles = this.articleService.getArticles(Page.livingWith, Section.informedDecisions, SubSection.transitioningCare, undefined, undefined, false);
        const transitionParent = transitioningCareArticles.shift();

        this.tabs[0].items.push({ ...transitionParent, panelTitle: transitionParent.title, additionalArticles: transitioningCareArticles });

        /*  Pallative and Hospice */
        const hospiceArticles = this.articleService.getArticles(Page.livingWith, Section.informedDecisions, SubSection.palliativeHospice, undefined, undefined, false);
        const hospiceParent = hospiceArticles.shift();
        this.tabs[0].items.push({ ...hospiceParent, panelTitle: hospiceParent.title, additionalArticles: hospiceArticles });

        /* Expanded Access */
        // const expandedAccessArticles = this.articleService.getArticles(Page.livingWith, Section.informedDecisions, Subsection.expandedAccess, undefined, undefined, false);
        // const expandedParent = expandedAccessArticles.shift();

        // this.tabs[0].items.push({ ...expandedParent, panelTitle: expandedParent?.title, additionalArticles: expandedAccessArticles });
    }
}
