import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { encodeName } from 'shared/utils/encode-name';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
    selector: 'gard-id',
    template: '',
})
export class GardIdComponent {
    // get GARD ID from URL part after last slash
    constructor(diseaseService: DiseaseService, router: Router) {
        const url: string = window.location.href;
        const gardId: number = Number(url.substring(url.lastIndexOf('/') + 1));

        // look up disease from GARD ID (gardId) using the disease service
        diseaseService.fetch(gardId).subscribe(disease => {
            // Calc new url
            const newUrl = `/diseases/${gardId}/${encodeName(disease.name)}`;

            // navigate to disease page
            router.navigate([newUrl]);
        });
    }
}
