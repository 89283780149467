import { AfterViewInit } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from 'shared/types';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';

@Component({
    selector: 'generic',
    templateUrl: './generic.component.html',
    styleUrls: ['./generic.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GenericPageComponent implements AfterViewInit {
    public article: Article;

    constructor(route: ActivatedRoute) {
        this.article = route.snapshot.data.article;
    }

    ngAfterViewInit(): void {
        // Add on click methods to links that are not .gov
        const links = Object.values(document.getElementsByTagName('a')).filter(link => !link.href.includes('.gov') && !link.baseURI.includes(link.hostname));
        links.forEach(linkEl => {
            const href = linkEl.href;
            linkEl.addEventListener('click', (ev: MouseEvent) => {
                ev.preventDefault();
                ev.stopPropagation();
                if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
                    const tab = window.open(href, '_blank');
                    tab.opener = null;
                }
            });
        });
    }
}
