import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';

@Component({
    selector: 'care-coordination',
    templateUrl: './care-coordination.component.html',
    styleUrls: ['./care-coordination.component.scss'],
})
export class CareCoordinationComponent {
    mainKA: Article;
    coordinateKA: Article;
    multidisciplinaryKA: Article;

    constructor(articleService: ArticleService) {
        this.mainKA = articleService.getOne(Page.diagnosis, Section.coordinateMedicalTeam, SubSection.parent, undefined, undefined, false);

        this.coordinateKA = articleService.getOne(Page.diagnosis, Section.coordinateMedicalTeam, SubSection.coordinateCare, undefined, undefined, false);

        this.multidisciplinaryKA = articleService.getOne(Page.diagnosis, Section.coordinateMedicalTeam, SubSection.careCenters, undefined, undefined, false);
    }
}
