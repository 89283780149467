import { encodeName } from '../../shared/utils/encode-name';
import { PATHS } from '../../shared/paths';
import { cleanDiseaseName } from './clean-disease-name';

export interface PathDisease {
  id: number;
  name: string;
}

export const getDiseasePath = (disease: PathDisease, subsection = '') => {
  const name = cleanDiseaseName(disease.name);

  const path = [PATHS.diseases, String(disease.id), encodeName(name).toLowerCase()];

  if (subsection) {
    path.push(subsection);
  }

  return path;
};
