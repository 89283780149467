<div class="disease-section-contact-us-wrapper container-fluid position-relative py-5">
    <!-- Desktop Background Circles -->
    <div class="d-none d-lg-block">
        <div class="background-gradient">
            <div class="circle-top-left-transparent"></div>
            <div class="circle-top-transparent"></div>
            <div class="circle-small-left"></div>
            <div class="circle-bottom-left"></div>
            <div class="circle-bottom-transparent"></div>
            <div class="circle-top-right-transparent"></div>
            <div class="circle-small-right"></div>
        </div>
    </div>

    <!-- Mobile Background Circles -->
    <div class="d-lg-none">
        <div class="background-gradient">
            <div class="circle-top-left-transparent"></div>
            <div class="circle-top-transparent"></div>
        </div>
    </div>

    <div class="container-xxxl">
        <!-- Desktop -->
        <div class="desktop d-none d-lg-block py-2">
            <div class="row py-2">
                <div class="left-side col-6">
                    <div class="mb-4 pb-2">
                        <div class="gard-logo d-flex align-items-center mb-4 pb-2">
                            <span class="fs-1 fw-500 m-0 p-0 me-2 lh-1">GARD</span>
                            <span class="fs-7 m-0 p-0">Genetic and Rare Diseases <br />Information Center</span>
                        </div>
                        <div class="description text-white fs-6" [innerHTML]="leftArticle.bodyText"></div>
                    </div>
                </div>
                <div class="right-side col-6">
                    <div class="row mt-4">
                        <div class="col-5">
                            <div class="phone">
                                <a [href]="'tel:' + contactPhone.bodyText" role="button" aria-label="Call GARD">
                                    {{ contactPhone.bodyText }}
                                </a>
                            </div>
                        </div>
                        <div class="col-7 toll-free-text">
                            <div class="description text-white fs-6" [innerHTML]="contactArticle.bodyText"></div>
                        </div>
                    </div>

                    <div class="contact-button-area">
                        <div class="row">
                            <div class="col-5">
                                <div class="button-wrapper">
                                    <a [href]="contactButtonUrl" target="_blank">
                                        <div class="button max fs-18 fw-500 text-white bg-transparent">
                                            {{ contactCenter.linkText }}
                                            <i class="icon-gard-arrow-right ms-2"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="col-7 text-white fs-6" [innerHtml]="contactCenter.bodyText"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div class="mobile d-lg-none">
            <div class="pt-5">
                <div class="mx-auto width-fit-content">
                    <div class="gard-logo d-flex align-items-center mb-4 pb-2">
                        <span class="fs-1 fw-500 m-0 p-0 me-2 lh-1">GARD</span>
                        <span class="fs-7 m-0 p-0">Genetic and Rare Diseases <br />Information Center</span>
                    </div>
                </div>

                <div class="description text-white fs-6" [innerHTML]="leftArticle.bodyText"></div>

                <div class="phone mt-4">
                    <a [href]="'tel:' + contactPhone.bodyText" role="button" aria-label="Call GARD">
                        {{ contactPhone.bodyText }}
                    </a>
                </div>
                <div class="mt-4 contact-button-area">
                    <div class="text-white fs-18" [innerHTML]="contactArticle.bodyText"></div>
                </div>

                <div class="mt-4 pt-2">
                    <div class="mx-auto width-fit-content">
                        <a [href]="contactButtonUrl" target="_blank">
                            <div class="button max fs-18 fw-500 text-white bg-transparent">{{ contactCenter.linkText }} <i class="icon-gard-arrow-right ms-2"></i></div>
                        </a>
                    </div>
                </div>
                <div class="mt-4 pt-2 text-white text-center" [innerHtml]="contactCenter.bodyText"></div>
            </div>
        </div>
    </div>
</div>
