import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { PATHS } from 'shared/paths';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';

@Component({
    selector: 'participating-in-clinical-studies',
    templateUrl: './participating-in-clinical-studies.component.html',
    styleUrls: ['./participating-in-clinical-studies.component.scss'],
})
export class ParticipatingInClinicalStudiesComponent {
    imgPath = PATHS.articleImages;
    mainKA: Article;
    imgUrl: string;

    constructor(articleService: ArticleService) {
        this.mainKA = articleService.getOne(Page.about, Section.research, SubSection.intro, undefined, undefined, false);
        this.imgUrl = this.imgPath + this.mainKA.images[0]?.url + '-1600.webp';
    }
}
