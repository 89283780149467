import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { encodeName } from 'shared/utils/encode-name';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent {
    constructor(diseaseService: DiseaseService, router: Router) {
        // get 'GARD_ID' from URL paramerters

        const url: string = window.location.href;

        // do we have a parameter?
        if (url.indexOf('?') > 0) {
            // redirect to gard page
            let gardId: number;

            const params: string = window.location.href.split('?')[1];

            if (url.indexOf('&')) {
                // TODO: later
            }

            const idType: string = params.split('=')[0];

            if (idType.toLocaleLowerCase() === 'GARD_ID'.toLocaleLowerCase()) {
                gardId = Number(params.split('=')[1]);

                // look up disease from GARD ID (gardId) using the disease service
                diseaseService.fetch(gardId).subscribe(disease => {
                    // Calc new url
                    const newUrl = `/diseases/${gardId}/${encodeName(disease.name)}`;

                    // navigate to disease page
                    router.navigate([newUrl]);
                });
            }
        }
    }
}
