<div class="snippets-wrapper">
    <h3 class="fs-5 fs-md-3 text-maroon fw-bold" [innerHtml]="mainTitle"></h3>
    <p class="fs-6 fs-md-18" [innerHtml]="mainSummary"></p>

    <ul class="ps-5">
        <li class="fs-6 fs-md-18 text-maroon" *ngIf="populationEstimateTitle && populationEstimateBody">
            <div class="li-wrap">
                <strong class="text-maroon pe-1" [innerHtml]="populationEstimateTitle"></strong>
                <span class="text-bluegray">{{ populationEstimateBody }}</span>
                <span class="text-bluegray ps-1 text-nowrap">
                    <span class="me-xl-2" [innerHtml]="populationEstimateBodyLastWord"> </span>
                    <app-tooltip [text]="populationEstimateTooltip" mobileIconClass="icon-small"></app-tooltip>
                </span>
            </div>
        </li>

        <li class="fs-6 fs-md-18 text-maroon" *ngIf="ageAtOnsetTitle && ageAtOnsetBody">
            <div class="li-wrap">
                <strong class="text-maroon pe-1" [innerHtml]="ageAtOnsetTitle"></strong>
                <span class="text-bluegray">{{ ageAtOnsetBody }}</span>
            </div>
        </li>

        <li class="fs-6 fs-md-18 text-maroon" *ngIf="causeArticleTitle && causeArticleBody">
            <div class="li-wrap">
                <strong class="text-maroon pe-1" [innerHtml]="causeArticleTitle"></strong>
                <span class="text-bluegray">{{ causeArticleBody }}</span>
            </div>
        </li>

        <li class="fs-6 fs-md-18 text-maroon" *ngIf="patientOrgTitle && patientOrgBody">
            <div class="li-wrap">
                <strong class="text-maroon pe-1" [innerHtml]="patientOrgTitle"></strong>
                <span class="text-bluegray">{{ patientOrgBody }}</span>
            </div>
        </li>

        <li class="fs-6 fs-md-18 text-maroon" *ngIf="newbornTitle && newbornBody">
            <div class="li-wrap">
                <strong class="text-maroon pe-1" [innerHtml]="newbornTitle"></strong>
                <span class="text-bluegray">{{ newbornBody }}</span>
                <span class="text-bluegray ps-1 text-nowrap">
                    <span class="me-xl-2" [innerHtml]="newbornBodyLastWord"> </span>
                    <app-tooltip [text]="newbornTooltip" mobileIconClass="icon-small"></app-tooltip>
                </span>
            </div>
        </li>

        <li class="fs-6 fs-md-18 text-maroon" *ngIf="categories.length">
            <div class="li-wrap">
                <strong class="text-maroon pe-1" [innerHtml]="categoriesTitle"></strong>
                <span *ngFor="let category of categories" class="text-bluegray">
                    <span class="me-1">{{ category.nameCurated | titleCaseWord }}</span>
                    <app-tooltip [text]="category.textSnippet" mobileIconClass="icon-small"></app-tooltip>
                </span>
            </div>
        </li>
    </ul>
</div>
