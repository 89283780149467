import { Component, Input } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { EVIDENCE_TYPES, EXTERNAL_IDENTIFIERS } from 'shared/constants';
import { Evidence } from 'shared/salesforce-types';
import { Section as SECTION, SubSection as SUBSECTION, Page as TAB, SubSubsection as SUBSUBSECTION } from 'shared/location-enums';
import { Article, ExternalIdentifier } from 'shared/types';

@Component({
    selector: 'app-disease-at-a-glance-summary',
    templateUrl: './disease-at-a-glance-summary.component.html',
    styleUrls: ['./disease-at-a-glance-summary.component.scss'],
})
export class DiseaseAtAGlanceSummaryComponent {
    textHeader: string;
    public summaryText: string;

    // More Info KAs
    infoTitle: string;

    // Orphanet
    showOrphanet: boolean;
    articleOrphanet: Article;
    linkOrphanet: string;

    // Rare Source
    showRareSource: boolean;
    articleRareSource: Article;
    linkRareSource: string;

    // Gene Source
    showGeneSource: boolean;
    articleGeneSource: Article;
    linkGeneSource: string;

    // Biomedical Data Translator
    showBiomedicalDataTranslator: boolean;
    articleBiomedicalDataTranslator: Article;
    linkBiomedicalDataTranslator: string;

    // default to null
    showNullInfo = true;
    infoNullArticle: Article;

    constructor(private diseaseService: DiseaseService, private articleService: ArticleService) {
        // check if this is the null version
        const isNull = DiseaseAtAGlanceSummaryComponent.isNull(diseaseService);

        // Title
        const headerArticle = articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.summary, undefined, undefined, isNull);
        if (headerArticle) {
            this.textHeader = headerArticle.title;
        }

        // Summary
        // if we don't have description, load in the Null Article for summary section
        if (isNull) {
            this.summaryText = articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.summary, undefined, undefined, isNull).bodyText;
        } else {
            this.summaryText = diseaseService.disease.clinicalDescription;
        }

        // More info KAs
        const infoParentKA = articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.moreInfo, undefined, undefined, isNull, SUBSUBSECTION.parent);

        this.infoTitle = infoParentKA.title.replace('${DiseaseName}', this.diseaseService.disease.name);

        // Orphanet
        const evidenceOrphanets: ExternalIdentifier[] = this.diseaseService.disease.externalIdentifiers.filter(
            (externalIdentifier: ExternalIdentifier) => externalIdentifier.source === EXTERNAL_IDENTIFIERS.orphanet,
        );

        if (evidenceOrphanets.length === 1) {
            // take first matching result
            const [evidenceOrphanet] = evidenceOrphanets;

            this.showOrphanet = true;
            this.articleOrphanet = this.articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.moreInfo, 1, undefined, false, SUBSUBSECTION.resources);

            this.linkOrphanet = evidenceOrphanet.url;
            this.showNullInfo = false;
        }

        // RareSource
        const evidenceRareSources: Evidence[] = this.diseaseService.disease.evidence.filter(evidence => evidence.type === EVIDENCE_TYPES.rareSource);

        if (evidenceRareSources.length === 1) {
            // take first matching result
            const [evidenceRareSource] = evidenceRareSources;

            this.showRareSource = true;
            this.articleRareSource = this.articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.moreInfo, 2, undefined, false, SUBSUBSECTION.resources);

            this.linkRareSource = evidenceRareSource.url;
            this.showNullInfo = false;
        }

        // GeneSource
        const evidenceGeneSources: Evidence[] = this.diseaseService.disease.evidence.filter(evidence => evidence.type === EVIDENCE_TYPES.geneReview);

        if (evidenceGeneSources.length === 1) {
            // take first matching result
            // We're not actually using this data, just checking if it exists, then have custom logic
            // const [evidenceGeneSource] = evidenceGeneSources;

            this.showGeneSource = true;
            this.articleGeneSource = this.articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.moreInfo, 3, undefined, false, SUBSUBSECTION.resources);

            this.linkGeneSource = evidenceGeneSources[0].url;
            this.showNullInfo = false;
        }

        // Biomedical Data Translator
        const evidenceBiomedicalDataTranslator: Evidence[] = this.diseaseService.disease.evidence.filter(evidence => evidence.type === EVIDENCE_TYPES.biomedicalDataTranslator);

        if (evidenceBiomedicalDataTranslator.length === 1) {
            // take first matching result
            // We're not actually using this data, just checking if it exists, then have custom logic

            this.showBiomedicalDataTranslator = true;
            // eslint-disable-next-line max-len
            this.articleBiomedicalDataTranslator = this.articleService.getOne(
                TAB.about,
                SECTION.diseaseAtAGlance,
                SUBSECTION.moreInfo,
                4,
                undefined,
                false,
                SUBSUBSECTION.resources,
            );

            this.linkBiomedicalDataTranslator = evidenceBiomedicalDataTranslator[0].url;
            this.showNullInfo = false;
        }

        // Get Null KA
        if (this.showNullInfo) {
            this.infoNullArticle = this.articleService.getOne(TAB.about, SECTION.diseaseAtAGlance, SUBSECTION.moreInfo, undefined, undefined, true, SUBSUBSECTION.parent);
        }
    }

    static isNull(diseaseService: DiseaseService): boolean {
        return !diseaseService.disease.clinicalDescription;
    }
}
