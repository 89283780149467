<div class="position-relative">
    <div class="container-fluid bg-lightblue">
        <div class="container-xxxl py-5 py-lg-4">
            <div class="my-0 my-lg-5 py-lg-2 d-block d-lg-flex align-items-center justify-content-between">
                <div class="d-none d-lg-block col-lg-4 left-part">
                    <img [src]="imageUrl" [alt]="contentArticle.images[0]?.altText" />
                </div>

                <div class="col-lg-7 right-part mb-lg-0">
                    <app-bar-header [textHeader]="contentArticle.title" theme="blue"></app-bar-header>
                    <div class="py-lg-4 fs-md-18" [innerHTML]="contentArticle.bodyText"></div>
                    <div class="d-lg-none py-4 mb-3 mb-lg-0">
                        <img [src]="imageUrl" [alt]="contentArticle.images[0]?.altText" />
                    </div>
                    <div>
                        <a class="btn btn-arrow text-white btn-green blue-effect" [routerLink]="aboutUrl" routerLinkActive="active">
                            <span [innerHTML]="buttonArticle.title"></span>
                            <i class="icon-gard-arrow-right ms-1"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
