import { ElementRef, OnDestroy, TemplateRef } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Article, Categories, CategoriesWithParent, Disease, DiseaseTrimmed } from 'shared/types';
import { Page, Section } from 'shared/location-enums';
import { DiseaseService } from 'src/app/services/disease.service';
import { getDiseasePath } from 'shared/utils/get-disease-path';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ArticleService } from 'src/app/services/article.service';
import { Language } from 'shared/salesforce-types';
import { PATHS } from '../../../../shared/paths';
import { SearchFilter } from './search-filter';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../services/modal.service';
import { DiseaseWithSynonymString, diseasesToDiseasesWithSynonymString, globalDiseaseSearch } from '../common/disease-search';

@Component({
    templateUrl: './search-result-page.component.html',
    styleUrls: ['./search-result-page.component.scss'],
})
export class SearchResultPageComponent implements OnDestroy {
    @ViewChild('filterTemplate') filterTemplate: TemplateRef<ElementRef>;
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    public sort = { sortEnabled: false, asc: false };
    public currentPage = 1; // unlike normal arrays, we start with 1 here
    public pages: number[] = [];
    public totalPages: number;
    public currentlyShowing = '';

    public showNextButton: boolean;
    public showPrevButton: boolean;
    public searchControl = this.formBuilder.control('');
    public headerArticle: Article;
    public firstSearchResult: HTMLElement;

    public filters: SearchFilter[] = [];
    public filterLetter = '';
    public mobileLetter = '';
    public mobileLetterPendingApply = '';
    public filterCategories: string[] = [];
    public mobileCategories: string[] = [];
    private modal: NgbModalRef;

    private maxItemsPerPage = 10;
    private maxShownPages = 5;
    public displayedDiseases: DiseaseWithSynonymString[] = [];
    private subscription = new Subscription();

    diseasePagesUrl: string = '';

    constructor(
        articleService: ArticleService,
        public diseaseService: DiseaseService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private modalService: ModalService,
        private location: Location,
    ) {
        this.headerArticle = articleService.getOne(Page.browse, Section.header);

        // Add synonymString
        // dz.synonymString = (dz.synonyms || []).join(', ');

        activatedRoute?.queryParams?.subscribe(param => {
            // Set current page if specified in query
            this.currentPage = +param?.page || 1;

            // Set search value if specified in query
            const search = param?.search || '';
            if (search) {
                this.searchControl.setValue(search, { emitEvent: false });
            }

            // Set filter values if specified in query
            const categories = param?.category || '';
            if (categories) {
                this.categoriesWereSelected(categories.split(';'), false);
            }

            // Set letter filter if specified in query
            const letter = param?.letter || ('' as string);
            if (letter) {
                this.letterWasSelected(letter, false);
            }

            this.updateResults();
            this.updateQueryParams();
        });

        if (activatedRoute.snapshot.data.language === Language.Es) {
            this.diseasePagesUrl = '/' + PATHS[Language.Es].diseases;
        } else {
            this.diseasePagesUrl = '/' + PATHS[Language.En].diseases;
        }

        this.subscription.add(
            this.searchControl.valueChanges.subscribe(() => {
                this.currentPage = 1;
                this.updateQueryParams();
                this.updateResults();
            }),
        );
    }

    static buildPagination(totalPages: number, maxShownPages: number, currentPage: number): number[] {
        const results = [];
        const pad = Math.floor(maxShownPages / 2);

        // -1 is for ellipsis

        if (totalPages <= maxShownPages) {
            // when there are less pages than max shown all pages
            for (let i = 1; i <= totalPages; i++) {
                results.push(i);
            }
        } else {
            // add first page when needed
            if (currentPage >= maxShownPages - 1) {
                results.push(1);
            }

            // add first ellipsis when needed
            if (currentPage >= maxShownPages) {
                results.push(-1);
            }

            // calculate start and end page in the middle
            const min = Math.max(1, Math.min(totalPages - maxShownPages + 1, currentPage - pad));
            const max = Math.min(totalPages, Math.max(currentPage + pad, maxShownPages));

            // add pages
            for (let i = min; i <= max; i++) {
                results.push(i);
            }

            // add last ellipsis when needed
            if (currentPage <= totalPages - maxShownPages + 1) {
                results.push(-1);
            }

            // add last page when needed
            if (currentPage <= totalPages - maxShownPages + 2) {
                results.push(totalPages);
            }
        }
        return results;
    }

    letterWasSelectedMobile(filterByLetter: string) {
        this.mobileLetterPendingApply = filterByLetter;
    }

    categoriesWereSelectedMobile(categories: string[]) {
        this.mobileCategories = categories;
    }

    openFilterModal() {
        this.modal = this.modalService.open(this.filterTemplate, {
            customRightModal: true,
            footerShow: true,
            footerFunctionsApply: this.applyFilter.bind(this),
            footerFunctionsClear: this.clearFilter.bind(this),
        });
    }

    clearFilter() {
        // reset letter for next open
        this.mobileLetterPendingApply = this.filterLetter;

        // And close
        this.modal.close();
    }

    applyFilter() {
        // Handle Categories
        this.categoriesWereSelected(this.mobileCategories);

        // Handle letter
        this.mobileLetter = this.mobileLetterPendingApply;
        this.letterWasSelected(this.mobileLetter);

        // And close
        this.modal.close();
    }

    letterWasSelected(filterByLetter: string, resetPage = true) {
        if (resetPage) {
            // Reset current page before filtering
            this.currentPage = 1;
        }

        if (filterByLetter) {
            // see if we already have a letter filter
            const letterFilters = this.filters.filter((searchFilter: SearchFilter) => searchFilter.filterType === 'letter');

            // holder for out letter filter
            let letterFilter: SearchFilter;

            if (letterFilters.length > 1) {
                throw new Error(`More than one letter filter set`);
            } else if (letterFilters.length === 1) {
                letterFilter = letterFilters[0];
            } else {
                // create new filter
                letterFilter = new SearchFilter('letter', '');
                this.filters.push(letterFilter);
            }

            letterFilter.filterText = filterByLetter.toUpperCase();
            this.filterLetter = letterFilter.filterText;
            this.mobileLetterPendingApply = this.filterLetter;
            this.updateQueryParams();
        }

        // update search results
        this.resetTabFocus();
        this.updateResults();
    }

    categoriesWereSelected(categoriesSelected: string[], resetPage = true) {
        if (resetPage) {
            this.currentPage = 1;
        }

        const categoryFilters = this.filters.filter((searchFilter: SearchFilter) => searchFilter.filterType === 'category');

        // loop over each category we should have
        categoriesSelected.forEach(category => {
            if (categoryFilters.filter(cat => category === cat.filterText).length) {
                // is included
            } else {
                // is missing, need to create
                const newCategoryFilter = new SearchFilter('category', category);
                this.filters.push(newCategoryFilter);
            }
        });

        // now make sure we don't have an extra

        this.filters = this.filters.map((searchFilter: SearchFilter) => {
            // first check if it is category type
            if (searchFilter.filterType === 'category') {
                if (!categoriesSelected.includes(searchFilter.filterText)) {
                    // the global filter array contains a filter that is no longer being used
                    // mark it for removal
                    searchFilter.enabled = false;
                }
            }

            this.updateQueryParams();
            return searchFilter;
        });

        // update search results
        this.resetTabFocus();
        this.updateResults();
    }

    filterEvent(clearedSearchFilter: SearchFilter) {
        this.currentPage = 1;
        this.filters = this.filters.filter((searchFilter: SearchFilter) => searchFilter.id !== clearedSearchFilter.id);

        if (clearedSearchFilter.filterType === 'letter') {
            this.filterLetter = '';
            this.mobileLetterPendingApply = '';
        }

        // update search results
        this.resetTabFocus();
        this.updateResults();
        this.updateQueryParams();
    }

    redirectToPage(num: number, searchParam?: string) {
        this.router.navigate([`${this.diseasePagesUrl}`], {
            queryParams: {
                page: num,
                search: searchParam,
                letter: this.filterLetter,
                category: this.filters
                    .filter((searchFilter: SearchFilter) => searchFilter.filterType === 'category')
                    .map((searchFilter: SearchFilter) => searchFilter.filterText)
                    .join(';'),
            },
        });
    }

    resetTabFocus() {
        this.searchInput?.nativeElement.focus();
    }

    updateCurrentPage(page: number, searchValue?: string) {
        this.currentPage = page;
        this.redirectToPage(page, searchValue);
        this.resetTabFocus();
        this.updateResults();
    }

    toggleSort() {
        // send to page one, if not on page 1
        if (this.currentPage > 1) {
            this.currentPage = 1;
            this.redirectToPage(this.currentPage);
        }

        // toggle the sort
        this.sort.sortEnabled = true;
        this.sort.asc = !this.sort.asc;
        this.updateResults();
    }

    generateDiseaseURL(disease: Disease) {
        return getDiseasePath(disease).join('/');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clearAllFilters() {
        this.filters = [];
        this.updateQueryParams();
        this.updateResults();
    }

    // Update the query params when a filter is updated
    private updateQueryParams() {
        const queryParams = {
            category: this.filters
                .filter((searchFilter: SearchFilter) => searchFilter.filterType === 'category' && searchFilter.enabled)
                .map((searchFilter: SearchFilter) => searchFilter.filterText)
                .join(';'),
            page: this.currentPage,
            letter: this.filterLetter,
            search: this.searchControl.value,
        };

        this.location.go(this.activatedRoute.snapshot.url.join('/'), this.getQueryParamString(queryParams));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getQueryParamString(queryParams: { [key: string]: any }): string {
        const keys = Object.keys(queryParams);
        if (!keys.length) {
            return '';
        }
        const queryParamPairs = keys.map(key => `${key}=${encodeURIComponent(queryParams[key])}`);
        return `?${queryParamPairs.join('&')}`;
    }

    private updateResults() {
        // load in Cached Disease list
        let filteredDiseases = diseasesToDiseasesWithSynonymString(this.diseaseService.trimmedDiseases);

        // need to check for disabled filters, and remove
        this.filters = this.filters.filter((searchFilter: SearchFilter) => searchFilter.enabled);

        // run the letter filter first
        const letterFilter = this.filters.filter((searchFilter: SearchFilter) => searchFilter.filterType === 'letter');

        if (letterFilter.length) {
            this.filterLetter = letterFilter[0].filterText;
        } else {
            this.filterLetter = '';
        }

        if (this.filterLetter) {
            if (this.filterLetter === '0-9') {
                filteredDiseases = filteredDiseases.filter((dz: DiseaseTrimmed) => {
                    const firstLetter = dz.name[0];
                    return Number(firstLetter);
                });
            } else {
                filteredDiseases = filteredDiseases.filter((dz: DiseaseTrimmed) => dz.name.startsWith(this.filterLetter));
            }
        }

        // Category Filters
        const categoryFilterNames = this.filters
            .filter((searchFilter: SearchFilter) => searchFilter.filterType === 'category')
            .map((searchFilter: SearchFilter) => {
                const matchedFilters = this.diseaseService.diseaseCategories.filter((dc: CategoriesWithParent) => dc.nameCurated === searchFilter.filterText && !dc.ParentTag);
                if (matchedFilters.length === 1) {
                    const [matchedFilter] = matchedFilters;

                    // return the "Non-Curated" name, as this is what will match the disease
                    return matchedFilter.name;
                } else {
                    console.error(matchedFilters.length, 'Matched Category Filters');
                    return searchFilter.filterText;
                }
            });

        if (categoryFilterNames.length) {
            filteredDiseases = filteredDiseases.filter((dz: DiseaseTrimmed) => {
                let matchedAny = false;
                categoryFilterNames.forEach(thisCategoryFilterName => {
                    if (Object.hasOwn(dz, 'diseaseCategories') && dz.diseaseCategories.includes(thisCategoryFilterName)) {
                        matchedAny = true;
                    }
                });
                return matchedAny;
            });
        }

        // Search Filter
        const search = this.searchControl.value?.trim().toLowerCase();

        filteredDiseases = globalDiseaseSearch(search, filteredDiseases);

        if (this.sort.sortEnabled) {
            // sort diseases
            filteredDiseases.sort((a, b) =>
                this.sort.asc ? a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) : b.name.localeCompare(a.name, 'en', { sensitivity: 'base' }),
            );
        }

        // calculate total pages
        const totalDiseases = filteredDiseases.length;
        this.totalPages = Math.ceil(totalDiseases / this.maxItemsPerPage);

        // calculate pagination
        this.pages = SearchResultPageComponent.buildPagination(this.totalPages, this.maxShownPages, this.currentPage);

        // show/hide next/previous buttons
        this.showNextButton = this.currentPage <= this.totalPages - 1;
        this.showPrevButton = this.currentPage > 1;

        // get diseases to display
        const start = (this.currentPage - 1) * this.maxItemsPerPage;
        const end = start + this.maxItemsPerPage;
        this.displayedDiseases = filteredDiseases.slice(start, end);

        // calculate "currently showing" label
        const startIndex = Math.min(start + 1, totalDiseases);
        const endIndex = Math.min(end, totalDiseases);
        this.currentlyShowing = `Showing ${startIndex} - ${endIndex} of ${totalDiseases}`;
    }
}
