<app-top-navigation></app-top-navigation>
<main-banner [title]="article?.title"></main-banner>

<div class="container-fluid my-5 generic-page-wrapper">
    <div class="container-xxxl">
        <div class="row">
            <div class="col-12 col-xl-10 m-auto body fs-md-18 text-link" phoneLink [innerHTML]="article?.bodyText"></div>
        </div>
    </div>
</div>

<app-footer></app-footer>
