<div class="coordinate-wrapper container-fluid pb-5">
    <div class="container-xxxl py-0 py-lg-5">
        <div class="d-flex align-items-center pt-5">
            <app-bar-header textHeader="Care Coordination" theme="blue"></app-bar-header>
        </div>

        <p class="description mb-4 pb-3 fs-18" [innerHTML]="mainKA.bodyText"></p>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
                <app-research-participate-in-research-card
                    [cardTitle]="coordinateKA.title"
                    [cardBody]="coordinateKA.bodyText"
                    theme="blue"
                    iconUrl="/assets/medical-person.svg"
                    [fullHeight]="true"
                ></app-research-participate-in-research-card>
            </div>

            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0 col-lg-6">
                <app-research-participate-in-research-card
                    [cardTitle]="multidisciplinaryKA.title"
                    [cardBody]="multidisciplinaryKA.bodyText"
                    theme="teal"
                    iconUrl="/assets/medical-people.svg"
                    [fullHeight]="true"
                ></app-research-participate-in-research-card>
            </div>
        </div>
    </div>
</div>
